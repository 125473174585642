import { useCallback } from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';
import { getFeatureFlag } from 'src/utils/featureFlags';

export default function useDeviceApiCall({ authProvider, stack, deviceId }) {
  const { getDevice } = useDeviceApiAsDeviceCache({
    authProvider,
    stack
  });

  const apiCall = useCallback(async () => {
    if (!deviceId) {
      return null;
    }
    let response = null;
    const useDeviceCache = await getFeatureFlag({ key: 'useDeviceCache' });
    if (useDeviceCache) {
      const client = new DeviceCacheApiClient(stack, authProvider);
      response = await client.show(deviceId);
    } else {
      response = await getDevice(deviceId);
    }
    return response.data;
  }, [authProvider, stack, deviceId, getDevice]);

  return useApiCall({ apiCall });
}
