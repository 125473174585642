import type { AccordionInterface } from '@veneer/core/dist/esm/scripts/accordion'
import React from 'react'
import { StyledAccordion } from './styles'
import { useAnalytics } from '@monetization/hpaip-ui-shared-components'

export interface VeneerAccordion extends AccordionInterface {}

export interface PrintCardsAccordionProps {
  veneerAccordion: VeneerAccordion
  title: string
}
/* istanbul ignore next */
const onExpandCallback = (...props) => {
  const item = props[2]
  setTimeout(() => {
    const targetElement = document.getElementById(item.id)
    const elementHeight = targetElement?.offsetHeight

    if (elementHeight > 200) {
      targetElement.style.cssText = 'height: 200px; overflow-y: scroll;'
    }
  }, 10)
}

export const PrintCardsAccordion: React.FunctionComponent<
  PrintCardsAccordionProps
> = ({ veneerAccordion }) => {
  const { publishEvent, events } = useAnalytics()

  const onExpandHandler = (...props) => {
    onExpandCallback(...props)
    onExpandCallbackEvent()
  }
  const onExpandCallbackEvent = () => {
    publishEvent(events.CardAccordianExpanded())
  }
  const onCollapseCallBack = () => {
    publishEvent(events.CardAccordianCollapsed())
  }
  return (
    <StyledAccordion
      {...veneerAccordion}
      appearance="dropShadow"
      behavior="singleExpand"
      onExpand={onExpandHandler}
      onCollapse={onCollapseCallBack}
    />
  )
}
