import React, { useState, forwardRef, useMemo, useEffect } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { HistoricTableAccordion, Title, HistoricTableWrapper } from './styles'
import { HistoricTableContent } from './HistoricTableContent'
import {
  Preferences,
  HistoricTableColumnType,
  TimePeriod,
  PeriodOptionsType
} from '../../types/historicTableType'
import CommonProps from '../../types/commonProps'
import { useAnalytics } from '@monetization/hpaip-ui-shared-components'

export interface HistoricTableProps {
  id?: string
  className?: string
  title: string
  loading?: boolean
  dataTable: any[]
  columns: HistoricTableColumnType[]
  preferences: Preferences
  onSort: (_: any, { id, type }: { id: string; type: string }) => void
  onFilter: (value: TimePeriod) => void
  downloadAll?: boolean
  onChangeExpand: (type: string) => void
  commonProps: CommonProps
  expanded?: boolean
  isFilteredBy?: TimePeriod
  periodOptions?: PeriodOptionsType[]
}

export const HistoricTableComponent: React.FunctionComponent<
  HistoricTableProps
> = ({
  id,
  className,
  title,
  loading,
  dataTable,
  columns,
  preferences,
  onSort,
  onFilter,
  downloadAll,
  onChangeExpand,
  expanded = false,
  isFilteredBy,
  periodOptions = [],
  commonProps
}) => {
  const { t } = commonProps
  const { publishEvent, events } = useAnalytics()
  const [isExpanded, setExpanded] = useState(expanded)
  const [pageSize, setPageSize] = useState<number>(8)
  const [paginationSize, setNavigationPageSize] = useState<number>(4)
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(
    isFilteredBy || 'all'
  )
  const [currentPage, setCurrentPage] = useState<number>(1)
  const hasNextPage = dataTable.length / pageSize > 1
  const paginatedData = dataTable.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )
  const PageSizeOptions = useMemo(() => {
    if (!dataTable.length) return [{ value: 0, label: '0' }]

    return [
      { value: 2, label: '2' },
      { value: 4, label: '4' },
      { value: 8, label: '8' },
      { value: 10, label: '10' }
    ]
  }, [dataTable.length])

  const windowSize = useWindowSize()

  const defaultPeriodOptions = [
    {
      value: 'all',
      /**
       * Language key for the default error message
       * @param {historic-table.select-label.all} 'All History' */
      label: t('historic-table.select-label.all', 'All History')
    },
    {
      value: 'last-12',
      /**
       * Language key for the default error message
       * @param {historic-table.select-label.last-12} 'Last 12 months' */
      label: t('historic-table.select-label.last-12', 'Last 12 months')
    }
  ]

  periodOptions = [...defaultPeriodOptions, ...periodOptions]

  const paginationHandler = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const onChangePeriod = (value: TimePeriod) => {
    setTimePeriod(value)
    onFilter(value)
  }

  const eventsHandler = () => {
    if (!isExpanded) {
      publishEvent(events.HistoryTableAccordianExpanded())
    } else {
      publishEvent(events.HistoryTableAccordianCollapsed())
    }
    onChangeExpand(
      isExpanded ? 'ControlAccordianCollapsed' : 'ControlAccordianExpanded'
    )
    setExpanded(!isExpanded)
  }

  useEffect(() => {
    const { width } = windowSize
    width < 360
      ? setNavigationPageSize(2)
      : width < 415
      ? setNavigationPageSize(3)
      : setNavigationPageSize(4)
  }, [windowSize])

  return (
    <HistoricTableAccordion
      id={id}
      appearance="dropShadow"
      behavior="singleExpand"
      items={[
        {
          header: {
            startArea: <Title>{title}</Title>,
            centralArea: <span />
          },
          expanded: isExpanded,
          content: (
            <HistoricTableContent
              loading={loading}
              className={className}
              columns={columns}
              preferences={preferences}
              onSort={onSort}
              downloadAll={downloadAll}
              setPageSize={setPageSize}
              paginationSize={paginationSize}
              timePeriod={timePeriod}
              PageSizeOptions={PageSizeOptions}
              periodOptions={periodOptions}
              paginationHandler={paginationHandler}
              onChangePeriod={onChangePeriod}
              paginatedData={paginatedData}
              totalLength={dataTable.length}
              pageSize={pageSize}
              currentPage={currentPage}
              hasNextPage={hasNextPage}
              commonProps={commonProps}
            />
          )
        }
      ]}
      onCollapse={eventsHandler}
      onExpand={eventsHandler}
    />
  )
}

export const HistoricTable = forwardRef<
  HTMLDivElement,
  HistoricTableProps & { areaName: string }
>(function HistoryTable({ areaName, ...props }, ref) {
  return (
    <HistoricTableWrapper ref={ref} areaName={areaName}>
      <HistoricTableComponent {...props} />
    </HistoricTableWrapper>
  )
})
