import React, { useState } from 'react'
import { useIICommonNotification } from '@monetization/hpaip-notification-rules-react'
import InitialComponent from '../InitialComponent'
import useCriticalScopes from '@/hooks/useCriticalScopes'
import useSettingsInfo from '@/hooks/useSettingInfo'

const IINotificationFlow = (props) => {
  const {
    error: criticalScopeError,
    isLoading: criticalScopeLoading,
    forceRefresh: forceCriticalScope
  } = useCriticalScopes(true)

  const { data: settingsData } = useSettingsInfo(true)

  const [sessionModal, setSessionModal] = useState<boolean>(false)
  const handleSessionModal = () => setSessionModal(!sessionModal)

  const [iIBillingModal, setIIBillingModal] = useState<boolean>(false)
  const handleIIBillingModal = () => setIIBillingModal(!iIBillingModal)

  const commonIINotificationData = useIICommonNotification(
    'ShippingBilling',
    handleIIBillingModal
  )
  props.commonProps.commonNotificationData = commonIINotificationData
  const commonProps = {
    ...props.commonProps,
    iIBillingModal,
    handleIIBillingModal,
    criticalScopeError,
    criticalScopeLoading,
    forceCriticalScope,
    sessionModal,
    handleSessionModal,
    settingsData
  }

  return (
    <>
      <InitialComponent commonProps={commonProps} />
    </>
  )
}

export default IINotificationFlow
