import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const AddressCardContainer = styled.div`
  @media (min-width: ${tokens.layout.mdMin}) {
    max-width: 50%;
  }
`

export const Title = styled.p`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size4};
  line-height: ${tokens.typography.lineHeight4};

  @media (max-width: ${tokens.layout.mdMin}) {
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight2};
  }
`

export const AddressBlock = styled.div<{ selected?: boolean }>`
  margin-top: ${tokens.layout.size6};
  padding: ${tokens.layout.size5} 40px;
  border-radius: ${tokens.layout.cornerRadius3};
  cursor: pointer;
  border: ${({ selected }) =>
    selected ? '3px solid #0076AD' : '1px solid #D9D9D9'};
`

export const AddressLine = styled.p`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  word-break: break-word;
`
