import React, { useEffect, useState } from 'react'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import { ShippingAddress } from '@/types/ShippingAddress'
import { ShippingForm as ShippingHPOneForm } from '@monetization/shipping-react'
import useGetText from '@/hooks/useGetText'
import {
  EditShippingCancelButtonClicked,
  EditShippingClicked,
  EditShippingModuleDisplayed,
  EditShippingSaveButtonClicked,
  publishEvent,
  ShippingAddressesModuleDisplayed
} from '@/utils/analytics'
import getSubscriptionLineItemEntityType from '@/helpers/getSubscriptionLineItemEntityType'
import getLocalization from '@/helpers/getLocalization'
import getAuthProvider from '@/helpers/getAuthProvider'
import { redirectUrl } from '@/utils/redirectUrl'
import useShellContext from '@/hooks/useShellContext'

import {
  ModalContentContainer,
  StyledAddressItemContainer,
  StyledButtonContainer,
  StyledContent,
  StyledDisabled,
  StyledIconContainer,
  StyledModal,
  StyledTitle,
  StyledAddButtonContainer
} from './styles'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import Images from '@/assets/images'
import SubscriptionInfoItem from '../../../SubscriptionInfoItem/SubscriptionInfoItem'
import { ErrorNotification } from '@/utils/ErrorNotification'

export type AdressInfoProps = React.PropsWithChildren<{
  commonProps: any
  shippingAddressInfo: ShippingAddress
  friendlySubscriptionId: string
  subscriptionId: string
  entityType: string
  enableEdit?: boolean
  onAddressSaved: () => void
  isDisabled?: boolean
  cloudId?: string
  addressDisabled?: boolean
  paperEligibility?: boolean
}>
const HPAIPShippingAddressInfo = ({
  commonProps,
  shippingAddressInfo,
  friendlySubscriptionId,
  subscriptionId,
  entityType,
  enableEdit,
  onAddressSaved,
  isDisabled,
  addressDisabled,
  paperEligibility
}: AdressInfoProps) => {
  const [isOpen, setOpenModal] = useState(false)
  const getTextSub = useGetText('subscriptions')
  const getText = useGetText('shipping')
  const shell = useShellContext()
  const translatedEntityType = getSubscriptionLineItemEntityType(entityType)
  const accountData = commonProps?.accountData
  const { language } = getLocalization()
  const authProvider = getAuthProvider()
  const country = accountData?.regionId || process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE + '_' + country
  const hponeBaseURLProvider = process.env.SHIPPING_BASE_URL_PROVIDER
  const updateURLProvider = process.env.SHIPPING_BASE_UPDATE_URL_PROVIDER

  useEffect(() => {
    publishEvent(ShippingAddressesModuleDisplayed, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShippingBtn = async () => {
    setOpenModal(true)
    publishEvent(EditShippingClicked, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
  }

  const onSave = async (err: string) => {
    publishEvent(EditShippingSaveButtonClicked, {
      entityType: translatedEntityType
    })

    const notification = err
      ? ErrorNotification({ getText })
      : {
          display: true,
          refreshNotification: false,
          title: getText('shippingAddressSavedSuccess.hpOneTitle', {
            defaultValue:
              'Your HP All-In Plan shipping information has been saved'
          }),
          notificationType: 'positive' as const
        }
    commonProps.setLocalNotificationList([])
    commonProps.refreshNotification(notification)
    setOpenModal(false)
    onAddressSaved()
  }

  const handleCloseModal = () => {
    publishEvent(EditShippingCancelButtonClicked, {
      entityType: translatedEntityType
    })
    setOpenModal(false)
  }

  const onFormOpen = () => {
    publishEvent(EditShippingModuleDisplayed, {
      entityType: translatedEntityType
    })
  }

  /* istanbul ignore next */
  const handleRemovePaperService = () =>
    redirectUrl(
      `/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`,
      shell.navigation
    )

  /* istanbul ignore next */
  const handleBackToAddress = async () => {
    setOpenModal(true)
  }

  const renderModalContent = (addressId) => {
    return (
      <ModalContentContainer>
        {isOpen && (
          <ShippingHPOneForm
            data-testid="editShippingFormId"
            country={country}
            language={currLanguage}
            subscriptionType={entityType}
            baseURLProvider={hponeBaseURLProvider}
            updateURLProvider={updateURLProvider}
            authProvider={authProvider}
            onSave={(err: string) => onSave(err)}
            onCancel={handleCloseModal}
            stack={shell?.stack}
            addressId={addressId}
            onOpen={onFormOpen}
            paperEligibility={paperEligibility}
            removePaperService={handleRemovePaperService}
            backToAddress={handleBackToAddress}
          />
        )}
      </ModalContentContainer>
    )
  }
  const subscriptionContent = entityType && (
    <SubscriptionInfoItem
      subscriptionType={entityType}
      subscriptionIds={[friendlySubscriptionId]}
      isDisabled={isDisabled}
      title={getTextSub(getSubscriptionTitleTextId(entityType))}
    />
  )
  const modalContent = (addressId?) => (
    <StyledModal
      data-testid="modal"
      closeButton
      show={isOpen}
      onClose={handleCloseModal}
      closeOnBlur={false}
    >
      {renderModalContent(addressId)}
    </StyledModal>
  )
  const containResourceId = Boolean(shippingAddressInfo?.resourceId)

  const addressButtonActionLabel = containResourceId ? (
    getText('editShippingAddress')
  ) : (
    <>
      {getText('addShippingAddress')}
      <IconChevronRight
        size={20}
        customStyle={{
          marginLeft: '5px',
          color: '#0278ab',
          verticalAlign: 'middle'
        }}
      />
    </>
  )

  const actionBtn =
    enableEdit && !isDisabled ? (
      <StyledAddButtonContainer isDisabled={addressDisabled}>
        <a
          role="button"
          data-testid={`editshippingButton_${shippingAddressInfo?.resourceId}`}
          onClick={handleShippingBtn}
          tabIndex={0}
          id={`edit-shipping-address-${entityType}`}
        >
          {addressButtonActionLabel}
        </a>
      </StyledAddButtonContainer>
    ) : (
      <StyledDisabled isDisabled={isDisabled}>
        {getText('editShippingAddress')}
      </StyledDisabled>
    )

  const getAddressIcon = (): JSX.Element => {
    return isDisabled ? (
      <img src={Images.shipping_gray} alt="title icon" />
    ) : (
      <img src={Images.shipping} alt="title icon" />
    )
  }

  const getEmptyAddressContent = (): JSX.Element => {
    return (
      <StyledContent data-testid="emptyAddressContent" isDisabled={isDisabled}>
        <img src={Images.shipping} alt="title icon" />
        <p>{getText('noShipping')}</p>
      </StyledContent>
    )
  }

  const cardContent = (
    <>
      <StyledAddressItemContainer data-testid="addressItem">
        {subscriptionContent}
        <div>
          <StyledIconContainer>
            {containResourceId && getAddressIcon()}
          </StyledIconContainer>

          <div>
            <StyledTitle isDisabled={isDisabled}>
              {containResourceId && shippingAddressInfo?.firstName}{' '}
              {containResourceId && shippingAddressInfo?.lastName}
            </StyledTitle>
            <StyledContent isDisabled={isDisabled}>
              {containResourceId && shippingAddressInfo?.address} <br />
              {containResourceId && shippingAddressInfo?.address2 ? (
                <span>{shippingAddressInfo?.address2}</span>
              ) : (
                <></>
              )}
            </StyledContent>
            <StyledContent isDisabled={isDisabled}>
              {containResourceId && (
                <span>
                  {shippingAddressInfo?.city}, {shippingAddressInfo?.state}{' '}
                  {shippingAddressInfo?.postalCode}
                </span>
              )}
            </StyledContent>
            <StyledButtonContainer>
              {!containResourceId && getEmptyAddressContent()}
              {actionBtn}
            </StyledButtonContainer>
          </div>
        </div>
      </StyledAddressItemContainer>

      {modalContent(shippingAddressInfo?.resourceId)}
    </>
  )
  return <>{cardContent}</>
}

export default HPAIPShippingAddressInfo
