import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';
import { TenantStrategyEnum } from '../tenantHandler/strategy/strategy';
import ISessionService, {
  RefreshParameterType,
  SignInOptionsType,
  SignInResponseType
} from './ISessionService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import IRefreshTokenService from './refreshTokenService/IRefreshTokenService';
import IExchangeTokenService from './exchangeTokenService/IExchangeTokenService';
import { inject, singleton } from 'tsyringe';
import ExchangeTokenServiceNative from './exchangeTokenService/ExchangeTokenServiceNative';
import { getJWeb, JWebEnums, JWebErrorHandler } from '../JWeb';
import {
  AuthContextEnum,
  AuthTokenService,
  IAuthTokenService
} from '../authTokenService';
import { TokenProviderOptions } from '../JWeb/types';
import {
  TokenLifetimeRequirementType,
  TokenType
} from '../../services/JWeb/JWebEnums';
import { getUserInteractionEntryPointBasedOnParams } from '../mfeRouterService/operations/NewLoginPathOperation/utils/getUserInteractionEntryPoint';
import { AccessToken, AuthPluginError } from '../../services/JWeb/types';
import * as JWebEnumsImport from '../JWeb/JWebEnums';
import { CriticalScopeHandler } from './CriticalScopeHandler';
import getConfigIdBasedOnStack from './utils/getConfigIdBasedOnStack';
import IApplicationService from '../applicationService/IApplicationService';
import type { LoginInputType } from './types';

@singleton()
class SessionServiceNative implements ISessionService {
  private _loginService: ILoginService;
  private _refreshTokenServiceNative: IRefreshTokenService;
  private _exchangeTokenServiceNative: IExchangeTokenService;
  private _legacySessionDataValue = {
    isLoggedIn: false,
    idToken: ''
  };
  private _criticalScopeHandler: CriticalScopeHandler;
  private _applicationService: IApplicationService;

  private _loginProps: LoginInputType;

  constructor(
    @inject(AuthTokenService)
    authTokenService: IAuthTokenService,
    @inject(ExchangeTokenServiceNative)
    exchangeTokenServiceNative: IExchangeTokenService,
    @inject('IRefreshTokenService')
    refreshTokenServiceNative: IRefreshTokenService,
    @inject('ApplicationService') applicationService: IApplicationService,
    @inject('LoginProps')
    loginProps: LoginInputType
  ) {
    this._exchangeTokenServiceNative = exchangeTokenServiceNative;
    this._refreshTokenServiceNative = refreshTokenServiceNative;
    this._criticalScopeHandler = new CriticalScopeHandler(
      { isNative: true },
      authTokenService
    );
    this._applicationService = applicationService;
    this._loginProps = loginProps;
    bindAllMethods(this);
  }
  async signIn(options: SignInOptionsType): Promise<SignInResponseType> {
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);

    const response = await Auth.getToken({
      tokenProviderOptions: {
        tokenType: JWebEnums.TokenType.user,
        allowNetworkAccess: true,
        allowUserInteraction: true,
        requireFreshToken: true,
        showAccountCreationLink: true,
        skipTokenRefresh: false,
        userInteractionEntryPoint: JWebEnums.UserInteractionEntryPoint.signIn,
        additionalAuthorizationParameters:
          this._loginProps?.additionalAuthorizationParameters
      }
    });

    if ((response as AuthPluginError).error) {
      return {
        success: false,
        error: {
          message: (response as AuthPluginError).error.code
        }
      };
    }

    return {
      success: true
    };
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { loginService } = services;
    this._loginService = loginService;

    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    /* istanbul ignore next */
    internalLogger.log('Initializing session service native.');

    await this._updateInitialState();
    await this._setStratusAccessTokenFromNative();

    return Promise.resolve();
  }

  /*
        Critical Scope methods
  */
  public isCriticalScopesValid(): boolean {
    return this._criticalScopeHandler.isCriticalScopesValid();
  }

  public getLoginCooldown(): number {
    return this._criticalScopeHandler.getLoginCooldown();
  }

  /** This should be used to ensure that critical scopes is valid. */
  public goForceLogin(waitCallback = () => {}): void {
    this._criticalScopeHandler.ensureCriticalScopes(waitCallback);
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    if (!this.isLoggedIn()) {
      return;
    }
    if (this._criticalScopeHandler.getLoginCooldown()) {
      /* istanbul ignore next */
      internalLogger.debug('Login cooldown is active, skipping refresh token');
      return;
    }
    return this._refreshTokenServiceNative.refresh(refreshParameterType);
  }

  private _getTokenProviderOptions(
    tokenProviderOptions: TokenProviderOptions
  ): TokenProviderOptions {
    const options = { ...tokenProviderOptions };

    if (options.requireFreshToken) {
      options.tokenLifetimeRequirements = [
        {
          type: TokenLifetimeRequirementType.preferredMaximumSecondsSinceIssued,
          timeInterval: 0
        }
      ];
    }

    if (options.requireFreshToken && options.skipTokenRefresh) {
      options.scopesRequested = ['openid'];
    }

    return options;
  }

  private async _exchangeNativeForceLoginToken(): Promise<
    AuthPluginError | AccessToken
  > {
    const exchangeResult = await (
      this._exchangeTokenServiceNative as ExchangeTokenServiceNative
    ).exchangeJWeb(
      null,
      this._getTokenProviderOptions({
        tokenType: TokenType.user,
        allowUserInteraction: true,
        requireFreshToken: true
      })
    );

    return exchangeResult;
  }

  private getAdditionalParamsForJwebGetToken() {
    // TODO: Update this to be used only for coptor auth provider in the future.
    return {
      additionalAuthorizationParameters: {
        config_id: getConfigIdBasedOnStack(
          this._applicationService.getPortalStack()
        )
      }
    };
  }

  public async redirectToIdentityProvider(): Promise<any> {
    const entryPoint = getUserInteractionEntryPointBasedOnParams() || 'signIn';

    // If the user is not logged in.
    if (!this.isLoggedIn()) {
      // ? This is the login of the user in login flow. We dont need to setToken here, because we can get affter of the /loggedin flow.

      const exchangeResult = await (
        this._exchangeTokenServiceNative as ExchangeTokenServiceNative
      ).exchangeJWeb(
        null,
        this._getTokenProviderOptions({
          userInteractionEntryPoint:
            JWebEnumsImport.UserInteractionEntryPoint[entryPoint],
          tokenType: TokenType.user,
          allowUserInteraction: true,
          ...this.getAdditionalParamsForJwebGetToken()
        })
      );

      return exchangeResult;
    }
    // So, the user is logged in, we should apply the force Login.
    return this._exchangeNativeForceLoginToken();
  }

  public async exchangeToken(
    tenantId: string,
    authContext: AuthContextEnum,
    tenantStrategy: TenantStrategyEnum
  ): Promise<void> {
    return this._exchangeTokenServiceNative.exchange({
      tenantId,
      authContext,
      tenantStrategy
    });
  }

  public async clearSession(): Promise<void> {
    this._legacySessionDataValue.isLoggedIn = false;
    this._legacySessionDataValue.idToken = '';
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
    await Auth.logout().then((v) => JWebErrorHandler(v));
  }

  public async logout(): Promise<void> {
    await this.clearSession();
    window.location.reload();
  }

  public async getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }

  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    return this._loginService.generateAuthenticationUrl(options);
  }

  public isLoggedIn(): boolean {
    return !!this._legacySessionDataValue?.isLoggedIn;
  }

  public getIdToken(): string {
    return this._legacySessionDataValue?.idToken;
  }

  private async _updateInitialState(): Promise<void> {
    try {
      const jWebClient = await getJWeb();
      const isLoggedInData =
        (await jWebClient?.Plugins?.Auth?.isLoggedIn?.()) as { value: boolean };
      const idTokenData = await jWebClient?.Plugins?.Auth?.getToken?.();
      this._legacySessionDataValue.isLoggedIn = isLoggedInData.value;
      this._legacySessionDataValue.idToken = (
        idTokenData as AccessToken
      )?.account?.idToken;
    } catch (e) {
      console.error('Failed from get Auth data from JWeb.');
      console.error(e);
      this._legacySessionDataValue.isLoggedIn = false;
    }
  }

  private async _setStratusAccessTokenFromNative(): Promise<void> {
    try {
      if (this.isLoggedIn()) {
        return this._refreshTokenServiceNative.refresh();
      }
    } catch (error) {
      /* istanbul ignore next */
      internalLogger.error('Error on refresh token');
      console.error(error);
      return;
    }
  }
}

export default SessionServiceNative;
