import React from 'react'
import { extendSemantics } from '@veneer/theme'
import primitives from '@veneer/primitives'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

const hpxTokens = {
  royalBlue0: '#f5f6ff',
  royalBlue1: '#e5eaff',
  royalBlue2: '#d6ddff',
  royalBlue3: '#c4caff', //different from veneer
  royalBlue4: '#9ba6ff', //different from veneer
  royalBlue5: '#8190ff',
  royalBlue6: '#6374fd',
  royalBlue7: '#4759f5',
  royalBlue8: '#3543c0',
  royalBlue9: '#15218f', //different from veneer
  royalBlue10: '#28307b',
  royalBlue11: '#262b65',
  royalBlue12: '#242852',
  background: '#edeefe'
}
export const LayoutTheme = ({ children, useHPXTheme = false }) => {
  const HpxTheme = extendSemantics({
    color: {
      light: {
        background: {
          disabled: hpxTokens.royalBlue3, // disabled primary background
          default: hpxTokens.background
        },
        foreground: {
          disabled: primitives.color.white // disabled primary text and border, also disabled secondary text and border
          // disabled: hpxTokens.royalBlue4 // gets correct disabled secondary button, but cannot see text as well in disabled primary button
        },
        primary: {
          foreground: {
            default: primitives.color.white // primary text
          },
          base: {
            default: hpxTokens.royalBlue7, // primary background
            hover: hpxTokens.royalBlue8, // primary hover, secondary border and font
            active: hpxTokens.royalBlue9 // primary click
          },
          background: {
            hover: hpxTokens.royalBlue1, // secondary background hover
            active: hpxTokens.royalBlue3 // secondary backgroudn click
          }
        }
      }
    }
  })

  return (
    <div className="layouttheme">
      <VeneerThemeProvider
        customSemantics={useHPXTheme ? HpxTheme : extendSemantics({})}
        mode="light"
        shape={useHPXTheme ? 'round' : 'round'}
        density="standard"
      >
        {children}
      </VeneerThemeProvider>
    </div>
  )
}
