import Button from '@veneer/core/dist/scripts/button';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator/progress_indicator';
import ButtonGroup from '@veneer/core/dist/scripts/button_group/button_group';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import Images from '../../assets/images';
import {
  getParameterQuery,
} from '../../utils/globals';
import {
  AlertModalIcon,
  ButtonContainer,
  LinkButtonGroup,
  ModalTitle,
  OfferTerms,
  OfferTermsList,
  ProgressContainer,
  TextContainer,
} from './styles';
import { InstantInkBaseUrl } from '../../utils/instantInk';
import { InnerDivHTML } from '../shared-components/InnerHTML';
import {
  ActivateHpPlusButtonClickedEvent,
  ActivateHpPlusModalActivateEvent,
  ActivateHpPlusModalCloseEvent,
  ActivateHpPlusModalDisplayedEvent,
  RedeemInstantInkModalRedeemEvent,
  RedeemInstantInkModalSkipEvent,
  UnableToActivateCloseEvent,
  UnableToActivateGetSupportEvent,
  UnableToActivateModalDisplayedEvent,
  UnableToActivateRetryEvent,
  publishEvent,
} from '../../utils/analytics';
import { PATHS } from '../../utils/constants';
import useActivateHpPlusApiCall from '../../hooks/useActivateHpPlusApiCall';
import useCreateInstantInkSessionApiCall from '../../hooks/useCreateInstantInkSessionApiCall';

const Steps = {
  confirm: 1,
  activating: 2,
  activatingInstantInk: 3,
  loadingTrial: 4,
  finished: 5,
  finishedWithAction: 6,
};

const ActivateOfferModal = ({
  eligible,
  instantInkMonths,
}) => {
  const { t } = useI18n();
  const { authProvider, stack, navigation } = useRootContext();
  const activateHpPlusApiCall = useActivateHpPlusApiCall({ authProvider, stack });
  const createInstantInkSessionApiCall = useCreateInstantInkSessionApiCall({ authProvider, stack });

  const noInstantInk = !instantInkMonths || instantInkMonths === 0;
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(Steps.confirm);
  const [activationError, setActivationError] = useState(false);
  const [activateSecondOffer, setActivateSecondOffer] = useState(false);
  const [createInstantInkSession, setCreateInstantInkSession] = useState(false);
  const [instantInkSessionId, setInstantInkSessionId] = useState(null);

  const handleRetry = useCallback(() => {
    publishEvent(UnableToActivateRetryEvent);
    setActivateSecondOffer(true);
    setCreateInstantInkSession(false);
    setActivationError(false);
    setShowModal(true);
  }, []);

  const redirectToDashboard = () => {
    navigation.push(PATHS.HOME);
    setShowModal(false);
  };

  const redirectToDashboardFromContinueII = () => {
    publishEvent(RedeemInstantInkModalRedeemEvent);
    redirectToDashboard();
  };

  const redirectToDashboardFromSkipII = () => {
    publishEvent(RedeemInstantInkModalSkipEvent);
    redirectToDashboard();
  };

  const openGetSupportInNewTab = useCallback(() => {
    publishEvent(UnableToActivateGetSupportEvent);
    const url = 'https://www.hp.com/plus-support';
    window.open(url, '_blank').focus();
  }, []);

  const handleShowModal = useCallback(() => {
    publishEvent(ActivateHpPlusButtonClickedEvent);
    publishEvent(ActivateHpPlusModalDisplayedEvent);
    setShowModal(!showModal);
  }, [showModal]);

  const handleClose = useCallback(() => {
    publishEvent(ActivateHpPlusModalCloseEvent);
    setShowModal(!showModal);
  }, [showModal]);

  const handleActivationError = () => {
    setActivateSecondOffer(false);
    setCreateInstantInkSession(false);
    setCurrentStep(Steps.confirm);
    setShowModal(false);
    setActivationError(true);
    publishEvent(UnableToActivateModalDisplayedEvent);
  };

  const finishedActivation = () => {
    setCurrentStep(Steps.finished);
    setTimeout(() => {
      setShowModal(false);
      redirectToDashboard();
    }, 3000);
  };

  useEffect(() => {
    if (activateSecondOffer) {
      if (currentStep !== Steps.activating) {
        setCurrentStep(Steps.activating);
        const accountId = window.Shell.v1.authToken.getTenantIdFromToken();
        const cloudId = getParameterQuery('cloudId');
        activateHpPlusApiCall.makeApiCall({ accountId, cloudId });
      } else if (activateHpPlusApiCall.error) {
        let errorDescription = 'Unknown activation error';
        const error = activateHpPlusApiCall.error?.response?.data?.errors?.[0];
        if (error && error.code && error.message) {
          errorDescription = `${error.code} - ${error.message}`;
        }
        handleActivationError(errorDescription);
      } else if (activateHpPlusApiCall.success
        && activateHpPlusApiCall.data
      ) {
        if (noInstantInk) {
          finishedActivation();
        } else {
          setCreateInstantInkSession(true);
        }
      }
    }
  }, [
    activateSecondOffer,
    activateHpPlusApiCall.data,
    activateHpPlusApiCall.error,
    activateHpPlusApiCall.success,
  ]);

  useEffect(() => {
    if (createInstantInkSession) {
      if (currentStep !== Steps.activatingInstantInk) {
        setCurrentStep(Steps.activatingInstantInk);
        const cloudId = getParameterQuery('cloudId');
        createInstantInkSessionApiCall.makeApiCall({ cloudId });
      } else if (createInstantInkSessionApiCall.error) {
        finishedActivation();
      } else if (createInstantInkSessionApiCall.success
        && createInstantInkSessionApiCall.data) {
        const { resourceId: sessionId } = createInstantInkSessionApiCall.data;
        setInstantInkSessionId(sessionId);
        setCurrentStep(Steps.finishedWithAction);
        publishEvent(RedeemInstantInkModalRedeemEvent);
      }
    }
  }, [
    createInstantInkSession,
    createInstantInkSessionApiCall.data,
    createInstantInkSessionApiCall.error,
    createInstantInkSessionApiCall.success,
  ]);

  const handleActivateOfferButton = useCallback(() => {
    publishEvent(ActivateHpPlusModalActivateEvent);
    setActivateSecondOffer(true);
    setCreateInstantInkSession(false);
  }, []);

  const modalHeader = useCallback(() => {
    if (currentStep === Steps.loadingTrial
      || currentStep === Steps.finished
      || currentStep === Steps.finishedWithAction) {
      return t('home.modalActivateHp+.activationSuccess.header');
    }

    if (currentStep === Steps.confirm) {
      return t('home.modalActivateHp+.subHeader');
    }

    return ' ';
  }, [currentStep]);

  const modalSubheader = useCallback(() => {
    const welcomeText = t('home.modalActivateHp+.activationSuccess.bodyCopy1');

    if (noInstantInk && currentStep === Steps.finished) {
      return (
        <>
          <TextContainer>
            {welcomeText}
          </TextContainer>
          <br />
          <ProgressContainer>
            <ProgressIndicator />
          </ProgressContainer>
        </>
      );
    }

    if (currentStep === Steps.loadingTrial || currentStep === Steps.finished) {
      return (
        <TextContainer>
          {welcomeText}
        </TextContainer>
      );
    }

    if (currentStep === Steps.finishedWithAction) {
      const continueText = t('home.modalActivateHp+.activationSuccess.bodyCopy2', {
        tagPlaceHolderStart: '<b>',
        tagPlaceHolderEnd: '</b>',
        interpolation: { escapeValue: false },
      });
      return (
        <TextContainer>
          {welcomeText}
          <br />
          <br />
          <InnerDivHTML>
            {continueText}
          </InnerDivHTML>
        </TextContainer>
      );
    }

    return null;
  }, [currentStep]);

  const modalFooter = useCallback(() => {
    if (currentStep === Steps.confirm) {
      return (
        <ButtonContainer>
          <Button onClick={handleActivateOfferButton}>{t('home.modalActivateHp+.button')}</Button>
        </ButtonContainer>
      );
    }

    if (currentStep === Steps.finishedWithAction) {
      const instantInkUrl = `${InstantInkBaseUrl(stack)}?require_sign_in=true&require_critical_scope=true&initiator=dashboard&ucdeSessionId=${instantInkSessionId}&jumpid=in_r11549_ii2_ucde_hpplus2ndchance`;

      return (
        <ButtonContainer>
          <LinkButtonGroup>
            <a
              className="button"
              href={instantInkUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={redirectToDashboardFromContinueII}
            >
              {t('home.modalActivateHp+.activationSuccess.primaryButton')}
            </a>
          </LinkButtonGroup>
          <Button onClick={redirectToDashboardFromSkipII} appearance="ghost">
            {t('home.IIOfferScreen.tertiaryButton', { months: instantInkMonths })}
          </Button>
        </ButtonContainer>
      );
    }

    return <></>;
  }, [currentStep, handleActivateOfferButton, instantInkSessionId]);

  const showCloseButton = currentStep === Steps.confirm;

  return (
    <>
      <ButtonGroup>
        <Button onClick={handleShowModal} disabled={!eligible}>
          {t('home.header.signedInUser.activateHpPlus')}
        </Button>
      </ButtonGroup>
      {
        eligible && (
          <AlertModalIcon
            id="alert-modal"
            closeButton={showCloseButton}
            onClose={handleClose}
            show={showModal}
            title={modalHeader()}
            footer={modalFooter()}
            icon={Images.hpPlusBlue}
          >
            {modalSubheader()}
            {currentStep === Steps.confirm && (
              <div className="offer-terms">
                <OfferTermsList>
                  <li>{t('home.modalActivateHp+.bullets.oneBullet')}</li>
                  <li>{t('home.modalActivateHp+.bullets.twoBullet')}</li>
                  <li>{t('home.modalActivateHp+.bullets.threeBullet')}</li>
                </OfferTermsList>
                <OfferTerms>
                  {t('home.modalActivateHp+.bodyFooter')}
                </OfferTerms>
              </div>
            )}
            {[Steps.activating, Steps.activatingInstantInk].includes(currentStep) && (
              <ProgressContainer>
                <ProgressIndicator />
                <p>{t('home.modalActivateHp+.spinner')}</p>
              </ProgressContainer>
            )}
            {currentStep === Steps.loadingTrial && (
              <ProgressContainer>
                <ProgressIndicator />
                <p>{t('home.modalActivateHp+.activationSuccess.bodyCopy2')}</p>
              </ProgressContainer>
            )}
          </AlertModalIcon>
        )
      }

      <AlertModalIcon
        id="alert-error-modal"
        closeButton
        onClose={() => {
          publishEvent(UnableToActivateCloseEvent);
          setActivationError(false);
        }}
        show={activationError}
        footer={(
          <ButtonContainer>
            <Button onClick={handleRetry}>
              {t('home.modalActivateHp+.activationErros.primaryButton')}
            </Button>
            <Button onClick={openGetSupportInNewTab} appearance="secondary">
              {t('home.modalActivateHp+.activationErros.secondaryButton')}
            </Button>
          </ButtonContainer>
        )}
      >
        <TextContainer>
          <img alt="Error" src={Images.error} />
          <ModalTitle>{t('home.modalActivateHp+.activationErros.header')}</ModalTitle>
          <p><b>{t('home.modalActivateHp+.activationErros.subhead')}</b></p>
          <p>
            {t('home.modalActivateHp+.activationErros.bodyCopy1')}
          </p>
          <InnerDivHTML>
            {t('home.modalActivateHp+.activationErros.bodyCopy2', {
              tagPlaceHolderStart: '<b>',
              tagPlaceHolderEnd: '</b>',
              interpolation: { escapeValue: false },
            })}
          </InnerDivHTML>
        </TextContainer>
      </AlertModalIcon>
    </>
  );
};

ActivateOfferModal.propTypes = {
  data: PropTypes.shape({
    country: PropTypes.string.isRequired,
    deviceSKU: PropTypes.string.isRequired,
    encryptedClientID: PropTypes.string.isRequired,
    encryptedDeviceID: PropTypes.string.isRequired,
  }).isRequired,
  eligible: PropTypes.bool.isRequired,
  instantInkMonths: PropTypes.number.isRequired,
};

export default ActivateOfferModal;
