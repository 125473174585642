import { useCallback } from 'react';
import {
  AccountMgtSvcClient,
  UserMgtSvcClientv3
} from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import { getFeatureFlag } from '../utils/featureFlags';
import {
  Entitlement,
  EntitlementCategory,
  EntitlementState,
  getHasServiceWithState
} from 'src/utils/entitlements';

const SMART_ADVANCE_SOLUTIONS = [
  {
    name: 'printAnywhere',
    serviceId: Entitlement.PrintOnTheGo
  },
  {
    name: 'smartDashboard',
    serviceId: Entitlement.SmartDashboard,
    featureFlag: 'smartAdvMobileProductivity'
  }
];

export function useSubscriptionInfoApiCall({ authProvider, stack }) {
  const apiCall = useCallback(
    async ({ accountId }) => {
      let solutions = {};

      const useGrantsInsteadOfEntitlements = await getFeatureFlag({
        key: 'useGrantsInsteadOfEntitlements',
        defaultValue: false
      });

      let userId = null;
      let programInfo = null;

      if (!useGrantsInsteadOfEntitlements) {
        const userClient = new UserMgtSvcClientv3(stack, authProvider);
        const userClientResponse = await userClient.getCurrentActiveUser();
        userId = userClientResponse?.data?.resourceId;
        // this code don't check for offerings
        // therefore we can use V2 to get entitlements
        const accountClient = new AccountMgtSvcClient(stack, authProvider);
        const response = await accountClient.getProgramInfos(accountId, userId);
        programInfo = response.data;
      }

      for (const solution of SMART_ADVANCE_SOLUTIONS) {
        let solutionCanAppear = true;
        if (solution.featureFlag) {
          solutionCanAppear = await getFeatureFlag({
            key: solution.featureFlag,
            defaultValue: false
          });
        }
        let hasSolution = false;
        if (solutionCanAppear) {
          hasSolution = await getHasServiceWithState({
            programInfo,
            tenantId: userId,
            category: EntitlementCategory.Benefit,
            serviceIds: [Entitlement.SmartAdvance],
            states: [EntitlementState.Enabled, EntitlementState.Pending],
            subServiceIds: [solution.serviceId],
            subStates: [EntitlementState.Enabled],
            useGrantsInsteadOfEntitlements
          });
        }

        solutions = {
          ...solutions,
          [solution.name]: hasSolution
        };
      }

      return { solutions };
    },
    [authProvider, stack]
  );

  return useApiCall({
    apiCall,
    init: false
  });
}
