import React, { useState } from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { TranslatorFunctionType } from '@/types/localization'
import IconPrinterInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_printer_ink_drop'
import { AddOn } from '../types/subscription'

import {
  BoosterCardPanel,
  LabelDarkBlock,
  PrintPlanBlock,
  FooterWrapper,
  ChevronRightIcon,
  ErrorButtonsContainer
} from './styles'

import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'
import { InkPlanDetails } from '@monetization/hpaip-ui-shared-components'
import {
  AddOnPaperStepOne,
  PaperOnPaasModal
} from '@monetization/hpaip-ui-shared-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useUpdateSubscription from '@/hooks/useUpdateSubscription/useUpdateSubscription'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import Button from '@veneer/core/dist/esm/scripts/button'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'

type AddonProps = {
  t: TranslatorFunctionType
  isDisabled?: boolean
  state?: string
  inkPlan?: SubscriptionOptionsResponse
  subscriptionId?: string
  isRemovePaperEnabledFlag?: boolean
  activePaperSubscription?: boolean
  shippingAddress?: any
  paperInfo?: any
  controllerId?: string
  handleAddedPaperNotification?: (value: boolean) => void
  isPaperEnabled: boolean
} & AddOn

export function AddOnDevice(props: AddonProps) {
  const {
    t,
    isDisabled,
    state,
    inkPlan,
    subscriptionId,
    isRemovePaperEnabledFlag,
    activePaperSubscription,
    shippingAddress,
    paperInfo,
    controllerId,
    handleAddedPaperNotification,
    isPaperEnabled
  } = props

  const { device } = useSubscriptionDetails()
  const [isPaperAdded, setIsPaperAdded] = useState<boolean>(false)
  const { enableManagePaperPostEnrollment } = useFlags()
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const isRemovePaperEnabled =
    isRemovePaperEnabledFlag && enableManagePaperPostEnrollment
  const isCancellationInProgress =
    state === 'deactivating' || state === 'inactive' || state === 'pending'

  const handleRemovePaperClick = () => {
    const paperPlanCancellationUrl = `${removeUCDE}/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`
    window.location.href = paperPlanCancellationUrl
  }
  const handleChangePlan = () => {
    const paperPlanChangeUrl = `${removeUCDE}/hp-all-in-print-plan/update-plan`
    window.location.href = paperPlanChangeUrl
  }
  const handleAddPaperClick = () => {
    setModalState((prevState) => ({
      ...prevState,
      stepOne: !prevState.stepOne
    }))
  }
  const [modalState, setModalState] = useState({
    stepOne: false,
    stepTwo: false,
    error: false
  })

  const { updateSubscription } = useUpdateSubscription(
    subscriptionId,
    paperInfo?.optionId,
    controllerId
  )

  const handlePaperStepOneSubmit = async () => {
    const success = await updateSubscription()
    setModalState({ stepOne: false, stepTwo: !success, error: !success })
  }
  let paperEligibility = ''

  if (isRemovePaperEnabled || isPaperAdded) {
    paperEligibility = 'removePaperService'
  } else if (isPaperEnabled) {
    paperEligibility = 'addPaperService'
  }
  const printPlanAddon = (
    <StyleThemeProvider theme={useTheme()}>
      <BoosterCardPanel>
        <PrintPlanBlock isDisabled={isDisabled}>
          <IconPrinterInkDrop size={32} className="iconLabel" />
          <span className="body-large">
            {t('master-device-detail.print-plan', {
              defaultValue: 'My plan'
            })}
          </span>
        </PrintPlanBlock>
        <div>
          <LabelDarkBlock isDisabled={isDisabled}>
            <InkPlanDetails
              t={t}
              pages={inkPlan?.pagesPerMonth}
              rollover={inkPlan?.rolloverPages}
              additional={inkPlan?.additionalPages}
              additionalPrice={inkPlan?.additionalPagesPrice}
              handleaddpaperService={handleAddPaperClick}
              handleremovePaperService={handleRemovePaperClick}
              paperEligibility={paperEligibility}
              isSubscriptionHavePaper={activePaperSubscription}
            />
          </LabelDarkBlock>
        </div>

        <FooterWrapper disabled={isCancellationInProgress}>
          <span onClick={handleChangePlan}>
            {t('ink-plan-details.change-plan', {
              defaultValue: 'Change Plan'
            })}
          </span>
          <ChevronRightIcon onClick={handleChangePlan} />
        </FooterWrapper>
        <>
          <AddOnPaperStepOne
            show={modalState.stepOne}
            pricePlan={String(paperInfo?.priceInfo)}
            pagesPerMonth="100"
            onClose={() => setModalState({ ...modalState, stepOne: false })}
            onSubmit={handlePaperStepOneSubmit}
          />
          <PaperOnPaasModal
            onClose={() => {
              handleAddedPaperNotification(true)
              setModalState({ ...modalState, stepTwo: false })
              setIsPaperAdded(true)
            }}
            show={modalState.stepTwo}
            pricePlan={String(paperInfo?.priceInfo)}
            pagesPerMonth="100"
            shippingAddress={{
              fullName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
              address: `${shippingAddress?.address}`,
              address2: `${shippingAddress?.address2}`,
              city: `${shippingAddress?.city}`,
              state: `${shippingAddress?.state}`,
              postalCode: `${shippingAddress?.postalCode}`,
              countryCode: `${shippingAddress?.countryCode}`
            }}
            printerName={device?.name}
            handleChange={handleChangePlan}
          />
          <Modal
            align="start"
            closeButton={true}
            show={modalState.error}
            onClose={() => setModalState({ ...modalState, error: false })}
            data-testid="modal-error"
            footer={
              <ErrorButtonsContainer>
                <Button
                  appearance="secondary"
                  onClick={handlePaperStepOneSubmit}
                >
                  {t('dashboard.error-section.button', 'Try it again')}
                </Button>
              </ErrorButtonsContainer>
            }
            title={t('dashboard.error-section.title', 'Something went wrong')}
          >
            <p>
              {t(
                'dashboard.error-section.description',
                'It has not been possible to add the Paper Add-on service to your plan'
              )}
            </p>
          </Modal>
        </>
      </BoosterCardPanel>
    </StyleThemeProvider>
  )
  return printPlanAddon
}
