import { useEffect, useCallback } from 'react'
import InfoKeys from '../../types/InfoKeys'
import { useAppContext } from '@/context/AppContext'
import useAppActions from '../useAppActions'
import InfoDatas from '../../types/InfoDatas'
const loading: Partial<Record<InfoKeys, boolean>> = {}
export default function useDataFetcher<K extends InfoKeys>(
  key: K,
  fetcher?: () => Promise<InfoDatas[K]>
) {
  const { [key]: info } = useAppContext().state
  const { setIsFetching, setErrorFetch, setSucessFetch } = useAppActions()

  const asyncGetInfo = async (force = false) => {
    const { data, error } = info
    const shouldFetchData = !data && !error && !loading[key]

    if (shouldFetchData || force) {
      loading[key] = true
      setIsFetching(key, true)
      try {
        setSucessFetch(key, await fetcher())
      } catch (e) {
        setErrorFetch(key, e)
      } finally {
        loading[key] = false
        setIsFetching(key, false)
      }
    }
  }

  const forceRefresh = useCallback(async () => {
    if (fetcher) asyncGetInfo(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher])

  useEffect(() => {
    if (fetcher) asyncGetInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher])

  return {
    info,
    forceRefresh
  }
}
