import React, { useCallback, useEffect, useState } from 'react'
import Shipping from '../Shipping/Shipping'
import Billing from '../Billing/Billing'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
import useGetText from '@/hooks/useGetText'
import { Container, NotificationContainer, StyledMainTitle } from '../styles'
import SecuritySessionExpiredModal from '../shared/SecuritySessionExpiredModal'

export const ShippingBillingContainer = ({ commonProps }) => {
  const getText = useGetText()
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [notification, setNotification] = useState(0)
  const refreshNotification = useCallback(async (notification) => {
    setNotification(notification)
    setRefreshCounter((prevCounter) => prevCounter + 1)
  }, [])

  const {
    criticalScopeError,
    criticalScopeLoading,
    sessionModal,
    handleSessionModal
  } = commonProps

  useEffect(() => {
    if (criticalScopeError && !criticalScopeLoading) {
      handleSessionModal()
    }
  }, [criticalScopeError, criticalScopeLoading])
  const enhancedCommonProps = {
    ...commonProps,
    refreshNotification
  }

  return (
    <>
      <Container>
        <StyledMainTitle role="heading" aria-level="1">
          {getText('mainTitle', { defaultValue: 'Shipping & Billing' })}
        </StyledMainTitle>

        {sessionModal &&
          commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions
            ?.length > 0 && (
            <SecuritySessionExpiredModal
              show={sessionModal}
              onClose={handleSessionModal}
            />
          )}
        {(commonProps?.notificationList?.length > 0 ||
          notification ||
          commonProps?.localNotificationList?.length > 0) && (
          <NotificationContainer>
            <MultipleNotification
              key={refreshCounter}
              multiNotificationArray={[
                ...commonProps?.notificationList,
                ...commonProps?.localNotificationList,
                notification
              ]}
              screenPath="/ShippingBillingManagementReact/ShippingAndBilling/"
            />
          </NotificationContainer>
        )}

        <Shipping commonProps={enhancedCommonProps} />
        <Billing commonProps={enhancedCommonProps} />
      </Container>
    </>
  )
}
export default ShippingBillingContainer
