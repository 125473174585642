const checkCancellationTriggered = (pendingData: any, printerEntityId: any) => {
  if (!pendingData) return false

  const filteredCancellations = pendingData?.contents?.filter(
    (content: any) =>
      content?.type === 'cancellation' &&
      (content?.reason?.type === 'cancellationAfterTrialPeriod' ||
        content?.reason?.type === 'cancellationWithinTrialPeriod') &&
      content?.entityId === printerEntityId
  )

  return Boolean(filteredCancellations[0]) || false // Return the first element or false
}

export default checkCancellationTriggered
