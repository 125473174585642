import { createContext, useContext } from 'react'
import AppDispatch from '@/types/AppDispatch'
import AppState from '@/types/AppState'

const AppContext = createContext<{
  state?: AppState
  dispatch?: AppDispatch
}>(undefined)

export const useAppContext = () => useContext(AppContext)

export default AppContext
