import React, { useEffect } from 'react'
import {
  CdmEvent,
  PublishCdmEventsOptions,
  useAnalytics
} from '../../contexts/AnalyticsContext'

export interface DisplayObserverProps {
  eventProps: CdmEvent
  options?: PublishCdmEventsOptions
}

export const DisplayObserver: React.FC<DisplayObserverProps> = ({
  children,
  eventProps,
  options
}) => {
  const { publishEvent } = useAnalytics()

  useEffect(() => publishEvent(eventProps, options), [])

  return <>{children}</>
}
