import { tokens } from '@veneer/tokens'
import { EDelinquencyNotification } from '../../types/pendingChanges'

const { red7 } = tokens.color
const getCardBorderColor = (expirationStatus): string => {
  if (!expirationStatus) return ''
  switch (expirationStatus) {
    case EDelinquencyNotification.ABOUT_TO_EXPIRE:
      return '#FFA500'
    case EDelinquencyNotification.EXPIRED:
      return '#CC2C00'
    case EDelinquencyNotification.OVERDUE:
      return '#FF8900'
    case EDelinquencyNotification.SUSPENDED:
      return `${red7}`
    default:
      return ''
  }
}
export default getCardBorderColor
