import {
  PrinterSubscriptionData,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import * as React from 'react'
import { FaqCard } from '../../FaqCard'
import { PrintCards } from '../../PrintCards'
import { ShipmentHistory } from '../../ShipmentHistory'
import { SupportText } from '../../SupportText'
import { ShipmentCard } from 'src/types/shipmentCard'
import {
  Container,
  FaqPanel,
  Section,
  Subheader,
  Subtitle,
  NotificationSection
} from '../styles'
import CommonProps from '../../../types/commonProps'
import { RenderControlProvider } from 'src/contexts'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'

interface ContentProps {
  subscription: PrinterSubscriptionData
  shipmentCards: ShipmentCard[]
  shipmentHistoryTableRef: React.MutableRefObject<HTMLDivElement>
  commonProps: CommonProps
  isCustomError?: boolean
  isCissPrinter?: boolean
  printerData?: any
  ordersState?: any
  notificationData?: any
}

const Content: React.FunctionComponent<ContentProps> = ({
  subscription,
  shipmentCards,
  shipmentHistoryTableRef,
  isCustomError,
  isCissPrinter,
  commonProps,
  printerData,
  ordersState,
  notificationData
}) => {
  const { t } = commonProps

  const subTitleText = isCissPrinter
    ? t(
        'shipment.header.subtitle-isprinter',
        'Supplies replacement are shipped automatically, based on your ink use. We monitor your ink levels and ship ink bottles before you need them (not monthly).​'
      )
    : t(
        'shipment.header.subtitle-not-isprinter',
        'Replacement cartridges are shipped automatically, based on your ink or toner use. We monitor your ink levels and ship cartridges before you need them (not monthly).'
      )
  return (
    <>
      {subscription?.printer?.state !== SubscriptionStateEnum.CANCELED && (
        <Section data-testid="hpx-header-sub">
          <Subheader>
            <Subtitle>{subTitleText}</Subtitle>
          </Subheader>
        </Section>
      )}
      <NotificationSection>
        <MultipleNotification
          multiNotificationArray={notificationData?.notificationsList}
        />
      </NotificationSection>
      <Container>
        <RenderControlProvider>
          {subscription?.printer?.state !== SubscriptionStateEnum.CANCELED && (
            <>
              <PrintCards
                shipmentCards={shipmentCards}
                shipmentHistoryTableRef={shipmentHistoryTableRef}
                isCustomError={isCustomError}
                isCissPrinter={isCissPrinter}
                commonProps={commonProps}
                printerData={printerData}
              />
            </>
          )}
          <FaqPanel isCissPrinter={isCissPrinter}>
            <FaqCard commonProps={commonProps} isCissPrinter={isCissPrinter} />
            {!isCissPrinter && (
              <SupportText
                shipmentCards={shipmentCards}
                commonProps={commonProps}
              />
            )}
          </FaqPanel>
          <ShipmentHistory
            shipmentHistoryTableRef={shipmentHistoryTableRef}
            subscription={subscription}
            isCissPrinter={isCissPrinter}
            commonProps={commonProps}
            ordersState={ordersState}
          />
        </RenderControlProvider>
      </Container>
    </>
  )
}

export default Content
