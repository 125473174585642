import React from 'react'
import { ThemeProvider, ThemeContextInterface } from '@veneer/theme'
import { ShellProvider } from '../src/context/shellContext'
import { getStack } from '../src/helpers/getStack'
import setEnvironmentVariables from '../src/helpers/setEnvironmentVariables'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { LaunchDarklyProvider } from '../src/lib/launchdarkly'
import { SubscriptionManagement } from '../src'
import { ApolloProvider } from '@apollo/client'
import useApolloClient from '../src/hooks/useApolloClient'
declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType) {
  // You can find the "stack" information by props
  const { stack, mode } = props
  setEnvironmentVariables(getStack(stack))
  const themeMode = mode ? (mode as ThemeContextInterface['mode']) : 'light'
  const client = useApolloClient()
  const [isMounted, setIsMounted] = React.useState(true)

  const toggleIsMounted = () => {
    setIsMounted(false)
    setTimeout(() => {
      setIsMounted(true)
    }, 10)
  }

  if (!process.env.LAUNCHDARKLY_CLIENTSIDE_ID) return null
  else {
    return (
      <section
        className={`${projectNames.namespace}`}
        id={projectNames.packageJsonName}
      >
        <ApolloProvider client={client}>
          <ThemeProvider mode={themeMode}>
            <ShellProvider value={props}>
              <LaunchDarklyProvider {...props}>
                {isMounted && (
                  <SubscriptionManagement
                    {...props}
                    toggleIsMounted={toggleIsMounted}
                  />
                )}
              </LaunchDarklyProvider>
            </ShellProvider>
          </ThemeProvider>
        </ApolloProvider>
      </section>
    )
  }
}
