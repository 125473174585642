import {
  AnalyticsProvider,
  parseAuxParams
} from '@monetization/hpaip-ui-shared-components'
import React, { useMemo } from 'react'
import { analyticsEvents } from '../analyticsEvents'
import moment from 'moment'

export const ShippingBillingAnalyticsProvider: React.FC<any> = ({
  children,
  commonProps
}) => {
  const analytics = commonProps?.analytics
  const subscriptionId =
    commonProps?.hpaip?.hpOneSubscriptionInfo?.[0]?.subscriptionId
  const entityStartDate =
    commonProps?.commonNotificationData?.printerData?.printer?.entityStartDate
  const subscriptionDate = moment(entityStartDate).format('DD MMMM YYYY')

  const auxParams = useMemo(() => {
    const params = parseAuxParams({
      subscriptionId: subscriptionId,
      subscriptionStartDate: subscriptionDate
    })

    return params
  }, [subscriptionId, subscriptionDate])

  return useMemo(
    () => (
      <AnalyticsProvider
        analytics={analytics}
        defaults={{
          screenPath: '/ShippingBillingManagementReact/ShippingAndBilling/',
          activity: 'AcctMgmt-v01',
          version: '1.3.0',
          ...auxParams
        }}
        events={analyticsEvents}
      >
        {children}
      </AnalyticsProvider>
    ),
    [analytics, auxParams, children]
  )
}
