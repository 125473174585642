import { useCallback } from 'react';
import {
  AccountMgtSvcClient,
  AccountMgtSvcClientV3
} from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import {
  getDeviceHasPrintAsAService,
  getDeviceSubscriptionStatus,
  getDeviceSubscriptionUpdatePlan,
  getDeviceHasPrintOnTheGo
} from 'src/utils/entitlements';
import { getFeatureFlag } from 'src/utils/featureFlags';

export default function useDeviceSolutionsApiCall({
  authProvider,
  stack,
  deviceId,
  accountId
}) {
  const apiCall = useCallback(async () => {
    const useGrantsInsteadOfEntitlements = await getFeatureFlag({
      key: 'useGrantsInsteadOfEntitlements'
    });
    let SvcClient = AccountMgtSvcClient;
    if (useGrantsInsteadOfEntitlements) {
      SvcClient = AccountMgtSvcClientV3;
    }
    const client = new SvcClient(stack, authProvider);
    const response = await client.getDeviceProgramInfos(accountId, deviceId);
    const programInfo = response?.data?.programInfo;
    const params = {
      programInfo,
      tenantId: accountId,
      deviceId,
      useGrantsInsteadOfEntitlements
    };
    const subscriptionStatus = await getDeviceSubscriptionStatus(params);
    const subscriptionUpdatePlan = await getDeviceSubscriptionUpdatePlan(
      params
    );
    const hasPrintAsAService = await getDeviceHasPrintAsAService(params);
    const hasPrintOnTheGo = await getDeviceHasPrintOnTheGo(params);
    return {
      subscriptionStatus,
      subscriptionUpdatePlan,
      hasPrintAsAService,
      hasPrintOnTheGo
    };
  }, [stack, authProvider, accountId, deviceId]);

  return useApiCall({ apiCall });
}
