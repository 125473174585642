import { CreditCardType } from '../types/BillingInfo'
import { CreditCardInfo } from '../types/CreditCardInfo'
import Images from '@/assets/images'

const CardList = {
  alipay: {
    cardName: 'Alipay',
    cardImage: Images.alipay,
    testid: 'cardImgAlipay'
  },
  amex: { cardName: 'Amex', cardImage: Images.amex, testid: 'cardImgAmex' },
  'american-express': {
    cardName: 'Amex',
    cardImage: Images.amex,
    testid: 'cardImgAmex'
  },
  diners: {
    cardName: 'Diners',
    cardImage: Images.diners,
    testid: 'cardImgDiners'
  },
  discover: {
    cardName: 'Discover',
    cardImage: Images.discover,
    testid: 'cardImgDiscover'
  },
  elo: { cardName: 'Elo', cardImage: Images.elo, testid: 'cardImgElo' },
  hiper: { cardName: 'Hiper', cardImage: Images.hiper, testid: 'cardImgHiper' },
  hipercard: {
    cardName: 'Hipercard',
    cardImage: Images.hipercard,
    testid: 'cardImgHipercard'
  },
  jcb: { cardName: 'Jcb', cardImage: Images.jcb, testid: 'cardImgJcb' },
  maestro: {
    cardName: 'Maestro',
    cardImage: Images.maestro,
    testid: 'cardImgMaestro'
  },
  'master-card': {
    cardName: 'MasterCard',
    cardImage: Images.masterCard,
    testid: 'cardImgMastercard'
  },
  mir: { cardName: 'Mir', cardImage: Images.mir, testid: 'cardImgMir' },
  paypal: {
    cardName: 'Paypal',
    cardImage: Images.paypal,
    testid: 'cardImgPaypal'
  },
  unionpay: {
    cardName: 'Unionpay',
    cardImage: Images.unionpay,
    testid: 'cardImgUnionpay'
  },
  visa: { cardName: 'Visa', cardImage: Images.visa, testid: 'cardImgVisa' },
  directDebit: {
    cardName: 'directDebit',
    cardImage: Images.sepa,
    testid: 'cardImgDirectDebit'
  }
}

export default function getPaymentCardInfo(
  creditCardType: CreditCardType
): CreditCardInfo {
  const cardType = creditCardType?.cardTypeKey?.toLowerCase() || 'directDebit'
  return (
    CardList[cardType] ?? {
      cardName: cardType,
      cardImage: Images.generic,
      testid: 'cardImgGeneric'
    }
  )
}
