import tokens from '@veneer/tokens'
import { smartQueries } from '@/utils/device/device'
import styled from 'styled-components'
export const StyledNoOrders = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};

  p {
    margin: 0;
    a {
      color: #0278ab;
      text-decoration: none;
      line-height: ${tokens.typography.lineHeight3};

      &:focus,
      &:active {
        border: 1px solid #0278ab;
      }
    }
  }

  .enroll-hp-one {
    background-color: ${tokens.color.white};
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    min-height: 532px;
    padding: 0 10px;
    width: 100%;
    text-align: center;
    box-shadow: 0 1px 4px 1px #21212133;
    ${smartQueries.mobileXS(`
      min-height: 250px;
    `)}
  }
`
