import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useRef } from 'react'
import { JarvisAuthProvider } from '@jarvis/web-http'

const useApolloClient = (authProvider: JarvisAuthProvider, uri: string) => {
  const httpLink = createHttpLink({
    uri
  })

  const authLink: ApolloLink | undefined = setContext(
    /* istanbul ignore next */
    async (_, { headers }) => {
      const token = await authProvider.getAccessToken()
      if (!token) return undefined

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    }
  )

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  const apolloClientRef = useRef<ApolloClient<NormalizedCacheObject>>(client)

  return apolloClientRef.current
}

export default useApolloClient
