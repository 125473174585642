import React from 'react'
import {
  IconCheckmarkCircle as IconCheckCircle,
  IconShield,
  IconWarningAlt
} from '@veneer/core'
import PropTypes from 'prop-types'
import { Sizes, BadgeColors } from '../static/consts'

const StatefulIcon = ({ value, filled, size = Sizes.ICON_SIZE, isHPX }) => {
  const strValue = String(value)
  const color =
    strValue === 'true' ? BadgeColors.lightGreen : BadgeColors.orange
  return strValue === 'true' ? (
    !isHPX ? (
      <IconCheckCircle
        size={size}
        color={color}
        filled={filled}
        data-testid="ss-icon-check-circle"
      />
    ) : (
      <IconShield
        size={24}
        color={color}
        filled={filled}
        data-testid="ss-icon-check-circle"
      />
    )
  ) : !isHPX ? (
    <IconWarningAlt
      size={size}
      color={color}
      filled={filled}
      data-testid="ss-icon-warning-alt"
    />
  ) : (
    <IconShield
      size={24}
      color={color}
      filled={filled}
      data-testid="ss-icon-warning-alt"
    />
  )
}

StatefulIcon.propTypes = {
  value: PropTypes.any.isRequired,
  filled: PropTypes.bool,
  size: PropTypes.number
}

StatefulIcon.defaultProps = {
  filled: false,
  size: Sizes.ICON_SIZE
}

export default StatefulIcon
