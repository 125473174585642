import { AssetsProvider } from '@jarvis/web-assets-provider'
import { Account } from '@/types/Account'

export enum ActionsType {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_SUBMITTED = 'SET_IS_SUBMITTED',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_ERROR = 'SET_ERROR',
  SET_SUCCESS_FETCH = 'SET_SUCCESS_FETCH',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_ERROR_FETCH = 'SET_ERROR_FETCH',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER'
}

export type Actions = {
  type: ActionsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const setIsLoadingAction = (data: boolean): Actions => ({
  type: ActionsType.SET_IS_LOADING,
  data
})

export const setIsSubmittedAction = (data: boolean): Actions => ({
  type: ActionsType.SET_IS_SUBMITTED,
  data
})
export const setAccount = (data: Account): Actions => ({
  type: ActionsType.SET_ACCOUNT,
  data
})

export const setErrorAction = (data: boolean): Actions => ({
  type: ActionsType.SET_ERROR,
  data
})

type ReturnSetAssetsProvider = {
  type: ActionsType.SET_ASSETS_PROVIDER
  data: AssetsProvider
}

export const setAssetsProvider = (
  assetsProvider: AssetsProvider
): ReturnSetAssetsProvider => ({
  type: ActionsType.SET_ASSETS_PROVIDER,
  data: assetsProvider
})
