import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const PreviousBillingCardWrapper = styled.div`
  margin: ${layout.size6} 0;
  border-radius: ${layout.cornerRadius4};
  background: ${color.white};
  box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
  padding: ${layout.size8};
  font-family: ${typography.family0};
  color: ${color.gray12};
`

export const ErrorText = styled.p`
  text-align: center;
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  color: ${color.gray12};
`

export const Header = styled.p`
font-size: ${typography.size4}; \
 line-height: ${typography.lineHeight4};
color: ${color.gray12};
margin-bottom: ${layout.size6};
`

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${layout.size1};
`

export const LabelText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  color: ${color.gray12};
`

export const DateRangeText = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  color: ${color.gray9};
`

export const PreviousInvoiceBlock = styled.div<{
  onClick?: () => void
  children?: React.ReactNode
}>`
  margin-top: ${layout.size6};
  cursor: pointer;
  width: fit-content;
`

export const InvoiceText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  color: ${color.hpBlue7};
`
