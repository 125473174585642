export const captureAnalyticsClickEvent = (
  notificationType: string,
  screenPath: any
) => {
  return {
    screenName: notificationType,
    action: 'ControlHyperLinkClicked',
    controlName: 'Dismiss',
    screenPath: screenPath
  }
}
export const captureAnalyticsModuleEvent = (
  notificationType: string,
  screenPath: any
) => {
  return {
    screenName: notificationType,
    action: 'ModuleDisplayed',
    screenPath: screenPath
  }
}
