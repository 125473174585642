import React, { FC } from 'react'
import {
  StyledModal,
  ImageBlock,
  ModalBody,
  Title,
  SubTitle,
  PriceText,
  ButtonBlock
} from './styles'
import useGetText from '../../hooks/useGetText'
import Images from '../../../assets/images'
import { getDefaultText, getPriceText } from './defaultText'
import Button from '@veneer/core/dist/scripts/button'
import PlanItems from './PlanItems'
import ShippingInfo from './ShippingInfo'
import { toCurrency } from '../../../utils/currency/currency'

interface ShippingAddress {
  fullName: string
  address: string
  address2?: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

interface PaperOnPaasModalProps {
  show: boolean
  pricePlan: string
  pagesPerMonth: string
  shippingAddress: ShippingAddress
  printerName: string
  onClose: () => void
  handleChange: () => void
}

export const PaperOnPaasModal: FC<PaperOnPaasModalProps> = (
  props: PaperOnPaasModalProps
) => {
  const {
    show,
    pricePlan,
    pagesPerMonth,
    shippingAddress,
    printerName,
    onClose,
    handleChange
  } = props
  const getText = useGetText('paper-on-paas')
  const currencyPricePlan = toCurrency(Number(pricePlan))

  return (
    <StyledModal show={show} closeButton onClose={onClose}>
      <ImageBlock>
        <img src={Images.AOPModalBanner} alt="banner" />
      </ImageBlock>
      <ModalBody>
        <Title>
          {getText('title', { defaultText: getDefaultText('title') })}
        </Title>
        <SubTitle data-testid="sub-title">
          {getText('sub-title', {
            defaultText: getDefaultText('sub-title')
          })}
        </SubTitle>
        <PriceText>
          {getText('price-text', {
            currencyPricePlan,
            defaultText: getPriceText(currencyPricePlan)
          })}
        </PriceText>

        <PlanItems pagesPerMonth={pagesPerMonth} printerName={printerName} />

        {shippingAddress && (
          <ShippingInfo
            shippingAddress={shippingAddress}
            handleChange={handleChange}
          />
        )}
        <ButtonBlock>
          <Button onClick={onClose}>
            {getText('done', { defaultText: getDefaultText('done') })}
          </Button>
        </ButtonBlock>
      </ModalBody>
    </StyledModal>
  )
}
