import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@veneer/core';
import RefreshButton from 'src/shared/RefreshButton';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import useDevicesApiCall from 'src/hooks/useDevicesApiCall';
import DropdownItem from './Dropdown';
import AccordionHeaderPrinter from './AccordionHeaderPrinter';
import NoPrintersImage from 'src/assets/images/generic-printer.svg';
import {
  Header,
  NoPrinters,
  NoPrintersIcon,
  PrintersAccordion
} from './styles';
import PrintersCardIcon from 'src/assets/images/printers-icon.svg';
import { STATUS } from 'src/utils/statusDictionary';

const PrintersTenantPage = () => {
  const { authProvider, stack } = useRootContext();
  const { t } = useI18n();

  const deviceInfos = useDevicesApiCall({ authProvider, stack });

  const refreshData = useCallback(() => {
    deviceInfos.forceFetch();
  }, [deviceInfos]);

  const [data, setData] = useState([]);
  const isLoading = deviceInfos.pending;
  const numberOfDevices = deviceInfos?.data?.length;

  const getItems = useCallback(
    () =>
      deviceInfos?.data?.map((device) => {
        const key = `${device.deviceId}`;
        const isDisabled =
          device?.printerHealth === STATUS.HEALTH.not_available;
        return {
          key,
          header: {
            centralArea: (
              <AccordionHeaderPrinter
                key={key}
                device={device}
              />
            )
          },
          content: isDisabled ? null : (
            <DropdownItem
              key={key}
              device={device}
            />
          ),
          id: key,
          disabled: isDisabled,
          expanded:
            data?.find((dataItem) => dataItem.key === key)?.expanded || false
        };
      }) || [],
    [data, deviceInfos?.data]
  );

  useEffect(() => {
    if (deviceInfos?.data && deviceInfos.data.length > 0 && data.length === 0) {
      setData(getItems());
    }
  }, [data.length, deviceInfos, getItems]);

  const handleExpand = (_, index) => {
    const updatedData = [...data];
    updatedData[index].expanded = true;
    setData(updatedData);
  };

  const handleCollapse = (_, index) => {
    const updatedData = [...data];
    updatedData[index].expanded = false;
    setData(updatedData);
  };

  const getCardContent = () => {
    const getMainContent = () => {
      if (data.length === 0) {
        const noPrintersText = t('homev2.printersTenantCard.noPrinters');
        return (
          <NoPrinters>
            <NoPrintersIcon
              src={NoPrintersImage}
              alt={noPrintersText}
            />
            {noPrintersText}
          </NoPrinters>
        );
      }

      return (
        <PrintersAccordion
          onExpand={handleExpand}
          onCollapse={handleCollapse}
          items={data}
        />
      );
    };

    const getHeaderTitle = () => {
      if (numberOfDevices === 0) {
        return t('homev2.printersTenantCard.header.printerPlural', {
          printers: ''
        });
      }
      if (numberOfDevices > 1) {
        return t('homev2.printersTenantCard.header.printerPlural', {
          printers: numberOfDevices
        });
      }
      return t('homev2.printersTenantCard.header.printerSingular', {
        printers: numberOfDevices
      });
    };

    return (
      <>
        <Header>
          <img
            src={PrintersCardIcon}
            alt={getHeaderTitle()}
          />
          <h5>{getHeaderTitle()}</h5>
          <RefreshButton
            onClick={refreshData}
            isRefreshing={isLoading}
          />
        </Header>
        {getMainContent()}
      </>
    );
  };

  return (
    <Card
      data-testid="printers-tenant-card"
      content={getCardContent()}
      appearance="dropShadow"
    />
  );
};

export default PrintersTenantPage;
