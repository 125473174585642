import React, { useRef, useEffect, useContext } from 'react'
// import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'
import { ThemeProvider } from '@veneer/core'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
// import WarningMarker from '../WarningMarker'
import CardItemContent from '../CardItemContent'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
} from '../../static/consts'
import AuthContext from '../../contexts/AuthContext'

const Web = ({
  strings,
  getSecurityCheckStringsForProtectionStatus,
  isComplianceOff,
  isCompliant,
  isUnknown
}) => {
  const { isHPX } = useContext(AuthContext)
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current && ref.current.children[0]) {
      ref.current.children[0].style.padding = '0'
    }
  }, [ref])

  const accordionExpandedContent = () => {
    return (
      <List>
        {ConstraintsDataTypes.filter((_, index) => index === 3).map(
          (securityCheckName) => {
            const { title, description } =
              getSecurityCheckStringsForProtectionStatus(
                securityCheckName,
                strings,
                isCompliant,
                isHPX
              )
            const isNetworkSecurityCheck =
              securityCheckName === ConstraintsDataTypesKeys.network
            if (isComplianceOff && isNetworkSecurityCheck) return <></>
            return (
              <CardItemContent
                key={title}
                title={title}
                content={<>{description}</>}
              />
            )
          }
        )}
      </List>
    )
  }

  const unknownCard = (
    <CardItem
      customCss={{ paddingLeft: isHPX ? '24px' : '' }}
      leftItem={<StatefulIcon value={false} isHPX={true} />}
      contentItem={
        <CardItemContent
          title={strings.title}
          content={<>{strings.description}</>}
        />
      }
    />
  )

  const accordionCard = (
    <ThemeProvider shape="sharp">
      <CardItem
        data-testid="ss-card"
        leftItem={
          <StatefulIcon
            value={isComplianceOff || isCompliant}
            isHPX={true}
          ></StatefulIcon>
        }
        contentItem={<>{accordionExpandedContent()}</>}
      ></CardItem>
    </ThemeProvider>
  )

  const content = !isComplianceOff ? (
    <CardItem
      customCss={{ padding: '0px' }}
      contentItem={accordionCard}
      customRef={ref}
    />
  ) : (
    <></>
  )

  return isUnknown ? unknownCard : content
}

export default Web
