import { useMemo, useEffect } from 'react'
import getApplicationStack from '../../helpers/getApplicationStack'
import getAuthProvider from '../../helpers/getAuthProvider'

import { useLazyFetchRequest } from '../useLazyFetchRequest'
import { fetchActivePrinterSubscription } from './fetchPrinterSubscription'
import { PrinterSubscription } from '../useGetActivePrinterBySubscription/fetchPrinterSubscription'

export interface PrinterSubscriptionData extends Partial<PrinterSubscription> {
  isLoading: boolean
  called: boolean
  error?: Error
}

export const useGetActivePrinterBySubscription = (
  subscriptionId: string,
  launchdarklyFlags: any
): PrinterSubscriptionData => {
  const auth = getAuthProvider()
  const usedStack = getApplicationStack()
  const [requestLazyData, lazyFetchStateData] = useLazyFetchRequest(() =>
    fetchActivePrinterSubscription(
      auth,
      usedStack,
      subscriptionId,
      launchdarklyFlags
    )
  )

  useEffect(() => {
    if (!lazyFetchStateData.called) {
      requestLazyData()
    }
  }, [requestLazyData, lazyFetchStateData.called])

  return useMemo(() => {
    return {
      subscriptionId: lazyFetchStateData.data?.subscriptionId,
      printer: lazyFetchStateData.data?.printer || null,
      addons: lazyFetchStateData.data?.addons || null,
      instantInk: lazyFetchStateData.data?.instantInk || null,
      root: lazyFetchStateData.data?.root || null,
      isLoading: lazyFetchStateData.loading,
      called: lazyFetchStateData.called,
      error: lazyFetchStateData.error || null,
      replacement: lazyFetchStateData.data?.replacement,
      paperEligibilityInfo: lazyFetchStateData.data?.paperEligibilityInfo,
      optionsData: lazyFetchStateData.data?.optionsData || null,
      isRemovePaper: lazyFetchStateData.data?.isRemovePaper,
      pendingData: lazyFetchStateData.data?.pendingData || null,
      activePaperSubscription: lazyFetchStateData.data?.activePaperSubscription,
      cancellationInfo: lazyFetchStateData.data?.cancellationInfo || null,
      fullfillmentData: lazyFetchStateData.data?.fullfillmentData,
      isDeviceShipped: lazyFetchStateData.data?.isDeviceShipped
    }
  }, [lazyFetchStateData])
}
