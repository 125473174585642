import { SupplyDelivery } from 'src/utils/statusDictionary';

export const BorderType = {
  none: 'border-none',
  error: 'border-error',
  warning: 'border-warning'
};

export const SupplyName = {
  cyan: 'cyan',
  magenta: 'magenta',
  yellow: 'yellow',
  black: 'black',
  tricolor: 'tricolor'
};

export const ColorCodes = {
  C: 'C',
  M: 'M',
  Y: 'Y',
  K: 'K',
  CMY: 'CMY'
};

export const ColorMapping = {
  C: SupplyName.cyan,
  M: SupplyName.magenta,
  Y: SupplyName.yellow,
  K: SupplyName.black,
  CMY: SupplyName.tricolor
};

export const LevelForState = {
  depleted: 0,
  fulfillment: 100,
  low: 20,
  ok: 100,
  unknown: 0,
  veryLow: 10,
  veryVeryLow: 10
};

export const SupplyState = {
  error: 'error',
  inform: 'inform',
  ok: 'ok',
  warning: 'warning'
};

export const ValidSupplyTypes = [
  'ink', // not in CDM, seen in PIE
  'toner', // not in CDM, seen in PIE
  'inkCartridge',
  'inkTank',
  'tonerCartridge'
];

export const DeliveryMapForType = {
  [SupplyDelivery.tank]: ['ink', 'inkTank'],
  [SupplyDelivery.cartridge]: ['ink', 'inkCartridge', 'toner', 'tonerCartridge']
};
