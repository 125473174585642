// import '../../../css/SmartSecurityStyles.css';
import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PrinterIcon from '../PrinterIcon'
import BadgeIcon from '../BadgeIcon'

const Web = ({ isActive, isUnknown, strings, name, isHPX }) => {
  return (
    <CardItem
      leftItem={
        !isHPX ? (
          <div style={{ position: 'relative', left: '0', top: '0' }}>
            <PrinterIcon />
            {isUnknown || !isActive ? '' : <BadgeIcon />}
          </div>
        ) : (
          <></>
        )
      }
      contentItem={
        !isHPX ? (
          <CardItemContent
            titleStyle={{ fontSize: '24px' }}
            title={sprintf(strings.title, name)}
          />
        ) : (
          <h4
            className="list-item__middle-section--primary"
            data-testid="ss-printer-title"
          >
            <p
              style={{
                fontSize: '32px',
                fontWeight: '500',
                lineHeight: '40px'
              }}
            >
              Printer Security
            </p>
          </h4>
        )
      }
    />
  )
}

export default Web

Web.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnknown: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}
