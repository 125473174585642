import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'
import { SettingsInfo } from '../../types/PaymentMethodsInfo'

export default class SettingInfoClient {
  httpClient: AxiosInstance
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider) {
    this.httpClient = axios
    this.mfeAuthProvider = authProvider
    this.baseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getSettingsData(): Promise<SettingsInfo> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/settings`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        sendOpenTelemetryEvent(
          `Error while fetching data from Settings api : ` +
            JSON.stringify(error)
        )
        throw error
      })
  }
}
