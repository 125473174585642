import {
  DisplayObserver,
  useAnalytics
} from '@monetization/hpaip-ui-shared-components'
import tokens from '@veneer/tokens'
import React from 'react'
import styled from 'styled-components'

const { hpBlue7 } = tokens.color
const { size2, lineHeight2 } = tokens.typography
const { size4 } = tokens.layout

const Link = styled.a`
  color: ${hpBlue7};
`

const Text = styled.p`
  font-size: ${size2};
  line-height: ${lineHeight2};

  & + p {
    margin-top: ${size4};
  }
`
type Props = {
  t?: any
}

export const ErrorMessage = ({ t }: Props) => {
  const { events } = useAnalytics()
  return (
    <div>
      <Text>
        {t(
          'update-plan.modals.changePlanDialog.error.description-top',
          "Something went wrong. Your update didn't go through."
        )}
      </Text>
      <Text>
        {t(
          'update-plan.modals.changePlanDialog.error.description-bottom-start',
          'Please refresh the page or'
        )}{' '}
        <DisplayObserver eventProps={events.ClickChangePlanErrorLink()}>
          <Link
            href="https://support.hp.com/contact/select-product?originid=myaccount"
            target="_blank"
            rel="noreferrer"
          >
            {t(
              'update-plan.modals.changePlanDialog.error.description-bottom-link',
              'contact Support'
            )}
          </Link>
        </DisplayObserver>
        {t(
          'update-plan.modals.changePlanDialog.error.description-bottom-end',
          'if you still need assistance.'
        )}
      </Text>
    </div>
  )
}
