import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { getEnvironmentVariables, getStack } from 'src/helpers'
import useApolloClient from '../../hooks/useApolloClient'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'
import InitialComponent from '../InitialComponent'

const ShipmentTracking = ({
  t,
  authProvider,
  stack,
  localization,
  analytics,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const graphqlClient = useApolloClient(
    authProvider,
    envVariables?.variables?.MAGENTO_URL
  )
  return (
    <ApolloProvider client={graphqlClient}>
      <InitialComponent
        commonProps={{
          t,
          authProvider,
          stack,
          analytics,
          localization,
          navigation
        }}
      />
    </ApolloProvider>
  )
}

export default ShipmentTracking
