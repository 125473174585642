import React from 'react'
import { ShippingText, AddressText, ChangeText } from './styles'
import { getDefaultText } from './defaultText'
import useGetText from '../../hooks/useGetText'

interface ShippingInfoProps {
  shippingAddress: {
    fullName: string
    address: string
    address2?: string
    city: string
    state: string
    postalCode: string
    countryCode: string
  }
  handleChange: () => void
}

const ShippingInfo: React.FC<ShippingInfoProps> = ({
  shippingAddress,
  handleChange
}) => {
  const getText = useGetText('paper-on-paas')
  return (
    <>
      <ShippingText data-testid="shipping-text">
        {getText('shipping-text', {
          default: getDefaultText('shipping-text')
        })}
      </ShippingText>
      <AddressText>{shippingAddress.fullName}</AddressText>
      <AddressText>{shippingAddress.address}</AddressText>
      {shippingAddress.address2 && (
        <AddressText>{shippingAddress.address2}</AddressText>
      )}
      <AddressText>
        {`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode} ${shippingAddress.countryCode}`}
      </AddressText>
      <ChangeText onClick={handleChange}>
        {getText('change', {
          defaultText: getDefaultText('change')
        })}
      </ChangeText>
    </>
  )
}

export default ShippingInfo
