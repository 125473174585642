import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const PrinterStatus = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0;
  color: ${tokens.colorGray7};
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 1036px) {
    font-size: 12px;
    line-height: 24px;
  }

  && > svg {
    vertical-align: bottom;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    min-width: 16px;

    @media (min-width: 1036px) {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      min-width: 24px;
    }
  }
`;

export const DetailContainer = styled.div`
  ${PrinterStatus} {
    font-size: 16px;

    @media (min-width: 1036px) {
      font-size: 20px;
    }
  }
`;

export const CoachmarkContainer = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const Explanation = styled.div`
  margin-left: 20px;
  font-size: 14px;

  @media (min-width: 1036px) {
    margin-left: 34px;
    font-size: 16px;
  }

  a {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    :hover {
      color: ${tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${tokens.colorHpBlue8};
      text-decoration: underline;
    }
  }
`;
