import { InkOffer, InkOffersRecord } from '../types/instantink'

export function getCurrentOffer(
  printerData: any,
  offers: InkOffersRecord
): InkOffer | null {
  if (!printerData?.instantInk || !offers) return null

  const currentOfferSku = printerData.instantInk.product?.value?.productSku
  return currentOfferSku ? (offers[currentOfferSku] ?? null) : null
}
