import tokens from '@veneer/tokens'
import styled from 'styled-components'
const { size3: layoutSize3, size1: layoutSize1 } = tokens.layout
const { family0, size2, size1, lineHeight2, lineHeight1 } = tokens.typography
const { gray12, gray9, hpBlue7 } = tokens.color

export const NotificationPage = styled.div<{
  $type?: string
}>`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  align-self: stretch;
  background: ${({ $type }) =>
    $type == 'planRevertError' ? 'rgba(255, 137, 0, 0.10)' : '#E6F0F5'};
  gap: ${layoutSize3};
  border-radius: 12px;
  padding: 12px 8px 12px 12px;
  white-space: break-spaces;
  align-items: flex-start;
  box-shadow: 0 1px 4px 1px rgb(33, 33, 33, 0.2);
  > svg {
    min-width: 24px;
    min-height: 24px;
  }
  > div {
    align-items: flex-start;
  }
`
export const NotificationContent = styled.div`
  font-family: ${family0};
`
export const NotificationTitle = styled.p`
  color: ${gray12};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  margin-bottom: ${layoutSize1};
`
export const NotificationDescription = styled.p`
  color: ${gray9};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  margin-bottom: ${layoutSize3};
`
export const NotificationButton = styled.a`
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${hpBlue7};
  cursor: pointer;
`
export const NotificationRevertTitle = styled.p`
  color: ${gray12};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`
export const NotificationRevertDescription = styled.p`
  color: ${gray9};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};

  > a {
    color: ${hpBlue7};
  }
`
