import React from 'react'
import HPAIPBilling from './HPAIPBilling'
import InstantInkBilling from './InstantInkBilling'
import { BillingContainer, SubTitle, Title } from './styles'
import useGetText from '@/hooks/useGetText'

interface BillingProps {
  commonProps: any
}

const Billing = (props: BillingProps) => {
  const { commonProps } = props
  const hpOneSubscriptionInfos = commonProps?.hpaip?.hpOneSubscriptionInfo
  const getText = useGetText('billing')

  const instantInkSubscriptionInfos =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions

  const iInkIsFetching = commonProps?.iInk?.iInkIsFetching
  return (
    <>
      <Title>
        {getText('yourBilling', { defaultValue: 'Your payment method' })}
      </Title>
      <SubTitle>
        {getText('paymentInfoOnFileMsg', {
          defaultValue:
            'Below is the payment information on file for your HP account. Keep this information updated to avoid service interruptions.'
        })}
      </SubTitle>
      <BillingContainer>
        {commonProps?.isHPOneSubscriptionAvailable && (
          <>
            {hpOneSubscriptionInfos?.map((_, index) => (
              <HPAIPBilling
                commonProps={props?.commonProps}
                subscriptionIndex={index}
                key={index}
              />
            ))}
          </>
        )}
        {!iInkIsFetching && instantInkSubscriptionInfos?.length > 0 && (
          <InstantInkBilling commonProps={props?.commonProps} />
        )}
      </BillingContainer>
    </>
  )
}

export default Billing
