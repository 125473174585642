import React from 'react';
import PropTypes from 'prop-types';
import { CoachmarkContainer, PrinterStatus, Explanation } from './styles';
import { IconHelpBlue } from 'src/utils/iconStyles';
import { useI18n } from '@jarvis/react-portal-addons';
import { Coachmark } from 'src/shared/Coachmark';
import Dot from 'src/components/Supplies/Dot';
import { ColorMapping } from 'src/components/Supplies/constants';

const DeviceStatusBase = ({
  className,
  coachmark,
  colorCodes,
  formatParams,
  Icon,
  linkText,
  linkUrl,
  message,
  onClick,
  title,
  withCoachmark,
  withExplanation,
  ...rest
}) => {
  const { t } = useI18n();
  const dataTestId = rest['data-testid'] || 'printer-status';

  const getDot = (item) => {
    const { code } = item;
    const supplyName = ColorMapping[code] || code;
    return (
      <Dot
        key={supplyName}
        supplyName={supplyName}
      />
    );
  };

  const getMessage = (message, formatParams) => {
    if (formatParams) {
      return t(message, {
        ...formatParams,
        interpolation: { escapeValue: false }
      });
    }
    return t(message);
  };

  return (
    <>
      <PrinterStatus data-testid={`${dataTestId}-ps`}>
        <Icon filled />
        {colorCodes?.map((item) => getDot(item))}
        <span
          className={className}
          data-testid={dataTestId}
        >
          {t(title)}
        </span>
        {withCoachmark && coachmark && (
          <CoachmarkContainer data-testid={`${dataTestId}-cm`}>
            <Coachmark
              description={t(coachmark)}
              content={<IconHelpBlue />}
            />
          </CoachmarkContainer>
        )}
      </PrinterStatus>
      {withExplanation && (
        <Explanation data-testid={`${dataTestId}-ex`}>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: getMessage(message, formatParams)
              }}
            />
          )}
          {linkText && linkUrl && (
            <a
              href={t(linkUrl)}
              target="_blank"
              rel="noreferrer"
              onClick={onClick}
            >
              {t(linkText)}
            </a>
          )}
        </Explanation>
      )}
    </>
  );
};

DeviceStatusBase.defaultProps = {
  className: 'caption',
  coachmark: null,
  colorCodes: [],
  formatParams: null,
  linkText: null,
  linkUrl: null,
  message: null,
  onClick: null,
  withCoachmark: false,
  withExplanation: false
};

DeviceStatusBase.propTypes = {
  className: PropTypes.string,
  coachmark: PropTypes.string,
  colorCodes: PropTypes.arrayOf(PropTypes.object),
  formatParams: PropTypes.object,
  Icon: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  withCoachmark: PropTypes.bool,
  withtExplanation: PropTypes.bool
};

export default DeviceStatusBase;
