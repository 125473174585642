import React, { useState, useEffect, useContext } from 'react'
import {
  DeviceConfigurationsValue,
  DeviceConfigurationResult,
  AnalyticsClickEvents
} from '../static/consts'
import AssessmentHooksContext from '../contexts/AssessmentHooksContext'
import {
  getSecurityCheckStringsForProtectionStatus,
  stringsForState
} from '../strings/DeviceConfigurations/protectionStatusStrings'
import { compliancyForResult } from '../utils/shared'
import { registerAnalyticsEvents } from '../utils/analytics'
import AuthContext from '../contexts/AuthContext'

const withConfigWifiNetwork = (Component) => {
  const NewComponent = () => {
    const { isHPX } = useContext(AuthContext)
    const { useDeviceConfigurationsAssessmentHook, useAssessmentStringsHook } =
      useContext(AssessmentHooksContext)
    const { configWifiNetwork } = useDeviceConfigurationsAssessmentHook
    const { configWifiNetworkStrings } = useAssessmentStringsHook
    const [active, setActive] = useState(false)
    const onAccordionClick = () => {
      const linkIDAnalytics = active
        ? AnalyticsClickEvents.SHRINK_PROTECTION_STATUS
        : AnalyticsClickEvents.EXPAND_PROTECTION_STATUS
      registerAnalyticsEvents(linkIDAnalytics)
      setActive(!active)
    }

    const assessmentDataResult = configWifiNetwork.assessmentData.result
    const isUnknown =
      configWifiNetwork.value === DeviceConfigurationsValue.unknownValue
    const isComplianceOff =
      assessmentDataResult === DeviceConfigurationResult.complianceOff

    const [isCompliant, setIsCompliant] = useState(
      compliancyForResult(configWifiNetwork)
    )

    const strings = stringsForState(
      configWifiNetworkStrings,
      assessmentDataResult,
      isHPX
    )

    useEffect(() => {
      const newState = compliancyForResult(configWifiNetwork)
      setIsCompliant(newState)
    }, [configWifiNetwork])

    const componentProps = {
      configWifiNetworkStrings,
      configWifiNetwork,
      strings,
      active,
      getSecurityCheckStringsForProtectionStatus,
      onAccordionClick,
      isCompliant,
      isUnknown,
      isComplianceOff,
      isHPX
    }

    return <Component componentProps={componentProps} />
  }

  return NewComponent
}

export default withConfigWifiNetwork
