import { AssessmentResult } from '../../static/consts'

export const monitorSecuritySettingsStrings = (i18n) => {
  return {
    titleProtected: i18n.t(`monitorSecuritySettings.titleProtected`),
    titleHPXProtected: i18n.t(`monitorSecuritySettings.titleHPXProtected`),
    descriptionProtected: i18n.t(
      `monitorSecuritySettings.descriptionProtected`
    ),
    descriptionHPXProtected: i18n.t(
      `monitorSecuritySettings.descriptionHPXProtected`
    ),
    titleNotProtected: i18n.t(`monitorSecuritySettings.titleNotProtected`),
    descriptionNotProtected: i18n.t(
      `monitorSecuritySettings.descriptionNotProtected`
    ),
    descriptionTenantNotProtected: i18n.t(
      `monitorSecuritySettings.descriptionTenantNotProtected`
    ),
    titleSecurityDisabled: i18n.t(
      `monitorSecuritySettings.titleSecurityDisabled`
    ),
    descriptionSecurityDisabled: i18n.t(
      `monitorSecuritySettings.descriptionSecurityDisabled`
    ),
    descriptionTenantSecurityDisabled: i18n.t(
      `monitorSecuritySettings.descriptionTenantSecurityDisabled`
    ),
    titleUnknown: i18n.t(`monitorSecuritySettings.titleUnknown`),
    descriptionUnknown: i18n.t(`monitorSecuritySettings.descriptionUnknown`),
    toast: i18n.t('monitorSecuritySettings.toast'),
    errorToastText: i18n.t('monitorSecuritySettings.errorToastText'),
    errorToastSub: i18n.t('monitorSecuritySettings.errorToastSub'),
    modalTitleToggleOn: i18n.t('monitorSecuritySettings.modalTitleToggleOn'),
    modalDescriptionToggleOn: i18n.t(
      'monitorSecuritySettings.modalDescriptionToggleOn'
    ),
    modalTitleToggleOff: i18n.t('monitorSecuritySettings.modalTitleToggleOff'),
    modalDescriptionToggleOff: i18n.t(
      'monitorSecuritySettings.modalDescriptionToggleOff'
    ),
    stayProtected: i18n.t('monitorSecuritySettings.stayProtected'),
    removeSecurity: i18n.t('monitorSecuritySettings.removeSecurity'),
    getProtected: i18n.t('monitorSecuritySettings.getProtected'),
    close: i18n.t('monitorSecuritySettings.close')
  }
}

export const tenantStringsForState = (assessmentResult, isEnabled, strings) => {
  let formattedStrings = { ...strings }

  const assessmentResultValue = assessmentResult.toLowerCase()

  switch (assessmentResultValue) {
    case AssessmentResult.securityMonitored:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleProtected,
        description: strings.descriptionProtected
      }
      break
    case AssessmentResult.needsAttention:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleNotProtected,
        description: strings.descriptionTenantNotProtected
      }
      break
    case AssessmentResult.notMonitored:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleSecurityDisabled,
        description: strings.descriptionTenantSecurityDisabled
      }
      break
    case AssessmentResult.unknown:
    default:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleUnknown,
        description: strings.descriptionUnknown
      }
      break
  }

  if (isEnabled) {
    formattedStrings = {
      ...formattedStrings,
      modalTitle: strings.modalTitleToggleOn,
      modalDescription: strings.modalDescriptionToggleOn,
      buttonPrimaryTitle: strings.stayProtected,
      buttonSecondaryTitle: strings.removeSecurity
    }
  } else {
    formattedStrings = {
      ...formattedStrings,
      modalTitle: strings.modalTitleToggleOff,
      modalDescription: strings.modalDescriptionToggleOff,
      buttonPrimaryTitle: strings.getProtected,
      buttonSecondaryTitle: strings.close
    }
  }

  return formattedStrings
}

export const stringsForState = (
  assessmentResult,
  isEnabled,
  strings,
  isHPX
) => {
  let formattedStrings = { ...strings }

  const assessmentResultValue = assessmentResult.toLowerCase()

  switch (assessmentResultValue) {
    case AssessmentResult.securityMonitored:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX ? strings.titleProtected : strings.titleHPXProtected,
        description: !isHPX
          ? strings.descriptionProtected
          : strings.descriptionHPXProtected
      }
      break
    case AssessmentResult.needsAttention:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX ? strings.titleNotProtected : strings.titleHPXProtected,
        description: !isHPX
          ? strings.descriptionNotProtected
          : strings.descriptionHPXProtected
      }
      break
    case AssessmentResult.notMonitored:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX
          ? strings.titleSecurityDisabled
          : strings.titleHPXProtected,
        description: !isHPX
          ? strings.descriptionSecurityDisabled
          : strings.descriptionHPXProtected
      }
      break
    case AssessmentResult.unknown:
    default:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX ? strings.titleUnknown : strings.titleHPXProtected,
        description: !isHPX
          ? strings.descriptionUnknown
          : strings.descriptionHPXProtected
      }
      break
  }

  if (isEnabled) {
    formattedStrings = {
      ...formattedStrings,
      modalTitle: strings.modalTitleToggleOn,
      modalDescription: strings.modalDescriptionToggleOn,
      buttonPrimaryTitle: strings.stayProtected,
      buttonSecondaryTitle: strings.removeSecurity
    }
  } else {
    formattedStrings = {
      ...formattedStrings,
      modalTitle: strings.modalTitleToggleOff,
      modalDescription: strings.modalDescriptionToggleOff,
      buttonPrimaryTitle: strings.getProtected,
      buttonSecondaryTitle: strings.close
    }
  }

  return formattedStrings
}
