import React, { useMemo } from 'react';
import { getEnvironmentConfig } from '../src/utils/jweb';
import { mergeThemeWithEnvConfigInfo } from 'src/utils/theme-provider';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { DirectionProvider, ToastProvider } from '@veneer/core';
import { ThemeProvider } from '@veneer/theme/';
import { RootProvider } from '@jarvis/react-portal-addons';
import App from '../src';
import resources from '../src/assets/locale';
import projectNames from './configs/projectNames';

export default function Root({ ...props }) {
  const { stack } = props;
  const { v1 } = window.Shell;

  const environmentConfig = getEnvironmentConfig(stack);
  const theme = mergeThemeWithEnvConfigInfo(environmentConfig);

  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    };
  }, [v1?.theme]);

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <StyledComponentThemeProvider theme={theme}>
            <RootProvider
              localization={v1.localization}
              resources={resources}
              shell={v1}
              authProvider={v1.authProvider}
              stack={stack}
              orgSelector={v1.orgSelector}
            >
              <ToastProvider>
                <App />
              </ToastProvider>
            </RootProvider>
          </StyledComponentThemeProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
