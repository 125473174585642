import {
  AnalyticsProvider,
  parseAuxParams,
  ShellAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React, { useMemo } from 'react'
import { analyticsEvents } from '../analyticsEvents'
import moment from 'moment'

interface Props {
  analytics: ShellAnalytics
  subscriptionId: string
  entityStartDate: any
}

export const UpdatePlanAnalyticsProvider: React.FC<Props> = ({
  children,
  analytics,
  subscriptionId,
  entityStartDate
}) => {
  const subscriptionDate = moment(entityStartDate).format('DD MMMM YYYY')
  const auxParams = useMemo(() => {
    const params = parseAuxParams({
      subscriptionId: subscriptionId,
      subscriptionStartDate: subscriptionDate
    })

    return params
  }, [subscriptionId, subscriptionDate])

  return useMemo(
    () => (
      <AnalyticsProvider
        analytics={analytics}
        defaults={{
          screenPath: 'ReactPaasDashboardUpdatePlan',
          activity: 'HpOnePrintMgmt-v01',
          version: '1.3.0',
          ...auxParams
        }}
        events={analyticsEvents}
      >
        {children}
      </AnalyticsProvider>
    ),
    [analytics, auxParams, children]
  )
}
