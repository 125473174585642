import React, { useState } from 'react'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
import { useGetStatements } from 'src/hooks/useGetStatements/useGetStatements'
import { getFormatDate } from 'src/helpers/date-helper'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import StatementDownloadLink from '../StatementDownloadLink'
import { toCurrency } from 'src/utils/currency'
import {
  DateRangeText,
  ErrorText,
  FlexSpaceBetween,
  Header,
  LabelText,
  PreviousBillingCardWrapper,
  PreviousInvoiceBlock
} from './styles'

const PreviousBillingCard = ({ customerId }) => {
  const { t } = useHistoryContext()
  const { data, error, isLoading } = useGetStatements(customerId)
  const [showInvoiceDownloadNotification, setShowInvoiceDownloadNotification] =
    useState(false)

  const firstStatement =
    data?.find((item) => item?.resourceType === 'Order') ?? null

  console.log(showInvoiceDownloadNotification)

  const renderDateRange = () => {
    const startDate = getFormatDate(
      new Date(firstStatement?.invoiceLineItems?.[0]?.startDate)
    )
    const endDate = getFormatDate(
      new Date(firstStatement?.invoiceLineItems?.[0]?.endDate)
    )

    return `${startDate} - ${endDate}`
  }

  if (isLoading) {
    return (
      <PreviousBillingCardWrapper>
        <LoadingHandler loading={isLoading} data-testid="loading" />
      </PreviousBillingCardWrapper>
    )
  }

  if (error || !firstStatement) {
    return (
      <PreviousBillingCardWrapper>
        <Header>
          {t(
            'history.cards.PreviousBilling.title',
            'Previous billing cycle charges'
          )}
        </Header>
        <ErrorText>
          {t('error-section.title', 'Something went wrong')}
        </ErrorText>
      </PreviousBillingCardWrapper>
    )
  }

  return (
    <PreviousBillingCardWrapper>
      <Header>
        {t(
          'history.cards.PreviousBilling.title',
          'Previous billing cycle charges'
        )}
      </Header>
      <FlexSpaceBetween>
        <LabelText>
          {t('history.cards.PreviousBilling.invoice', 'Invoice')}
        </LabelText>
        <LabelText>{toCurrency(firstStatement?.totalAmount || 0)}</LabelText>
      </FlexSpaceBetween>
      <DateRangeText>{renderDateRange()}</DateRangeText>
      <PreviousInvoiceBlock>
        <StatementDownloadLink
          statement={firstStatement}
          showDownloadErrorNotification={() =>
            setShowInvoiceDownloadNotification(true)
          }
        />
      </PreviousInvoiceBlock>
    </PreviousBillingCardWrapper>
  )
}

export default PreviousBillingCard
