import React from 'react'
import useInstantInkShippingAddress from '@/hooks/useInstantInkShippingAddress'
import ShippingAddress from './ShippingAddress'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { StyledAddressItemContainer } from '../InstantInkShipping/styles'
import ErrorCard from '../../ErrorCard'
import useGetText from '@/hooks/useGetText'
interface InstantInkShippingingProps {
  commonProps: any
}
const InstantInkShipping = (props: InstantInkShippingingProps) => {
  const { commonProps } = props
  const getText = useGetText()
  const hasInstantInkSubscription =
    commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0
  const {
    data: instantInkAddress,
    isfetching: instantInkLoading,
    error: instantInkError,
    forceRefresh: fetchIntantInkAddress
  } = useInstantInkShippingAddress(hasInstantInkSubscription)

  if (instantInkLoading) {
    return (
      <StyledAddressItemContainer>
        <LoadingHandler loading />
      </StyledAddressItemContainer>
    )
  }

  if (instantInkError) {
    return (
      <StyledAddressItemContainer error>
        <ErrorCard
          hpOneSubtitle={getText('errorScreen.instantInkSubTitle', {
            defaultValue: 'Your Instant Ink Plan information did not load.'
          })}
          refreshcard={getText('errorScreen.refreshcard', {
            defaultValue: 'Refresh the card'
          })}
          refetchData={fetchIntantInkAddress}
        />
      </StyledAddressItemContainer>
    )
  }

  return (
    <ShippingAddress
      commonProps={props?.commonProps}
      instantInkAddress={instantInkAddress}
      fetchIntantInkAddress={fetchIntantInkAddress}
    />
  )
}

export default InstantInkShipping
