import React from 'react';
import {
  Card,
  CardOneContainer,
  Content,
  ContentTitle,
  HrLine,
  Label,
  RecyclingText,
  StepLine,
  StepOne,
  StepOneContent,
  StepperBlock,
  StepThreeContent,
  StepThreeContentText
} from '../Styles';
import { IconCheckmarkCircle, IconCircle } from '@veneer/core';
import RecyclingModal from '../RecyclingModal/RecyclingModal';
import { DeviceComponent } from '../DeviceComponent';
import CancelationModal from '../../CommonComponent/CancelModal/CancelModal';
import moment from 'moment';

const DeviceNotShipped = ({
  t,
  canceledDate,
  props,
  isLoading,
  isInRemorse,
  costValue,
  after10days,
  lastDayToReturnPrinter
}) => {
  const statusReturn = moment().isAfter(after10days);
  const statusComplete = moment().isAfter(lastDayToReturnPrinter);
  return (
    <StepperBlock>
      <StepOne>
        <StepLine>
          <IconCheckmarkCircle
            size={36}
            filled
          />
          <HrLine />
        </StepLine>
        <Card>
          <Content>
            <ContentTitle>
              {canceledDate}
              {': '}
              {t('SecondComponent.ContentTitle.1', 'Cancellation submitted')}
            </ContentTitle>
            <CardOneContainer>
              <StepOneContent>
                {t(
                  'SecondComponent.ContentTitle.7',
                  'The cancellation process has started, and you can no longer access HP All-In Plan services.'
                )}
              </StepOneContent>
            </CardOneContainer>
          </Content>
        </Card>
      </StepOne>
      <StepOne>
        <StepLine>
          {statusReturn ? (
            <IconCheckmarkCircle
              size={36}
              filled
            />
          ) : (
            !isLoading && (
              <>
                <IconCircle
                  size={36}
                  style={{ color: '#666' }}
                />
                <Label>{t('SecondComponent.label.2', '2')}</Label>
              </>
            )
          )}
          <HrLine />
        </StepLine>
        <Card>
          <Content>
            <ContentTitle>
              {canceledDate}
              {': '}
              {t(
                'SecondComponent.ContentTitle.8',
                'Cancellation process in progress'
              )}
            </ContentTitle>
            <CardOneContainer>
              <StepOneContent>
                {t(
                  'SecondComponent.ContentTitle.9',
                  'Since you cancelled your order after the printer had already been dispatched, we were unable to halt its delivery. Once the printer arrives at your address, please follow the return instructions that will be sent to your email in the coming days. This will help you avoid any non-return fees.'
                )}
              </StepOneContent>
            </CardOneContainer>
          </Content>
        </Card>
      </StepOne>
      <StepOne>
        <StepLine>
          {statusComplete ? (
            <>
              <IconCheckmarkCircle
                size={36}
                filled
              />
            </>
          ) : (
            !isLoading && (
              <>
                <IconCircle
                  size={36}
                  style={{ color: '#666' }}
                />
                <Label>{t('SecondComponent.label.3', '3')}</Label>
              </>
            )
          )}
        </StepLine>
        <Card>
          <StepThreeContent>
            <ContentTitle>
              {after10days}
              {': '}
              {t(
                'SecondComponent.ContentTitle.10',
                'Printer must be submitted to FedEx by this date'
              )}
            </ContentTitle>
            <StepThreeContentText>
              {t('SecondComponent.ContentTitle.11', {
                costValue,
                defaultValue: `You will be charged a ${costValue}* cancellation fee if you don’t submit the printer by this date. See more info about`
              })}
              <CancelationModal
                t={t}
                props={props}
              />
            </StepThreeContentText>
            <RecyclingText>
              {t(
                'SecondComponent.ContentTitle.12',
                'You are required to return the printer and ink cartridges via FedEx for'
              )}
              <RecyclingModal
                t={t}
                startDate={props?.printerSubscription?.entityStartDate}
                subscriptionId={props?.printerSubscription?.subscriptionId}
                trialPeriod={props?.cancellationPeriod}
              />
            </RecyclingText>
            <DeviceComponent
              t={t}
              props={props}
              isInRemorse={isInRemorse}
            />
          </StepThreeContent>
        </Card>
      </StepOne>
    </StepperBlock>
  );
};

export default DeviceNotShipped;
