import {
  SubscriptionEntity,
  SubscriptionStateEnum,
  SubscriptionEntityTypeEnum,
  PRODUCT_TYPES
} from '@/types'

export const getPaperEligibilityInfo = async (
  optionsData: any,
  enableAddPaperPostEnrollment,
  printer: SubscriptionEntity
) => {
  const editData = optionsData?.data?.subscription?.edit
  const instantPaper = Array.isArray(editData)
    ? editData.filter(
        (item) =>
          item?.details?.productType === PRODUCT_TYPES.INSTANT_PAPER &&
          item?.enabled === true
      )
    : []
  let isPaperEnabled = false
  let price = 0
  let optionId = null
  if (instantPaper?.length > 0) {
    isPaperEnabled =
      enableAddPaperPostEnrollment &&
      (printer?.state === SubscriptionStateEnum.SUBSCRIBED ||
        printer?.state === SubscriptionStateEnum.PENDING)

    price = parseFloat(instantPaper[0]?.outcomes[0]?.value?.cost) || 0

    optionId = instantPaper[0]?.optionId ?? null
  }

  return {
    enabled: isPaperEnabled,
    optionId: optionId,
    priceInfo: isNaN(price) ? 0 : price
  }
}

export const getRemovePaperEnabled = async (entities: any[]) => {
  const instantPaper = entities.filter(
    (item) =>
      item.entityType === SubscriptionEntityTypeEnum.PAPER &&
      (item.state === SubscriptionStateEnum.SUBSCRIBED ||
        item.state === SubscriptionStateEnum.PENDING)
  )

  return instantPaper.length > 0
}

export const getActivePaperSubscription = async (entities: any[]) => {
  const instantPaper = entities.filter(
    (item) =>
      item.entityType === SubscriptionEntityTypeEnum.PAPER &&
      item.state !== SubscriptionStateEnum.CANCELED
  )

  return instantPaper.length > 0
}
