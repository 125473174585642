import React from 'react'
import Images from '@/assets/images'
import { ImageContainer, Subtitle, Title } from './styles'
import useGetText from '@/hooks/useGetText'

interface HeaderProps {
  subscriptionFriendlyIds: string[]
  isDisabled: boolean
}

const Header = (props: HeaderProps) => {
  const { subscriptionFriendlyIds, isDisabled } = props
  const joinedSubscriptionIds = subscriptionFriendlyIds?.join(', ')

  const getText = useGetText('subscriptions')

  return (
    <div>
      <ImageContainer>
        <img src={Images.hp_all_in_plan} alt="HP All In Plan" />
      </ImageContainer>
      <Title isDisabled={isDisabled}>
        {getText('planId', { defaultValue: 'Plan ID (Print)' })}
      </Title>
      <Subtitle isDisabled={isDisabled}>{joinedSubscriptionIds}</Subtitle>
    </div>
  )
}

export default Header
