import React, { useState } from 'react'
import Markdown from 'markdown-to-jsx'
import { MultipleStyledNotification, Title, Description } from './styles'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification/inline_notification'
import { NotificationType } from 'src/types/delinquencyNotification'
import { ENotification } from './helper'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import { useAnalytics } from '../../contexts'
import {
  captureAnalyticsClickEvent,
  captureAnalyticsModuleEvent
} from '../../../utils/Analytics/analyticsUtility'

export interface NotificationData {
  title?: string
  description?: string
  notificationID?: string
  showCloseButton?: boolean
  notificationType?: NotificationType
  callbackFunction?: () => void | (Window & typeof globalThis)
  SecondCallbackFunction?: () => void | (Window & typeof globalThis)
}

export interface Props {
  multiNotificationArray: NotificationData[]
  screenPath: string
}

export const getNotificationIndex = (
  type: NotificationType | undefined
): number => {
  switch (type) {
    case ENotification.NEGATIVE:
      return 0
    case ENotification.WARNING:
      return 1
    case ENotification.CUSTOM:
      return 2
    case ENotification.HELP:
      return 3
    case ENotification.INFORMATIVE:
      return 4
    case ENotification.POSITIVE:
      return 5
    default:
      return -1
  }
}

const CustomLink1 = ({ children, callbackFunction }: any) => (
  <span className="custom-link1 link" onClick={callbackFunction}>
    {children}
  </span>
)

const CustomLink2 = ({ children, SecondCallbackFunction }: any) => (
  <a className="custom-link2 link" onClick={SecondCallbackFunction}>
    {children}
  </a>
)

export function MultipleNotification({
  multiNotificationArray,
  screenPath
}: Readonly<Props>) {
  const [closedNotifications, setClosedNotifications] = useState<string[]>([])

  const { publishEvent } = useAnalytics()

  const handleCloseNotification = (
    notificationID: string,
    screenPath: string
  ) => {
    const analyticsClickEvent = captureAnalyticsClickEvent(
      notificationID,
      screenPath
    )
    publishEvent(analyticsClickEvent)
    setClosedNotifications([...closedNotifications, notificationID])
  }

  const isNotificationClosed = (notificationID: string) => {
    return closedNotifications.includes(notificationID)
  }

  const getFormattedDescription = (
    description: string,
    callbackFunction?: () => void | (Window & typeof globalThis),
    SecondCallbackFunction?: () => void | (Window & typeof globalThis)
  ) => {
    return (
      description && (
        <Markdown
          options={{
            overrides: {
              span: {
                component: CustomLink1,
                props: { callbackFunction }
              },
              nav: {
                component: CustomLink2,
                props: { SecondCallbackFunction }
              }
            }
          }}
        >
          {description}
        </Markdown>
      )
    )
  }

  const sortedNotifications = multiNotificationArray.sort((a, b) => {
    return (
      getNotificationIndex(a.notificationType) -
      getNotificationIndex(b.notificationType)
    )
  })

  // Track published events for each notification
  const publishedNotifications = React.useRef<Set<string>>(new Set())

  const renderNotification = (data: NotificationData | undefined) => {
    if (!data) return null

    const {
      title,
      description,
      notificationID,
      notificationType,
      showCloseButton,
      callbackFunction,
      SecondCallbackFunction
    } = data
    // Only publish events once per notification
    if (
      notificationID && // Make sure notificationID exists
      !publishedNotifications.current.has(notificationID)
    ) {
      const analyticsModuleEvent = captureAnalyticsModuleEvent(
        notificationID,
        screenPath
      )
      publishEvent(analyticsModuleEvent)
      publishedNotifications.current.add(notificationID)
    }
    const marginInfo = description ? true : false
    return (
      !isNotificationClosed(notificationID) && (
        <InlineNotification
          key={notificationID}
          id={notificationID}
          closeButton={showCloseButton}
          type={notificationType}
          title={<Title marginInfo={marginInfo}>{title}</Title>}
          description={
            <Description>
              {getFormattedDescription(
                description,
                callbackFunction,
                SecondCallbackFunction
              )}
            </Description>
          }
          onClose={() => handleCloseNotification(notificationID, screenPath)}
          customStyle={
            notificationType === ENotification.CUSTOM
              ? { backgroundColor: '#E6F0F5' }
              : {}
          }
          icon={
            notificationType === ENotification.CUSTOM ? (
              <IconInfo filled size={24} color="hpBlue7" />
            ) : null
          }
        />
      )
    )
  }

  return (
    <MultipleStyledNotification>
      {sortedNotifications.map((notificationData) =>
        renderNotification(notificationData)
      )}
    </MultipleStyledNotification>
  )
}
