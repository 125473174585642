import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu'
import { TableColumns } from '@veneer/core/dist/scripts/table'
import StatementDownloadLink from '../StatementDownloadLink'
import useStatements from '@/hooks/useStatements'
import useAppActions from '@/hooks/useAppActions'
import useGetText from '@/hooks/useGetText'
import ErrorInformationCard from '../ErrorInformationCard'
import NoStatements from '../NoStatements'
import DOMPurify from 'dompurify'
import {
  StyledActionItemsContainer,
  StyledBaseContainer,
  StyledParagraph,
  StyledSelect,
  StyledTable,
  StyledTitle
} from './styles'

import {
  publishEvent,
  StatementsHistoryDropdownCollapsed,
  StatementsHistoryDropdownExpanded,
  StatementsScreenDisplayed
} from '@/utils/analytics'
import { getFormatDate, getPastMonthsDate } from '@/helpers/date-helper'
import { Statement } from '@/types/Statements'
import StatementOneTimeTokenDownload from '../StatementOneTimeTokenDownload'
import InvoiceDownloadErrorNotification from './InvoiceDownloadErrorNotification'
const fetchKey = 'statements'

const DEFAULT_TOTAL_ITEMS = 0
const DEFAULT_STARTING_PAGE = 1
const DEFAULT_PAGE_SIZE = 5
const PAGE_SIZE_OPTIONS: OptionInterface<number>[] = [
  { value: 5 },
  { value: 25 },
  { value: 50 }
]

const STATEMENT_PERIOD_INDEX = {
  last3Months: 0,
  last6Months: 1,
  last12Months: 2,
  allHistory: 3
}

const filterDate = (date: string) => getFormatDate(new Date(date))
const getDatePastByMonths = (month: number) => getPastMonthsDate(month)

const formatDescription = (statement) => {
  const strDesc =
    statement?.invoiceDescription ||
    DOMPurify.sanitize(
      statement?.invoiceLineItems
        ?.map((invoiceLineItem) =>
          invoiceLineItem.description
            .replace(/<br\s*\/?>/gi, ' ')
            .replace(/<[^>]{1,7}>/g, '')
        )
        .join(', ') || statement.description.replace(/<br\s*\/?>/gi, ' ')
    )

  return <div dangerouslySetInnerHTML={{ __html: strDesc }} />
}

const sortTableData = (tableRows: any, type: 'ascending' | 'descending') => {
  if (!tableRows) return []
  return [...tableRows].sort(
    (a, b) =>
      (new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime()) *
      (type === 'ascending' ? 1 : -1)
  )
}

function Statements() {
  const getText = useGetText(fetchKey)
  const [historyValue, setHistoryValue] = useState(
    STATEMENT_PERIOD_INDEX.last3Months
  )
  const [showInvoiceDownloadNotification, setShowInvoiceDownloadNotification] =
    useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_STARTING_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const { setIsFetching, setErrorFetch, setSucessFetch } = useAppActions()
  const [totalItems, setTotalItems] = useState(DEFAULT_TOTAL_ITEMS)
  const [orderBy, setOrderBy] = React.useState('date')
  const [orderType, setOrderType] = React.useState('descending')
  const withWidthPreferences = {
    width: [
      { columnId: 'date', width: 130 },
      { columnId: 'description', width: 500 },
      { columnId: 'downloadLink', width: 40 }
    ],
    defaultOrder: ['date', 'description', 'downloadLink'],
    sortBy: { id: orderBy, type: orderType as 'ascending' | 'descending' }
  }
  const STATEMENT_PERIODS = [
    {
      value: STATEMENT_PERIOD_INDEX.last3Months,
      label:
        getText('options.last3Months', { defaultValue: 'Last 3 months' }) ||
        'Last 3 months',
      date: getDatePastByMonths(3)
    },
    {
      value: STATEMENT_PERIOD_INDEX.last6Months,
      label: getText('options.last6Months', { defaultValue: 'Last 6 months' }),
      date: getDatePastByMonths(6)
    },
    {
      value: STATEMENT_PERIOD_INDEX.last12Months,
      label: getText('options.last12Months', {
        defaultValue: 'Last 12 months'
      }),
      date: getDatePastByMonths(12)
    },
    {
      value: STATEMENT_PERIOD_INDEX.allHistory,
      label: getText('options.allHistory', { defaultValue: 'All history' }),
      date: '1970-01-01'
    }
  ]
  const TABLE_COLUMNS: TableColumns[] = [
    {
      id: 'date',
      label: getText('table.columns.date'),
      width: 120
    },
    { id: 'description', label: getText('table.columns.description') },
    { id: 'downloadLink', label: '' }
  ].map((column) => ({
    ...column,
    label: column.label?.toUpperCase() || ''
  }))

  const { info } = useStatements({
    fromDate: STATEMENT_PERIODS[historyValue].date,
    toDate: getPastMonthsDate(0)
  })

  const handleSort = (_, { id, type }) => {
    setOrderBy(id)
    setOrderType(type)
  }

  const onHistorySelectChange = (selectedOption) => {
    setHistoryValue(selectedOption.value)
    if (selectedOption.value === STATEMENT_PERIOD_INDEX.allHistory) {
      publishEvent(StatementsHistoryDropdownExpanded)
      publishEvent(StatementsHistoryDropdownCollapsed)
    }
    setIsFetching(fetchKey, false)
    setErrorFetch(fetchKey, null)
    setSucessFetch(fetchKey, null)
    setCurrentPage(DEFAULT_STARTING_PAGE)
  }
  /* istanbul ignore next */
  const onPageChange = useCallback((page) => setCurrentPage(page), [])
  /* istanbul ignore next */
  const onPageSizeChange = useCallback((_event, { value }) => {
    setCurrentPage(1)
    setPageSize(value)
  }, [])

  const dataTable = useMemo(() => {
    if (!info.data) return []
    return sortTableData(info.data, orderType as 'ascending' | 'descending')
  }, [info.data, orderType])

  const filteredTable = useMemo(() => {
    const table = dataTable
      ?.map((statement: Statement) => {
        if (
          statement.resourceType !== 'Purchase' &&
          statement.resourceType !== 'purchase_refund'
        ) {
          return {
            date: filterDate(statement.invoiceDate),
            description: formatDescription(statement),
            downloadLink:
              statement.accessType === 'ONE_TIME_TOKEN' ? (
                <StatementOneTimeTokenDownload statement={statement} />
              ) : (
                <StatementDownloadLink
                  statement={statement}
                  showDownloadErrorNotification={() =>
                    setShowInvoiceDownloadNotification(true)
                  }
                />
              ),
            rowConfig: {
              noWrap: true
            },
            key: statement.customerId
          }
        }
      })
      .filter(Boolean)

    table && setTotalItems(table.length)
    return (
      table?.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      ) || []
    )
  }, [dataTable, orderType, currentPage, pageSize])

  useEffect(() => {
    publishEvent(StatementsScreenDisplayed)
  }, [])

  if (info.error && info.error !== 'noStatements') {
    return (
      <StyledBaseContainer>
        <StyledTitle>{getText('body.title')}</StyledTitle>
        <ErrorInformationCard getText={getText} />
      </StyledBaseContainer>
    )
  }

  return (
    <>
      <StyledBaseContainer>
        <StyledTitle>{getText('body.title')}</StyledTitle>
        <StyledParagraph>{getText('body.description')}</StyledParagraph>
        {showInvoiceDownloadNotification && (
          <InvoiceDownloadErrorNotification />
        )}
        <StyledActionItemsContainer>
          <StyledSelect
            data-testid="history-select"
            options={STATEMENT_PERIODS}
            onChange={onHistorySelectChange}
            clearIcon={false}
            defaultValue={[historyValue]}
            value={[historyValue]}
            disabled={info.isFetching}
          />
        </StyledActionItemsContainer>
        {!info.isFetching && filteredTable.length === 0 ? (
          <NoStatements
            message={getText('error.noStatements.description', {
              defaultValue:
                'There are no invoices associated with the selected time period. Please choose a different time frame.'
            })}
          />
        ) : (
          <>
            <StyledTable
              columns={TABLE_COLUMNS}
              data={filteredTable}
              loading={info?.isFetching}
              loadingDataLength={pageSize}
              onSort={handleSort}
              preferences={withWidthPreferences}
              pagination={{
                currentPage,
                onPageChange,
                pageSize,
                onPageSizeChange,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                totalItems: totalItems
              }}
            />
          </>
        )}
      </StyledBaseContainer>
    </>
  )
}

export default memo(Statements)
