import { useState, useEffect } from 'react'
import useShellContext from '../useShellContext'
import getAuthProvider from '../../helpers/getAuthProvider'
import SubscriptionStateClient from '../../services/SubscriptionStateClient/SubscriptionStateClient'

export default function useSubscriptionClient() {
  const [subscriptionClient, setSubscriptionClient] = useState(null)
  const shellContext = useShellContext()
  const authProvider = getAuthProvider()

  useEffect(() => {
    setSubscriptionClient(
      new SubscriptionStateClient(
        authProvider,
        shellContext?.xCorrelationId?.toString(),
        shellContext?.stack
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return subscriptionClient
}
