import Modal from '@veneer/core/dist/scripts/modal'
import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    border: 1px solid ${tokens.color.black};
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 900px;
    }
  }
`
