import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PrinterAccordion from 'src/shared/PrinterAccordion';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import {
  getConsumablesStateReasons,
  getStateReasons
} from '../../utils/stateReasons';
import { isPrinterDisconnected, hasNonOriginalSupply } from 'src/utils/utils';
import DeviceReasonDetail from '../DeviceStatus/DeviceReasonDetail';
import DeviceStatusDetail from '../DeviceStatus/DeviceStatusDetail';
import { STATUS } from '../../utils/statusDictionary';
import { publishHyperLinkClickedEvent } from 'src/utils/analytics';
import { PrinterDetailContainer } from './styles';

const PrinterDetails = ({ device, subscription }) => {
  const { t } = useI18n();
  const { analytics } = useRootContext();
  const reasons = useMemo(() => {
    return [
      ...getStateReasons(device),
      ...getConsumablesStateReasons(device, subscription)
    ]?.sort((a, b) => a.order - b.order);
  }, [device, subscription]);

  const isDisconnected = useMemo(() => isPrinterDisconnected(device), [device]);
  const isNonOriginal = useMemo(() => hasNonOriginalSupply(device), [device]);

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  const printerProperties =
    `connection=${device.status?.connectionState}` +
    `&security=${device.securityAssessmentResult}` +
    `&status=${device.printerHealth}`;

  const content = useMemo(
    () => (
      <>
        {isDisconnected && (
          <PrinterDetailContainer key="state-disconnected">
            <DeviceStatusDetail
              status={STATUS.GENERAL.disconnected_from_internet}
              onClick={() => {
                publishHyperLinkClickedEvent(
                  analytics,
                  'GetMoreHelp',
                  device.identity?.makeAndModel?.name,
                  'PrinterDetails',
                  analyticsMetadata,
                  printerProperties
                );
              }}
            />
          </PrinterDetailContainer>
        )}
        {isNonOriginal && (
          <PrinterDetailContainer key="state-non-original">
            <DeviceStatusDetail status={STATUS.GENERAL.not_original_inks} />
          </PrinterDetailContainer>
        )}
        {reasons?.map(
          (reason) =>
            reason && (
              <PrinterDetailContainer key={`reason-${reason.status}`}>
                <DeviceReasonDetail reason={reason} />
              </PrinterDetailContainer>
            )
        )}
      </>
    ),
    [
      isDisconnected,
      isNonOriginal,
      reasons,
      analytics,
      analyticsMetadata,
      printerProperties,
      device.identity?.makeAndModel?.name
    ]
  );

  if (!isDisconnected && !isNonOriginal && !reasons?.length) {
    return null;
  }

  return (
    <PrinterAccordion
      id="printer-details-data"
      headerText={t('myPrinters.printerDetails.title')}
      content={content}
      dataTestId="printer-details-pp"
    />
  );
};

PrinterDetails.propTypes = {
  device: PropTypes.shape({
    identity: PropTypes.shape({
      supplyDelivery: PropTypes.string,
      bizModel: PropTypes.string
    }),
    status: PropTypes.shape({
      printerStateReasons: PropTypes.arrayOf(PropTypes.string),
      connectionState: PropTypes.string
    }),
    supplies: PropTypes.shape({
      consumables: PropTypes.arrayOf(
        PropTypes.shape({
          colorCode: PropTypes.string,
          slotOrder: PropTypes.number,
          stateReasons: PropTypes.arrayOf(PropTypes.string),
          consumablePlatform: PropTypes.string,
          supplyType: PropTypes.string,
          isGenuineHP: PropTypes.bool
        })
      )
    })
  }),
  subscription: PropTypes.string
};

export default PrinterDetails;
