import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const HeaderChevronSection = styled.span`
  font-family: ${tokens.typography.family0};
  letter-spacing: 0.04em;
  display: flex;
  color:#0278ab};
  font-size: ${tokens.typography.size0};
  line-height: ${tokens.typography.lineHeight1};
  > svg {
    color: #0278ab;
  }
`

export const AccordionWrapper = styled.div`
  > #orderAccordion {
    > div {
      padding: ${tokens.layout.size2} 0 0 0;
      &:has(div[aria-expanded='false']) {
        border-radius: ${tokens.layout.cornerRadius3}
          ${tokens.layout.cornerRadius3} 0 0;
        box-shadow: 0 1px 4px 1px #21212133;
      }
      > div {
        margin: 0;
        padding: 0;
      }
    }
  }
`
