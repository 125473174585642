import { useEffect, useState } from 'react'
import { usePaperCancelledData } from '@/hooks/usePaperCancelledData'
import useGetText from '@/hooks/useGetText'
import { NotificationType } from '@/utils/common-utils'
import { NavigationType } from '@/types/shell'
import { handleNavigationPath } from '@/utils/handleNavigationPath'
import moment from 'moment'
import { returnDate } from '@/utils/formatDaysToReturn'

const GetAllPaperNotification = (
  subscriptionId: string,
  flags?: any,
  navigation?: NavigationType,
  allNotificationsObject?: any,
  pendingData?: any
) => {
  const [billingDate, setBillingDate] = useState<string>('')
  const getText = useGetText('paper-notification')
  const paperCancelledData = usePaperCancelledData(subscriptionId, pendingData)
  const isPaperCancelled = paperCancelledData && flags
  // if (!isPaperCancelled) return null
  const commerceData = allNotificationsObject?.notificationApiInfo?.commerceData

  useEffect(() => {
    const fetchBillingDate = async () => {
      if (commerceData) {
        const { billingDate, cancelledOn, customerEndDate } = commerceData
        const newBillingDate = await returnDate(
          billingDate,
          cancelledOn,
          customerEndDate
        )
        setBillingDate(newBillingDate)
      }
    }

    fetchBillingDate()
  }, [commerceData])
  if (!isPaperCancelled) return null
  const formattedBillingDate = moment(billingDate?.split('T')[0]).format(
    'MMM DD YYYY'
  )
  let resumePaperServiceLink = `/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper&rescind=true&redirectTo=`

  const handleRedirect = () => {
    if (window.location.pathname.includes('update-plan')) {
      resumePaperServiceLink = resumePaperServiceLink + 'updatePlan'
    } else if (window.location.pathname.includes('subscriptions')) {
      resumePaperServiceLink = resumePaperServiceLink + 'subscription'
    } else {
      resumePaperServiceLink = resumePaperServiceLink + 'Overview'
    }

    const path = handleNavigationPath(resumePaperServiceLink)
    navigation ? navigation.push(path) : window.location.assign(path)
  }

  const secondHandleRedirect = () => {
    const resumePaperServiceLink = `/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`
    const path = handleNavigationPath(resumePaperServiceLink)
    navigation ? navigation.push(path) : window.location.assign(path)
  }

  return {
    title: getText('paper-cancel.title', {
      defaultValue:
        'Your Paper Add-on Service has been removed from your HP All-In Plan'
    }),
    description:
      getText('paper-cancel.description', {
        formattedBillingDate: formattedBillingDate,
        defaultValue: `Removal will be final at the end of your current billing cycle on ${formattedBillingDate}. <nav>See cancellation timeline.</nav>`
      }) +
      getText('paper-cancel.sub-description', {
        defaultValue:
          '<br>Change your mind? <span>Resume Paper Add-on Service.</span>'
      }),
    showCloseButton: false,
    notificationID: 'paperCancel',
    notificationType: NotificationType.CUSTOM,
    callbackFunction: handleRedirect,
    SecondCallbackFunction: secondHandleRedirect
  }
}

export default GetAllPaperNotification
