import React from 'react'
import useGetText from '@/hooks/useGetText'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import IconHeadset from '@veneer/core/dist/scripts/icons/icon_headset'
import {
  ReactiveSubscriptionButtonsContainer,
  ModalContent1,
  CardContainer,
  InsideCardTwo,
  CardLabel,
  CardText
} from './styles'

interface BuyerRemorseModalProps {
  showBuyerRemorseModal?: boolean
  handleCloseBuyerRemorse: () => void
  handleConfirmCancellation: () => void
  error?: boolean
}

const BuyerRemorseModal: React.FC<BuyerRemorseModalProps> = ({
  showBuyerRemorseModal,
  handleCloseBuyerRemorse,
  handleConfirmCancellation,
  error
}) => {
  const getText = useGetText('buyer-remorse-cancellation')
  const getErrorText = useGetText('SubscriptionManagement')
  return (
    <>
      <Modal
        align="start"
        closeButton={true}
        show={showBuyerRemorseModal}
        onClose={handleCloseBuyerRemorse}
        footer={
          <ReactiveSubscriptionButtonsContainer>
            <Button appearance="secondary" onClick={handleCloseBuyerRemorse}>
              {getText('button-back', {
                defaultValue: 'Back to my account'
              })}
            </Button>
            <Button
              appearance="primary"
              onClick={handleConfirmCancellation}
              loading={false}
            >
              {getText('Confirm-cancellation', {
                defaultValue: 'Confirm cancellation'
              })}
            </Button>
          </ReactiveSubscriptionButtonsContainer>
        }
        title={getText('title', {
          defaultValue: 'Do you want to cancel?'
        })}
      >
        <ModalContent1>
          <p>
            {getText('subtitle', {
              defaultValue:
                'If you cancel now, you will not receive the printer and no costs will be charged.'
            })}
          </p>
        </ModalContent1>
        <CardContainer>
          <InsideCardTwo>
            <IconHeadset size={40} />
            <div>
              <CardLabel>
                <p>
                  {getText('Need-help', {
                    defaultValue: 'Have questions or need help?'
                  })}
                </p>
              </CardLabel>
              <a
                href="https://www.hp.com/all-in-plan/printer-support"
                target="_blank"
                rel="noreferrer"
              >
                <CardText>
                  {getText('Contact-txt', {
                    defaultValue: 'Contact our 24/7 Pro live support'
                  })}
                </CardText>
              </a>
            </div>
          </InsideCardTwo>
        </CardContainer>
      </Modal>

      <Modal
        align="start"
        closeButton={true}
        show={error}
        onClose={handleCloseBuyerRemorse}
        data-testid="modal-content2"
        footer={
          <ReactiveSubscriptionButtonsContainer>
            <Button appearance="primary" onClick={handleConfirmCancellation}>
              {getErrorText('firstHourErrorScreen.button', {
                defaultValue: 'Try Again'
              })}
            </Button>
          </ReactiveSubscriptionButtonsContainer>
        }
        title={getErrorText('firstHourErrorScreen.title', {
          defaultValue: 'Something is wrong'
        })}
      >
        <p>
          {getErrorText('firstHourErrorScreen.subtitle', {
            defaultValue:
              'There is an issue with your cancellation request. Please try again.'
          })}
        </p>
      </Modal>
    </>
  )
}

export default BuyerRemorseModal
