import React, { useRef, useEffect } from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'
import { ThemeProvider } from '@veneer/core'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import WarningMarker from '../WarningMarker'
import CardItemContent from '../CardItemContent'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
} from '../../static/consts'

const Web = ({
  strings,
  getSecurityCheckStringsForProtectionStatus,
  active,
  isComplianceOff,
  onAccordionClick,
  isCompliant,
  isUnknown,
  isHPX
}) => {
  const accordionHeader = (
    <CardItemContent
      customCss={{
        textAlign: 'left',
        marginBottom: '4px',
        fontSize: isHPX ? '14px' : '',
        fontWeight: isHPX ? '400' : ''
      }}
      titleStyle={{ textAlign: 'left', marginTop: '4px', fontSize: '24px' }}
      title={strings.title}
      content={<>{strings.description}</>}
    />
  )
  // Using this ref to remove padding from Accordion's ListItem.
  // Change by CSS had problems on Safari
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current && ref.current.children[0]) {
      ref.current.children[0].style.padding = '0'
    }
  }, [ref])

  const accordionExpandedContent = () => {
    if (active && !isUnknown && !isHPX) {
      return (
        <List>
          {ConstraintsDataTypes.map((securityCheckName) => {
            const { title, description } =
              getSecurityCheckStringsForProtectionStatus(
                securityCheckName,
                strings,
                isCompliant
              )
            const isNetworkSecurityCheck =
              securityCheckName === ConstraintsDataTypesKeys.network
            if (isComplianceOff && isNetworkSecurityCheck) return <></>

            return (
              <CardItem
                customCss={{
                  padding: '20px 0 20px 0',
                  margin: '0 18px 0 68px',
                  width: 'auto'
                }}
                key={title}
                contentItem={
                  <CardItemContent
                    title={title}
                    isSubcontent
                    customCss={{ textAlign: 'left' }}
                    content={<>{description}</>}
                  />
                }
                leftItem={
                  !isCompliant &&
                  !isComplianceOff &&
                  isNetworkSecurityCheck && (
                    <WarningMarker
                      isSecured={false}
                      customCss={{ left: '-68px' }}
                    />
                  )
                }
              />
            )
          })}
        </List>
      )
    }
    return (
      <List style={{ borderTop: '1px solid #d9d9d9' }}>
        {ConstraintsDataTypes.map((securityCheckName, index) => {
          if (index === 3) return null
          const { title, description } =
            getSecurityCheckStringsForProtectionStatus(
              securityCheckName,
              strings,
              isCompliant
            )
          return (
            <CardItem
              customCss={
                {
                  // borderTop: '1px solid #ebebeb',
                  // borderRadius: '0px'
                }
              }
              key={title}
              contentItem={
                <CardItemContent
                  title={title}
                  isSubcontent
                  customCss={{
                    textAlign: 'left',
                    fontWeight: '400',
                    fontSize: '14px'
                  }}
                  content={<>{description}</>}
                />
              }
            />
          )
        })}
      </List>
    )
  }

  const unknownCard = (
    <CardItem
      customCss={{
        paddingLeft: isHPX ? '24px' : '',
        borderBottom: isHPX ? '1px solid #d9d9d9' : '',
        borderRadius: isHPX ? '0' : ''
      }}
      leftItem={<StatefulIcon value={false} isHPX={isHPX} />}
      contentItem={
        <CardItemContent
          title={strings.title}
          content={<>{strings.description}</>}
        />
      }
    />
  )

  const accordionCard = (
    <ThemeProvider shape="sharp">
      {!isHPX ? (
        <Accordion
          onExpand={onAccordionClick}
          onCollapse={onAccordionClick}
          data-testid="ss-accordion"
          items={[
            {
              expanded: active,
              header: {
                startArea: (
                  <div style={{ left: '-4px', position: 'relative' }}>
                    <StatefulIcon
                      value={isComplianceOff || isCompliant}
                      isHPX={isHPX}
                    />
                  </div>
                ),
                centralArea: accordionHeader
              },
              content: <>{accordionExpandedContent()}</>,
              id: 'accordionProtectionStatusWebItemID'
            }
          ]}
          id="accordionProtectionStatusWebID"
        />
      ) : (
        <>
          <CardItem
            data-testid="ss-card"
            leftItem={<StatefulIcon value={true} isHPX={isHPX}></StatefulIcon>}
            contentItem={<>{accordionHeader}</>}
          ></CardItem>
          {accordionExpandedContent()}
        </>
      )}
    </ThemeProvider>
  )

  const content = (
    <CardItem
      customCss={{
        padding: '0px',
        borderBottom: isHPX ? '1px solid #d9d9d9' : '',
        borderRadius: isHPX ? '0' : ''
      }}
      contentItem={accordionCard}
      customRef={ref}
    />
  )

  return isUnknown ? unknownCard : content
}

export default Web
