import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { AddOn, Device } from '../types/subscription'
import { TranslatorFunctionType } from '@/types/localization'
import { Panel, AddonsContainer } from './styles'
import { AddOnDevice } from '../AddOnDevice'
import { MasterDeviceDetail } from '../MasterDeviceDetail'
import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'
import ErrorCard from '@/components/ErrorCard'
import { BoosterCardPanel } from '../AddOnDevice/styles'

type SubPanelProps = {
  t: TranslatorFunctionType
  addOns?: AddOn[]
  device?: Device
  belowTitleArea?: React.ReactNode
  isDisabled?: boolean
  printerModelName?: string
  inkPlan?: SubscriptionOptionsResponse
  subscriptionId?: string
  isRemovePaperEnabledFlag?: boolean
  activePaperSubscription?: boolean
  shippingAddress?: any
  paperInfo?: any
  controllerId?: string
  handleAddedPaperNotification?: (value: boolean) => void
  isPaperEnabled: boolean
}

export function SubscriptionIncludesPanel(props: SubPanelProps) {
  const {
    addOns,
    device,
    belowTitleArea,
    t,
    isDisabled,
    printerModelName,
    inkPlan,
    subscriptionId,
    shippingAddress,
    paperInfo,
    controllerId,
    handleAddedPaperNotification,
    isPaperEnabled
  } = props as SubPanelProps

  return (
    <StyleThemeProvider theme={useTheme()}>
      <Panel>
        {device && (
          <MasterDeviceDetail
            key={device.name}
            {...device}
            t={t}
            belowTitleArea={belowTitleArea}
            isDisabled={isDisabled}
            printerModelName={printerModelName}
          />
        )}
        <AddonsContainer>
          {addOns && addOns.length > 0 && inkPlan ? (
            <AddOnDevice
              isDisabled={isDisabled}
              state={device.state}
              t={t}
              inkPlan={inkPlan}
              subscriptionId={subscriptionId}
              controllerId={controllerId}
              isRemovePaperEnabledFlag={props.isRemovePaperEnabledFlag}
              activePaperSubscription={props.activePaperSubscription}
              shippingAddress={shippingAddress}
              paperInfo={paperInfo}
              handleAddedPaperNotification={handleAddedPaperNotification}
              isPaperEnabled={isPaperEnabled}
            />
          ) : (
            <BoosterCardPanel>
              <ErrorCard
                getText={t}
                subtitle={t('errorScreen.plansubtitle', {
                  defaultValue: 'Your plan information did not load.'
                })}
              />
            </BoosterCardPanel>
          )}
        </AddonsContainer>
      </Panel>
    </StyleThemeProvider>
  )
}
