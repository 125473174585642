import React from 'react'
import { instantInkStates } from '@/utils/instantInkStates'
import { StyledAddressItemContainer } from '../styles'

import Header from './Header'
import Section from './Section'
import Footer from './Footer'

interface ShippingingAddressProps {
  commonProps: any
  instantInkAddress: any
  fetchIntantInkAddress: any
}

const ShippingAddress = (props: ShippingingAddressProps) => {
  const { commonProps, instantInkAddress, fetchIntantInkAddress } = props
  const { iInk } = commonProps
  const instantInkSubData =
    iInk?.iInkSubscriptionInfo.instantInkSubscriptions?.filter(
      (subitem) => subitem.state !== instantInkStates.OBSOLETE
    )
  const isDisabled = instantInkSubData?.length === 0
  const containResourceId = Boolean(instantInkAddress?.resourceId)
  const cardContent = (
    <StyledAddressItemContainer>
      <Header commonProps={props?.commonProps} />
      <div>
        {containResourceId && <Section instantInkAddress={instantInkAddress} />}
        <Footer
          commonProps={props?.commonProps}
          isDisabled={isDisabled}
          containResourceId={containResourceId}
          fetchIntantInkAddress={fetchIntantInkAddress}
          instantInkAddress={instantInkAddress}
        />
      </div>
    </StyledAddressItemContainer>
  )

  return <>{cardContent}</>
}

export default ShippingAddress
