import { SubscriptionEntity, mblEntityType } from '../types/SubscriptionEntity'

export const getDevices = (
  entities: SubscriptionEntity[]
): SubscriptionEntity[] =>
  entities.filter(
    (entity) =>
      entity.entityType === mblEntityType.printer ||
      entity.entityType === mblEntityType.chromebook ||
      entity.entityType === mblEntityType.pc
  ) || null
