import React from 'react'
import {
  ContentContainer,
  StyledTextContainer,
  StyledDescContainer
} from './styles'
import Card from '@veneer/core/dist/scripts/card'
import useGetText from '@/hooks/useGetText'
import { Container, StyledMainTitle } from '../styles'

const EmptyState = () => {
  const getText = useGetText()
  const emptyStateHeaderContent = (
    <>
      <StyledDescContainer>
        <div>{getText('emptyState.message1')}</div>
      </StyledDescContainer>
    </>
  )

  const emptyStateMessage = (
    <ContentContainer data-testid="emptyStateMessageTestId">
      <StyledTextContainer>
        <div>{getText('emptyState.message2')}</div>
      </StyledTextContainer>
    </ContentContainer>
  )

  return (
    <Container>
      <StyledMainTitle role="heading" aria-level="1">
        {getText('mainTitle', { defaultValue: 'Shipping & Billing' })}
      </StyledMainTitle>
      {emptyStateHeaderContent}
      <Card
        appearance="dropShadow"
        content={emptyStateMessage}
        className="card-box"
      />
    </Container>
  )
}

export default EmptyState
