import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@veneer/theme'
import React from 'react'
import { TranslatorFunctionType } from '../types/localization'
import { MfePropsType } from '../types/mfeProps'
import SubscriptionInfo from 'src/components/SubscriptionInfo/SubscriptionInfo'
import { getEnvironmentVariables, getStack } from '../helpers'
import useApolloClient from '../hooks/useApolloClient'

export const Root = ({
  t,
  authProvider,
  stack,
  analytics,
  localization,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const graphqlClient = useApolloClient(
    authProvider,
    envVariables?.variables?.MAGENTO_URL
  )

  return (
    <ApolloProvider client={graphqlClient}>
      <ThemeProvider density="high">
        <SubscriptionInfo
          commonProps={{
            authProvider,
            stack,
            t,
            analytics,
            localization,
            navigation
          }}
        />
      </ThemeProvider>
    </ApolloProvider>
  )
}
