import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/esm/scripts/modal'

const { color, layout, typography } = tokens

export const StyledModal = styled(Modal)`
  && {
    .vn-modal__close > span > svg {
      color: ${color.white};
      background-color: ${color.gray9};
      border-radius: 50%;
      padding: 2px;
    }

    .vn-modal--content {
      padding: ${layout.size0};
      max-width: 648px;
    }
  }

  @media (max-width: ${layout.mdMax}) {
    .vn-modal--content {
      width: 75%;
    }
  }

  @media (max-width: ${layout.smMin}) {
    .vn-modal--content {
      width: 90%;
    }
  }
`

export const ImageBlock = styled.div`
  > img {
    width: 648px;
    height: 198px;
    gap: ${layout.size0};
    border-radius: ${layout.size4} ${layout.size4} ${layout.size0}
      ${layout.size0};
    opacity: ${layout.size0};

    @media (max-width: ${layout.smMin}) {
      width: 100%;
      height: 100%;
    }
  }
`

export const ModalBody = styled.div`
  padding: 40px 48px;
  color: ${color.gray12};

  font-family: ${typography.family0};
  @media (max-width: ${layout.smMin}) {
    padding: ${layout.size8} ${layout.size4};
  }
`

export const Title = styled.p`
  font-size: ${typography.size7};
  line-height: ${typography.lineHeight7};
  margin-bottom: ${layout.size6};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size5};
    line-height: ${typography.lineHeight5};
  }
`

export const SubTitle = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size1};
    line-height: ${typography.lineHeight2};
  }
`
export const PriceText = styled.p`
  font-size: ${typography.size3};
  line-height: ${typography.lineHeight4};
`

export const ShippingText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
`

export const AddressText = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  text-transform: capitalize;
`

export const ChangeText = styled.p`
  color: ${color.hpBlue7};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  margin-top: ${layout.size4};
  cursor: pointer;
`

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${layout.size6};
`

export const MyPlanIncludes = styled.div`
  width: 552px;
  padding: ${layout.size2} ${layout.size6} ${layout.size6} ${layout.size6};
  display: flex;
  flex-direction: column;
  opacity: 1;

  @media (max-width: ${layout.smMin}) {
    width: 100%;
    padding: ${layout.size2} ${layout.size4} ${layout.size0} ${layout.size0};
  }
`

export const MyPlanTitle = styled.h2`
  width: Fill (552px) px;
  height: Fixed (49px) px;
  padding: ${layout.size2} ${layout.size6} ${layout.size0} ${layout.size6};
  opacity: ${layout.size0};
  font-size: ${typography.size4};
  color: ${color.gray10};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size3};
    padding: ${layout.size2} ${layout.size4};
  }
`

export const MyPlanUnderline = styled.div`
  border: 1px solid var(--Stroke-Default, ${color.gray3});
`

export const MyPlanList = styled.ul`
  font-size: ${typography.size2};
  color: ${color.gray10};
  list-style-type: none;
  width: Fill (552px) px;
  height: Hug (264px) px;
  padding: ${layout.size4} ${layout.size0} ${layout.size6} ${layout.size8};
  display: grid;
  gap: ${layout.size4};
  opacity: ${layout.size0};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size1};
  }
`

export const MyPlanListItem = styled.li`
  margin: 5px 0;
  display: flex;
  align-items: center;
  width: Fill (520px) px;
  height: Hug (${layout.size6}) px;
  gap: ${layout.size2};
  opacity: ${layout.size0};

  p {
    width: Fill (488px) px;
    height: Hug (${layout.size6}) px;
    gap: ${layout.size3};
    display: flex;
  }

  @media (max-width: ${layout.smMin}) {
    margin: ${layout.size1} 0;
  }
`
