import { Link, useAnalytics } from '@monetization/hpaip-ui-shared-components'
import IconEnvelope from '@veneer/core/dist/esm/scripts/icons/icon_envelope'
import React from 'react'
import { ShipmentCard } from 'src/types/shipmentCard'
import { Container } from './styles'
import CommonProps from '../../types/commonProps'

type SupportTextProps = {
  shipmentCards: ShipmentCard[]
  commonProps: CommonProps
}

export const SupportText = ({
  shipmentCards,
  commonProps
}: SupportTextProps) => {
  const { t } = commonProps
  const { publishEvent, events } = useAnalytics()
  const handleClick = () => {
    publishEvent(events.ClickRecyclingEnvelope())
  }
  return (
    <Container hasShipmentCards={Boolean(shipmentCards.length)}>
      <p>{t('shipment.support.cartridge', 'Cartridge support')}</p>

      <Link
        target="_blank"
        rel="noopener"
        href="https://www.hp.com/us-en/hp-information/recycling/ink-toner.html"
        id="reciclying-link"
        onClick={handleClick}
      >
        <IconEnvelope width={20} height={14} />

        {t('shipment.support.request', 'Request a recycling envelope')}
      </Link>
    </Container>
  )
}
