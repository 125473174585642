import { usePostCancelNotification } from '@/hooks/usePostCancelNotification'
import { SubscriptionStateEnum } from '@/types'
import { NavigationType } from '@/types/shell'
import checkCancellationTriggered from '../../utils/pendingChangesDataUtil'
const GetAllPostCancelNotification = (
  subscriptionId,
  tenantId,
  mainEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation?: NavigationType,
  allNotificationsObject?: any,
  pendingData?: any
) => {
  const notificationObject = usePostCancelNotification(
    subscriptionId,
    tenantId,
    mainEntity,
    navigation,
    allNotificationsObject,
    pendingData
  )
  const checkPendingChanges = checkCancellationTriggered(
    pendingData,
    mainEntity?.entityId
  )
  if (
    mainEntity?.state === SubscriptionStateEnum.CANCELED ||
    mainEntity?.state === SubscriptionStateEnum.CANCELING ||
    (mainEntity?.state === SubscriptionStateEnum.SUSPENDED &&
      checkPendingChanges)
  )
    return notificationObject
}

export default GetAllPostCancelNotification
