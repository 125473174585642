export const getNotification = (getText, notificationType) => {
  switch (notificationType) {
    case 'instantInkShippingSuccess':
      return {
        title: getText('shipping.shippingAddressSavedSuccess.instantInkTitle', {
          defaultValue: 'Your Instant Ink shipping information has been saved.'
        }),
        description: '',
        showCloseButton: true,
        notificationID: 'InstantInkShippingSuccess',
        notificationType: 'positive'
      }
    case 'instantInkShippingError':
      return {
        title: getText('billing.failedApi', {
          defaultValue: 'Something went wrong. Try again later.'
        }),
        description: '',
        showCloseButton: true,
        notificationID: 'InstantInkShippingError',
        notificationType: 'warning'
      }
    case 'noInstantInkShippingAddress':
      return {
        title: getText('shippingWarningSubtitle', {
          defaultValue:
            'Please add your HP Instant Ink subscription shipping information below.'
        }),
        description: '',
        showCloseButton: false,
        notificationID: 'InstantInkNoShippingAddress',
        notificationType: 'warning'
      }
    default:
      return {
        title: getText('defaultNotification', {
          defaultValue: 'Default notification type.'
        }),
        description: '',
        showCloseButton: false,
        notificationID: 'defaultNotification',
        notificationType: 'info'
      }
  }
}
