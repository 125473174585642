import styled from 'styled-components';
import { tokens } from '@veneer/tokens';

const {
  size5: layoutSize5,
  size4: layoutSize4,
  size3: layoutSize3,
  size6: layoutSize6,
  size2: layoutSize2,
  size1: layoutSize1,
  cornerRadius4
} = tokens.layout;
const { white, gray12, gray9, gray3, gray6, hpBlue7 } = tokens.color;
const {
  size3,
  size2,
  size1,
  size7,
  size4,
  size5,
  lineHeight7,
  lineHeight3,
  lineHeight1,
  lineHeight2,
  lineHeight5,
  family0
} = tokens.typography;

export const Container = styled.div`
  margin: 40px;
  padding: 40px;
  background: ${white};
  border-radius: ${cornerRadius4};
`;

export const Title = styled.h2`
  font-size: ${size7};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight7};
  color: ${gray12};
`;
export const CardText = styled.p`
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
`;
export const StepperBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
`;
export const StepOne = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${layoutSize6};
  margin-top: 8px;
`;
export const StepTwoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${layoutSize6};
  margin-top: 8px;
`;
export const StepThreeContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${layoutSize6};
  margin-top: 8px;
  width: auto;
`;

export const StepThreeContentText = styled.div`
  color: #737373;
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: 8px;
`;

export const StepTwoContentText = styled.div`
  color: #737373;
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
`;

export const StepTitle = styled.div`
  font-weight: 700;
  font-size: ${size3};
  line-height: 24px;
  color: ${gray12};
  margin-bottom: 26px;
`;
export const StepTwoTitle = styled.div`
  font-weight: 400;
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray6};
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StepThreeCardAlignment = styled.div`
  margin-bottom: 20px;
`;

export const StepThreeTitle = styled.div`
  font-weight: 700;
  font-size: ${size3};
  line-height: ${lineHeight3};
  color: ${gray12};
  margin-bottom: ${layoutSize2};
  margin-top: ${layoutSize2};
`;

export const Card = styled.div`
  width: 100%;
`;
export const BenfitItem = styled.div`
  @media (min-width: 375px) {
    display: flex;
    gap: ${layoutSize1};
  }
`;
export const BenfitCard = styled.div`
@media (min-width: 500px) {
  display flex;
  flex-direction:row;
  gap:26px;
}
`;

export const BenfitContent = styled.p`
  font-size: ${size1};
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray9};
  margin-top: ${layoutSize2};
  @media (min-width: 375px) {
    margin-top: ${layoutSize1};
  }
`;
export const BenfitList = styled.div`
  @media (min-width: 375px) {
    margin-left: ${layoutSize6};
  }
  @media (min-width: 1280px) {
    margin-left: 0px;
  }
`;
export const StepLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Line = styled.div`
  border: 1px solid #dbdbdb;

  @media (min-width: 375px) {
    margin: ${layoutSize5};
  }
  @media (min-width: 1280px) {
    height: 162px;
    margin: 0px;
  }
`;
export const ConfirmationContent = styled.p`
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  color: ${gray12};
  margin-top: ${layoutSize5};
`;
export const SubText = styled.p`
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
  margin-top: ${layoutSize2};
`;

export const StepOneContent = styled.div`
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  color: ${gray9};
`;

export const Label = styled.p`
  position: absolute;
  top: 8px;
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: #666;
`;
export const HrLine = styled.hr`
  border: 1px solid #dbdbdb;
  margin: ${layoutSize3} 0 ${layoutSize3} 0;
  @media (min-width: 375px) {
    height: 106px;
  }
  @media (min-width: 500px) {
    height: 40px;
  }
  @media (min-width: 1280px) {
    height: 36px;
    margin-top: 12px;
  }
`;
export const HrLineTwo = styled.hr`
  border: 1px solid #dbdbdb;
  margin-top: 12px;
  @media (min-width: 375px) {
    height: 580px;
  }
  @media (min-width: 500px) {
    height: 360px;
  }
  @media (min-width: 640px) {
    height: 270px;
  }
  @media (min-width: 900px) {
    height: 223px;
  }
`;

export const BenfitContentSupport = styled.p`
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
`;
export const InsideCardTwo = styled.div`
  display: flex;
  gap: ${layoutSize4};
  padding-top: ${layoutSize5};
`;

export const LineSupport = styled.div`
  border-top: 1px solid ${gray3};
`;

export const ContentTitle = styled.div`
  color: ${gray12};

  font-family: ${family0};
  font-size: ${size4};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight1};
`;
export const CardOneContainer = styled.div`
  margin-top: 7px;
`;

export const CardSupportTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: ${layoutSize1};
`;

export const CardSupportLabel = styled.p`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`;

export const SupportCard = styled.div`
  margin-top: 40px;
`;

export const RecyclingText = styled.p`
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray6};
  margin-bottom: ${layoutSize2};
`;
