import React from 'react'
import { Container } from './Page.styles'
import { InkPlanList } from '../InkPlanList'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { MemoizedPaperWrapper } from '../PaperWrapper/PaperWrapper'
import { DisclaimerText } from '../DisclaimerText'
import { ChangePlanDialog } from '../ChangePlanDialog'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import { InkOffer, InkOffersRecord } from '../../types/instantink'
import { CommonProps } from 'src/types/mfeProps'

interface FlagProps {
  addPaperEnabled?: boolean
  isPrinterSubscribedOrUpdating?: boolean
  loading?: boolean
  isError?: boolean
  isPrinterCancelledORReturned?: boolean
  showDialog?: boolean
  isDowngrade?: boolean
  isPaperAdded?: boolean
}

interface ActionsProps {
  handleOnSubscribe: (offer: InkOffer, isDowngrade: boolean) => void
  setIsPaperAdded: (value: boolean) => void
  handleChangePlanDialogClose: () => void
  handleChangePlanDialogConfirm: () => void
}

interface InkOfferProps {
  currentOffer?: InkOffer
  pendingOffer?: InkOffer
}

export interface MainContentProps {
  booleanFlags?: FlagProps
  actions?: ActionsProps
  inkOffers?: InkOfferProps
  t?: any
  printerData?: any
  errors?: any[]
  offers?: InkOffersRecord
  billingCycles?: any
  isDowngrade?: boolean
  billingCycleEndDate?: string
  nextBillingCycleStartDate?: string
  selectedOffer?: any
  commonProps?: CommonProps
  printerDetails?: any
}

export const MainContent: React.FC<MainContentProps> = ({
  booleanFlags,
  actions,
  inkOffers,
  t,
  printerData,
  errors,
  offers,
  billingCycles,
  billingCycleEndDate,
  nextBillingCycleStartDate,
  selectedOffer,
  commonProps,
  printerDetails
}) => (
  <Container>
    <LoadingHandler
      loading={booleanFlags.loading}
      error={booleanFlags.isError}
      $position="absolute"
      customError={
        <ErrorSection
          t={t}
          title="Something went wrong"
          description="Your account information did not load."
          buttonText="Try again"
        />
      }
    />
    {!booleanFlags.loading &&
      !errors.length &&
      !booleanFlags.isPrinterCancelledORReturned &&
      printerData?.printer !== undefined && (
        <>
          <InkPlanList
            printerData={printerData}
            offers={offers}
            currentOffer={inkOffers.currentOffer}
            pendingOffer={inkOffers.pendingOffer}
            onSubscribe={actions.handleOnSubscribe}
            t={t}
          />
          {printerData?.paperEligibilityInfo?.enabled &&
            !booleanFlags.isPaperAdded && (
              <MemoizedPaperWrapper
                printerData={printerData}
                t={t}
                handleAddedPaperNotification={actions.setIsPaperAdded}
                commonProps={commonProps}
                currentOffer={inkOffers.currentOffer}
                printerDetails={printerDetails}
              />
            )}
          <DisclaimerText t={t} />
          <ChangePlanDialog
            show={booleanFlags.showDialog}
            showError={!!billingCycles.error}
            onClose={actions.handleChangePlanDialogClose}
            onConfirm={actions.handleChangePlanDialogConfirm}
            isDowngrade={booleanFlags.isDowngrade}
            currentBillingCycleEndDate={billingCycleEndDate}
            nextBillingCycleStartDate={nextBillingCycleStartDate}
            currentOffer={inkOffers.currentOffer}
            chosenOffer={selectedOffer}
            t={t}
          />
        </>
      )}
  </Container>
)
