import packageJson from '../../package.json'

const [orgName, projectName] = packageJson.name.substring(1).split(/\/(.+)/)

const projectNames = {
  orgName,
  projectName,
  namespace: `${orgName}__${projectName}`,
  packageJsonName: packageJson.name
}

export default projectNames
