// import '../../../css/SmartSecurityStyles.css';
import React from 'react'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PrinterIcon from '../PrinterIcon'
import BadgeIcon from '../BadgeIcon'

const Mobile = ({ isActive, isUnknown, strings, name, isHPX }) => {
  return (
    <CardItem
      customCss={{
        backgroundColor: !isHPX ? 'transparent' : '',
        borderBottom: isHPX ? '1px solid #d9d9d9' : '',
        borderBottomLeftRadius: isHPX ? '0px' : '',
        borderBottomRightRadius: isHPX ? '0px' : ''
      }}
      contentItem={
        !isHPX ? (
          <>
            <div
              style={{
                margin: 'auto',
                gridRow: 0,
                width: 'min-content',
                position: 'relative'
              }}
            >
              <PrinterIcon />
              {isUnknown || !isActive ? '' : <BadgeIcon />}
            </div>
            <CardItemContent
              titleStyle={{
                textAlign: 'center',
                width: '100%',
                wordBreak: 'break-word'
              }}
              title={sprintf(strings.title, name)}
            />
          </>
        ) : (
          <h4
            className="list-item__middle-section--primary"
            data-testid="ss-printer-title"
          >
            <p
              style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '28px'
              }}
            >
              Printer Security
            </p>
          </h4>
        )
      }
    />
  )
}

export default Mobile
Mobile.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isUnknown: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}
