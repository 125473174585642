import React from 'react'
import { useBillingCycleDropdown } from '../../../hooks'
import { BillingUsageData } from './BillingUsageData'
import {
  StyledSelect,
  LoadingSelect,
  TitleText,
  BillingCalcLink,
  Container,
  Header,
  Divider,
  BillingTextLink
} from './styles'
import { PrearrivalCard } from '../PrearrivalCard'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

export const BillingCardContent = ({
  printerData,
  billingCycles,
  isPreArrival = false
}) => {
  const { t } = useHistoryContext()
  const { selectOptions, selectedBillingCyclePeriod, handleSelectChange } =
    useBillingCycleDropdown(billingCycles?.data)
  return (
    <Container>
      <Header>
        <TitleText>
          {t('history.cards.billing.card.title', 'Billing cycle period')}
        </TitleText>
        <BillingCalcLink>
          {t(
            'history.cards.billing.card.how-is-calculated',
            'How is billing calculated?'
          )}
        </BillingCalcLink>
      </Header>
      {selectOptions ? (
        <>
          <StyledSelect
            options={selectOptions}
            clearIcon={false}
            id="select-usage"
            label={t('history.cards.billing.select.label', 'Billing Cycle')}
            placement="bottom"
            onChange={handleSelectChange}
            value={[selectedBillingCyclePeriod?.value]}
          />
          <Divider />
        </>
      ) : (
        <LoadingSelect
          color="colorWhite"
          appearance="circular"
          behavior="indeterminate"
        />
      )}
      {isPreArrival ? (
        <PrearrivalCard />
      ) : (
        <BillingUsageData
          printerData={printerData}
          selectedBillingCyclePeriod={selectedBillingCyclePeriod}
        />
      )}
      <BillingTextLink>
        <a>
          {t(
            'history.cards.billing.card.how-is-calculated',
            'How is billing calculated?'
          )}
        </a>
      </BillingTextLink>
    </Container>
  )
}
