import React from 'react'
import ShippingBillingContainer from './ShippingBillingContainer'
import { ShippingBillingAnalyticsProvider } from '@/utils/ShippingBillingAnalyticsProvider/index'

const InitialComponent = (props) => {
  props.commonProps.notificationList =
    props.commonProps.commonNotificationData.notificationsList
  return (
    <>
      {(props?.commonProps?.isHPOneSubscriptionAvailable ||
        (props?.commonProps?.iInk?.iInkSubscriptionInfo
          ?.instantInkSubscriptions &&
          props?.commonProps?.iInk?.iInkSubscriptionInfo.instantInkSubscriptions
            .length > 0)) && (
        <ShippingBillingAnalyticsProvider commonProps={props.commonProps}>
          <ShippingBillingContainer commonProps={props.commonProps} />
        </ShippingBillingAnalyticsProvider>
      )}
    </>
  )
}

export default InitialComponent
