import React from 'react'
import {
  useCommonNotification,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import { CommonProps } from 'src/types/mfeProps'
import { Page } from '../Page'
import { UpdatePlanAnalyticsProvider } from 'src/utils/UpdatePlanAnalyticsProvider'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'

interface Props {
  subscriptionId?: string
  commonProps?: CommonProps
  setIndex?: React.Dispatch<React.SetStateAction<number>>
  index?: number
  productData?: any
}

const InitialComponent: React.FunctionComponent<Props> = ({
  subscriptionId,
  commonProps,
  setIndex,
  index,
  productData
}) => {
  const { t } = commonProps
  const flags = useFlags()
  const flagObject = {
    enablePaperOnPaaS: flags?.enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: flags?.enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: flags?.enableAddPaperPostEnrollment
  }

  const data = useCommonNotification(
    'updatePlan',
    subscriptionId,
    flagObject,
    null,
    null
  )

  console.log('Jay1', data)
  return (
    <>
      {data?.printerData?.printer ? (
        <UpdatePlanAnalyticsProvider
          analytics={commonProps.analytics}
          subscriptionId={subscriptionId}
          entityStartDate={data?.printerData?.printer?.entityStartDate}
        >
          <Page
            printerProps={data}
            commonProps={commonProps}
            setIndex={setIndex}
            index={index}
            productData={productData}
          />
        </UpdatePlanAnalyticsProvider>
      ) : (
        <LoadingHandler
          loading={data?.printerData?.loading}
          error={data?.printerData?.error}
          customError={
            <ErrorSection
              t={t}
              title="Something went wrong"
              description="Your account information did not load."
              buttonText="Try again"
            />
          }
        ></LoadingHandler>
      )}
    </>
  )
}

export default InitialComponent
