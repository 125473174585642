import { InkOffer, InkOffersRecord } from '../types/instantink'

export function getPendingOffer(
  printerData: any,
  offers: InkOffersRecord,
  customerRequestedPlanChange: string,
  chosenPendingOffer: InkOffer
): InkOffer | null {
  if (!printerData?.instantInk || !offers) return null

  const pendingSku = customerRequestedPlanChange
  return chosenPendingOffer ?? offers[pendingSku] ?? null
}
