import React from 'react'
// import '../../css/SmartSecurityStyles.css';
import PropTypes from 'prop-types'
import { navigateToPortal } from '../utils/navigate'

function HelperInfo({ i18n, mobileSize, isHPX }) {
  function handleClick(e) {
    e.preventDefault()
    navigateToPortal()
  }

  return (
    <div
      className="ss-help-section"
      style={{
        padding: isHPX ? '16px 24px 16px 24px' : '0',
        gap: isHPX ? '0' : '16px'
      }}
    >
      <h6
        style={{
          display: 'inline-block',
          fontSize: isHPX ? '12px' : '',
          fontWeight: isHPX ? '400' : '',
          color: isHPX ? '#9B9CB1' : ''
        }}
        data-testid="ss-help-title"
      >
        <p>{i18n.t('help.title')}</p>
        {mobileSize || isHPX || <hr className="divider" />}
      </h6>
      <div className="ss-helper-links">
        <span>
          <a
            style={{
              color: isHPX ? '#4759f5' : '',
              fontSize: isHPX ? '16px' : '',
              fontWeight: isHPX ? '400' : ''
            }}
            href="#"
            onClick={handleClick}
            data-testid="ss-help-link"
          >
            <p>{i18n.t('help.link1')}</p>
          </a>
        </span>
      </div>
    </div>
  )
}

export default HelperInfo

HelperInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  mobileSize: PropTypes.bool.isRequired
}
