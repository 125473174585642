import Card from '@veneer/core/dist/esm/scripts/card'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Select from '@veneer/core/dist/esm/scripts/select'

const { color, layout, typography } = tokens

export const StyledCard = styled(Card)`
  && {
    box-sizing: border-box;
    box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
    min-height: 100%;
    padding: ${layout.size8};
  }
`

export const Container = styled.div``

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${layout.size8};

  @media only screen and (max-width: ${layout.smMin}) {
    padding: ${layout.size2};
  }
`

export const TitleText = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size4};
  line-height: ${typography.lineHeight4};
`

export const BillingCalcLink = styled.a`
  color: ${color.hpBlue7};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  cursor: pointer;

  @media only screen and (max-width: ${layout.smMin}) {
    display: none;
  }
`

export const StyledSelect = styled(Select)`
  margin-bottom: ${layout.size8};

  @media only screen and (max-width: ${layout.smMin}) {
    margin-bottom: ${layout.size6};
  }
`

export const Divider = styled.div`
  display: none;

  @media only screen and (max-width: ${layout.smMin}) {
    display: block;
    margin-bottom: ${layout.size4};
    height: 1px;
    background: ${color.gray3};
  }
`

export const LoadingSelect = styled(ProgressIndicator)`
  && {
    align-self: center;
    margin: ${layout.size1} 0px;
  }
`

export const BillingTextLink = styled.div`
  text-align: center;
  margin-top: ${layout.size4};

  a {
    color: ${color.hpBlue7};
    font-size: ${typography.size2};
    line-height: ${typography.lineHeight2};
    cursor: pointer;
  }

  @media only screen and (min-width: ${layout.smMin}) {
    display: none;
  }
`
