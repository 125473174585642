import React from 'react';
import { RemorsePeriod } from '../RemorsePeriod';
import { NotInRemosePeriod } from '../NotInRemosePeriod';

const DeviceShipped = ({
  inRemorse,
  t,
  canceledDate,
  isLoading,
  costValue,
  props,
  after10days,
  after10DaysOfBilling
}) => {
  return (
    <>
      {inRemorse ? (
        <RemorsePeriod
          t={t}
          canceledDate={canceledDate}
          isLoading={isLoading}
          filteredCost={costValue}
          props={props}
          after10days={after10days}
          isInRemorse={inRemorse}
        />
      ) : (
        <NotInRemosePeriod
          t={t}
          canceledDate={canceledDate}
          isLoading={isLoading}
          lastDayToReturnPrinter={after10DaysOfBilling}
          filteredCost={costValue}
          props={props}
          isInRemorse={inRemorse}
        />
      )}
    </>
  );
};

export default DeviceShipped;
