import styled from 'styled-components';
import tokens from '@veneer/tokens';
import {
  IconCheckCircle,
  IconInfo,
  IconMinusCircle,
  IconShieldCheck,
  IconShieldOff,
  IconShieldWarning,
  IconWarningAlt,
  IconXCircle,
  IconHelp
} from '@veneer/core';

export const IconInformation = styled(IconInfo)`
  && {
    fill: #212121; // colorGray10
  }
`;

export const IconPrinterEnabled = styled(IconCheckCircle)`
  && {
    fill: #08a622; // colorGreen6
  }
`;

export const IconPrinterWarning = styled(IconWarningAlt)`
  && {
    fill: #e8701a; // colorOrange6
  }
`;

export const IconPrinterDisabled = styled(IconXCircle)`
  && {
    fill: #bf1212; // colorRed6
  }
`;

export const IconSecurityMonitored = styled(IconShieldCheck)`
  && {
    fill: #1c7a17; // colorGreen6
  }
`;

export const IconSecurityNotMonitored = styled(IconShieldOff)`
  && {
    fill: #adadad; // colorGray4
  }
`;

export const IconSecurityNeedsAttention = styled(IconShieldWarning)`
  && {
    fill: #d16607; // colorOrange6
  }
`;

export const IconSecurityUnavailable = styled(IconShieldOff)`
  && {
    fill: #404040; // colorGray7
  }
`;

export const IconPrinterError = styled(IconXCircle)`
  && {
    fill: #404040; // colorGray7
  }
`;

export const IconWarningAltOrange = styled(IconWarningAlt)`
  && {
    fill: #e8701a; // colorOrange6
  }
  min-width: 24px;
`;

export const IconMinusCircleRed = styled(IconMinusCircle)`
  && {
    fill: #bf1212; // colorRed6
  }
  min-width: 24px;
`;

export const IconMinusCircleDarkGray = styled(IconMinusCircle)`
  && {
    fill: ${tokens.colorGray10};
  }
  min-width: 24px;
`;

export const IconMinusCircleGray = styled(IconMinusCircle)`
  && {
    fill: ${tokens.colorGray4};
  }
  min-width: 24px;
`;

export const IconCheckCircleGreen = styled(IconCheckCircle)`
  && {
    fill: #08a622; // colorGreen6
  }
`;

export const IconHelpBlue = styled(IconHelp)`
  && {
    fill: ${tokens.colorHpBlue6};
  }
  min-width: 24px;
`;
