import { Container, Divider } from './styles'
import React, { FC } from 'react'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import EstimatedChargesCard from '../EstimatedChargesCard'
import useEstimatedCharges from 'src/hooks/useEstimatedCharges/useEstimatedCharges'
import { PlanDetailsCardInfo } from '../PlanDetailsCardInfo/PlanDetailsCardInfo'
interface PlanDetailsCardProps {
  subscriptionId: string
  printerData: any
  isPreArrival?: boolean
  isCancelled?: boolean
}

export const PlanDetailsCard: FC<PlanDetailsCardProps> = ({
  subscriptionId,
  printerData,
  isPreArrival,
  isCancelled
}) => {
  const { data, loading, error } = useEstimatedCharges(printerData)
  const { t } = useHistoryContext()

  return (
    <Container>
      <PlanDetailsCardInfo
        subscriptionId={subscriptionId}
        printerData={printerData}
        isPreArrival={isPreArrival}
      />
      {!isCancelled && !isPreArrival && (
        <>
          <Divider />
          <LoadingHandler
            loading={loading}
            error={error}
            customError={<p>{t('error-section.title', 'Loading...')}</p>}
          >
            <EstimatedChargesCard data={data} />
          </LoadingHandler>
        </>
      )}
    </Container>
  )
}
