import { PaymentIssue } from 'src/types/subscriptionState';

export const getHasCancellation = (
  pendingChangesData: PaymentIssue[],
  printerEntityId: string
) => {
  if (!pendingChangesData.length) return false;
  return pendingChangesData?.some(
    (item) =>
      item?.type === 'cancellation' &&
      (item?.reason?.type === 'cancellationAfterTrialPeriod' ||
        item?.reason?.type === 'cancellationWithinTrialPeriod') &&
      item?.entityId === printerEntityId
  );
};
