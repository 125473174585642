import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { breakPoints } from '../Statements/styles'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${tokens.color.white};
  border-color: #dbdbdb;
  border-radius: ${tokens.layout.cornerRadius3};
  border-width: 1px;
  min-height: 532px;
  box-shadow: 0 1px 4px 1px #21212133;
  margin-top: ${tokens.layout.size6};
  @media ${breakPoints.maxSm} {
    min-height: 250px;
  }
`

export const StyledText = styled.p`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
`
