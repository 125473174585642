import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { gray12, hpBlue7 } = tokens.color
const { size2, lineHeight3 } = tokens.typography
const { smMin, size8: layoutSize8, size5 } = tokens.layout

export const Container = styled.div`
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray12};
  @media (min-width: 300px) {
    padding-right: ${size5};
    padding-left: ${size5};
  }
  @media (min-width: 641px) {
    padding-right: ${layoutSize8};
    padding-left: ${layoutSize8};
  }
  @media (min-width: 1280px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (max-width: ${smMin}) {
    margin-bottom: ${layoutSize8};
  }
`

export const InfoText = styled.p``

export const RemovePaperText = styled.p`
  margin-top: 2px;
`

export const RemovePaperLink = styled.a`
  color: ${hpBlue7};
  cursor: pointer;
`
