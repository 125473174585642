// import '../../../css/SmartSecurityStyles.css';
import React, { useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'
import AssessmentHooksContext from '../../contexts/AssessmentHooksContext'
import Mobile from './Mobile'
import Web from './Web'
import AssessmentContext from '../../contexts/AssessmentContext'
import { stringsForState } from '../../strings/PrinterStatus'
import { SecurityAssessmentStatus } from '../../static/consts'

const PrinterStatus = () => {
  const { isMobileSize, isHPX } = useContext(AuthContext)
  const { useSecurityAssessmentStatusHook, useAssessmentStringsHook } =
    useContext(AssessmentHooksContext)
  const { assessment, printer } = useContext(AssessmentContext)
  const { active, isUnknown } = useSecurityAssessmentStatusHook
  const { printerStatusStrings } = useAssessmentStringsHook
  const name = printer?.identity?.makeAndModel?.name

  const strings = stringsForState(printerStatusStrings)

  const isActive = active === SecurityAssessmentStatus.trueValue

  const componentProps = {
    isActive,
    assessment,
    printer,
    isUnknown,
    strings,
    name,
    isHPX
  }

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default PrinterStatus
