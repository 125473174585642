import React, { useState } from 'react'
import {
  useCommonNotification,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import InitialComponent from '../InitialComponent'
import useCriticalScopes from '@/hooks/useCriticalScopes'
import useSettingsInfo from '@/hooks/useSettingInfo'

const CombinedNotificationFlow = (props) => {
  const [billingModal, setBillingModal] = useState<boolean>(false)
  const [iIBillingModal, setIIBillingModal] = useState<boolean>(false)
  const { data: settingsData } = useSettingsInfo(
    props?.commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions
      .length > 0
  )

  const {
    error: criticalScopeError,
    isLoading: criticalScopeLoading,
    forceRefresh: forceCriticalScope
  } = useCriticalScopes(
    props?.commonProps?.iInk?.iInkSubscriptionInfo?.instantInkSubscriptions
      .length > 0
  )

  const [sessionModal, setSessionModal] = useState<boolean>(false)
  const handleSessionModal = () => setSessionModal(!sessionModal)

  const handleBillingModal = () => setBillingModal(!billingModal)
  const handleIIBillingModal = () => setIIBillingModal(!iIBillingModal)
  const { enableManagePaperPostEnrollment, enableBuyerRemorseCancellation } =
    useFlags()
  const flagObject = {
    enablePaperOnPaaS: enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: enableBuyerRemorseCancellation
  }
  const commonNotificationData = useCommonNotification(
    'ShippingBilling',
    props?.commonProps?.hpaip?.hpOneSubscriptionInfo?.[0]?.subscriptionId,
    flagObject,
    handleBillingModal,
    null,
    handleIIBillingModal
  )
  props.commonProps.commonNotificationData = commonNotificationData
  const commonProps = {
    ...props.commonProps,
    billingModal,
    iIBillingModal,
    handleBillingModal,
    handleIIBillingModal,
    criticalScopeError,
    criticalScopeLoading,
    forceCriticalScope,
    sessionModal,
    handleSessionModal,
    settingsData
  }

  return <InitialComponent commonProps={commonProps} />
}

export default CombinedNotificationFlow
