import React from 'react'
import {
  MyPlanIncludes,
  MyPlanTitle,
  MyPlanUnderline,
  MyPlanList,
  MyPlanListItem
} from './styles'
import { IconCheckmark } from '@veneer/core/dist/esm/scripts/icons'
import { getDefaultText, getPagesText, getPrinterNameText } from './defaultText'
import useGetText from '../../hooks/useGetText'

interface PlanItemsProps {
  pagesPerMonth: string
  printerName: string
}

const PlanItems: React.FC<PlanItemsProps> = ({
  pagesPerMonth,
  printerName
}) => {
  const getText = useGetText('paper-on-paas')
  const myPlanItemIds = Array.from({ length: 6 }, (_, index) => index + 1)

  const getPlanItemText = (id) => {
    switch (id) {
      case 1:
        return getText(`myPlan-listItem${id}`, {
          pagesPerMonth,
          defaultText: getPagesText(pagesPerMonth)
        })
      case 2:
        return getText(`myPlan-listItem${id}`, {
          printerName,
          defaultText: getPrinterNameText(printerName)
        })
      default:
        return getText(`myPlan-listItem${id}`, {
          default: getDefaultText(`myPlan-listItem${id}`)
        })
    }
  }

  return (
    <MyPlanIncludes>
      <MyPlanTitle>
        {getText('myPlan-title', {
          default: getDefaultText('myPlan-title')
        })}
        <MyPlanUnderline />
      </MyPlanTitle>

      <MyPlanList>
        {myPlanItemIds.map((id) => (
          <MyPlanListItem key={id}>
            <IconCheckmark color="royalBlue7" filled size={24} />
            <p>{getPlanItemText(id)}</p>
          </MyPlanListItem>
        ))}
      </MyPlanList>
    </MyPlanIncludes>
  )
}

export default PlanItems
