import { DeviceConfigurationsValue } from '../../static/consts'

const passwordProtectionStatusStrings = (i18n, deviceConfiguration) => {
  return {
    titleGood: i18n.t(`configurations.${deviceConfiguration}.titleGood`),
    titleHPXGood: i18n.t(`configurations.${deviceConfiguration}.titleHPXGood`),
    descriptionGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionGood`
    ),
    descriptionHPXGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionHPXGood`
    ),
    titleBad: i18n.t(`configurations.${deviceConfiguration}.titleBad`),
    titleHPXBad: i18n.t(`configurations.${deviceConfiguration}.titleHPXBad`),
    descriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionBad`
    ),
    descriptionHPXBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionHPXBad`
    ),
    titleUnknown: i18n.t(`configurations.${deviceConfiguration}.titleUnknown`),
    descriptionUnknown: i18n.t(
      `configurations.${deviceConfiguration}.descriptionUnknown`
    ),
    title2: i18n.t(`configurations.${deviceConfiguration}.title2`),
    toast: i18n.t(`configurations.${deviceConfiguration}.toast`),
    errorToastText: i18n.t(
      `configurations.${deviceConfiguration}.errorToastText`
    ),
    errorToastSub: i18n.t(
      `configurations.${deviceConfiguration}.errorToastSub`
    ),
    recommendation: i18n.t(
      `configurations.${deviceConfiguration}.recommendation`
    ),
    recommendation2: i18n.t(
      `configurations.${deviceConfiguration}.recommendation2`
    ),
    passwordField: i18n.t(
      `configurations.${deviceConfiguration}.passwordField`
    ),
    passwordHPXField: i18n.t(
      `configurations.${deviceConfiguration}.passwordHPXField`
    ),
    confirmationPasswordField: i18n.t(
      `configurations.${deviceConfiguration}.confirmationPasswordField`
    ),
    confirmationHPXPasswordField: i18n.t(
      `configurations.${deviceConfiguration}.confirmationHPXPasswordField`
    ),
    requirementError: i18n.t(
      `configurations.${deviceConfiguration}.requirementError`
    ),
    differentPasswordsError: i18n.t(
      `configurations.${deviceConfiguration}.differentPasswordsError`
    ),
    success: i18n.t(`configurations.${deviceConfiguration}.success`)
  }
}

export const stringsForState = (strings, configuration, isHPX) => {
  let formattedStrings = { ...strings }

  switch (configuration.value) {
    case DeviceConfigurationsValue.trueValue:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX ? strings.titleGood : strings.titleHPXGood,
        description: !isHPX
          ? strings.descriptionGood
          : strings.descriptionHPXGood
      }
      break
    case DeviceConfigurationsValue.falseValue:
      formattedStrings = {
        ...formattedStrings,
        title: !isHPX ? strings.titleBad : strings.titleHPXBad,
        description: !isHPX ? strings.descriptionBad : strings.descriptionHPXBad
      }
      break
    case DeviceConfigurationsValue.unknownValue:
    default:
      formattedStrings = {
        ...formattedStrings,
        title: strings.titleUnknown,
        description: strings.descriptionUnknown
      }
      break
  }

  return formattedStrings
}

export default passwordProtectionStatusStrings
