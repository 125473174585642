import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { color, layout, typography } = tokens

export const Banner = styled.div`
  display: flex;
  border-radius: ${layout.cornerRadius3};
  position: relative;
  font-family: ${tokens.typography.family0};
  box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
  @media (max-width: ${layout.smMin}) {
    flex-direction: column;
  }
`

export const NewTag = styled.p`
  position: absolute;
  top: 32px;
  border-radius: 0 ${layout.cornerRadius2} ${layout.cornerRadius2} 0;
  background-color: black;
  font-family: ${typography.family0};
  color: ${color.white};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  padding: ${layout.size2} ${layout.size3};
`

export const BannerImage = styled.div`
  width: 50%;
  && img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${layout.smMin}) {
    width: 100%;
  }
`

export const BannerContent = styled.div`
  padding: ${layout.size6} 40px;
  flex: 1;
`

export const Title = styled.p`
  color: ${tokens.color.gray12};
  font-size: ${typography.size5};
  line-height: ${typography.lineHeight5};
`

export const Description = styled.p`
  color: ${color.gray9};
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  margin: ${layout.size4} 0;
`

export const FeatureBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${layout.size2};
  color: ${color.gray9};
  margin-left: ${layout.size2};
`

export const FeatureText = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
`

export const ButtonBlock = styled.div`
  && > button {
    width: 100%;
  }
  margin-top: ${layout.size6};
`
