import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Mobile from './Mobile'
import Web from './Web'
import AuthContext from '../../contexts/AuthContext'
import withConfigWifiNetwork from '../../hocs/withConfigWifiNetwork'

const ConnectivityStatus = ({ componentProps }) => {
  const { isMobileSize } = useContext(AuthContext)

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default withConfigWifiNetwork(ConnectivityStatus)

ConnectivityStatus.propTypes = {
  componentProps: PropTypes.object.isRequired
}
