import Select from '@veneer/core/dist/scripts/select'
import Table from '@veneer/core/dist/scripts/table'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const breakPoints = {
  xl: '(min-width: 1738px)',
  lg: '(min-width: 1008px)',
  md: '(min-width: 1007px)',
  sm: '(min-width: 640px)',
  maxXl: '(max-width: 1738px)',
  maxLg: '(max-width: 1008px)',
  maxMd: '(max-width: 1007px)',
  maxSm: '(max-width: 640px)'
}

export const StyledBaseContainer = styled.div`
  margin: ${tokens.typography.size9};
  font-family: ${tokens.typography.family0};
`

export const StyledParagraph = styled.p`
  margin-bottom: ${tokens.typography.size9};
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  font-weight: 400;
  color: ${tokens.color.gray12};
`

export const StyledTitle = styled.h1`
  font-family: ${tokens.typography.family0};
  margin-bottom: ${tokens.layout.size2};
  font-weight: 400;
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight7};
  color: ${tokens.color.gray12};
`

export const StyledTable = styled(Table)`
  margin-bottom: 30px;
  > div:first-child {
    box-shadow: 0 1px 4px 1px #21212133;
  }
  > div {
    border-radius: ${tokens.layout.size3};
    border-color: ${tokens.color.gray2};
  }
  th {
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.lineHeight1};
    padding: 10px ${tokens.layout.size2};
  }
  th#description {
    pointer-events: none;
  }
  th#date {
    svg {
      margin-left: 150px;
    }
  }

  @media ${breakPoints.maxMd} @media ${breakPoints.maxLg} {
    th#date {
      svg {
        margin-left: 125px;
      }
    }
  }
  @media only screen and (min-device-width: 744px) and (max-device-width: 1366px) {
    th#date {
      svg {
        margin-left: 90px;
      }
    }
  }
  @media ${breakPoints.maxSm} {
    th#date {
      svg {
        margin-left: 40px;
      }
    }
  }
  td {
    line-height: ${tokens.typography.lineHeight3};
    padding: ${tokens.layout.size3} ${tokens.layout.size2};
  }
  & > div > div > div {
    flex: 1;
  }
  tbody > tr > td > div > div > svg {
    fill: ${tokens.color.hpBlue7};
  }
  tbody>tr: nth-of-type(2n+1) {
    background-color: #e0e0e0;
  }
  tbody>tr: nth-of-type(2n) {
    background-color: ${tokens.color.white};
  }
  thead > tr {
    background-color: ${tokens.color.white};
  }
  nav > ul > li > button:not(:disabled, .next, .previous)[aria-current='true'] {
    background-color: ${tokens.color.hpBlue7};
  }
`
export const StyledInlineNotificationContainer = styled.div`
  > div {
    margin-bottom: 40px;
    border-radius: 12px;
    padding: 12px 8px 12px 12px;
    box-shadow: 0 1px 4px 1px rgb(33, 33, 33, 0.2);
  }
  span {
    font-weight: 700;
  }
`
export const StyledActionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.layout.size8};
  justify-content: space-between;
  margin-bottom: ${tokens.typography.size9};
  width: 100%;
  @media ${breakPoints.sm} {
    flex-direction: row;
  }
`

export const StyledSelect = styled(Select)`
  max-width: 272px;
  height: ${tokens.typography.size8};
  & > div {
    height: ${tokens.typography.size8};
    border-radius: ${tokens.layout.size2};
    border-color: ${tokens.color.gray7};
  }

  & > div > div {
    line-height: ${tokens.typography.lineHeight2};
  }
  span {
    color: ${tokens.color.gray9};
  }
  svg {
    fill: ${tokens.color.gray7} !important;
  }
  @media ${breakPoints.maxSm} {
    max-width: 100%;
  }
`
