import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledIconContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    display: flex;
    font-weight: 400;
    padding: 0 0 10px 0;

    img {
      width: 32px;
    }
  }
`

export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: #737373;
    font-size: ${tokens.typography.size5};
    line-height: ${tokens.typography.lineHeight5};
  }
`

export const StyledMainTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size5};
    line-height: 32px;
    font-weight: 400;
    padding: 12px 24px 0 0;
    margin: 12px 0 8px 0;
  }
`
export const StyledNoAddressMainMsg = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size3};
    line-height: 24px;
    font-weight: 400;
    padding: 2px 12px 0 0;
    margin: 2px 0 12px 0;
  }
`

export const StyledTextContainer = styled.p`
  -webkit-text-size-adjust: 100%;
  font-size: ${tokens.typography.size2} !important;
  font-weight: 400;
  font-family: ${tokens.typography.family0};
  color: ${tokens.color.gray12};
  line-height: ${tokens.typography.lineHeight3};
  margin-top: ${tokens.layout.size1};
`

export const StyledButtonContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-weight: 400;
    padding: 12px 0 10px 0;
  }
`

export const ShippingSectionContainer = styled.div`
  font-family: ${tokens.typography.family0};
  color: ${tokens.color.gray9};
`

export const ModalContentContainer = styled.div`
  & .shipping-content .shipping-fields {
    width: auto;
  }

  && {
    @media only screen and (min-width: 500px) {
      div[role='dialog'] {
        width: 90%;
      }
    }
    @media only screen and (min-width: 1000px) {
      div[role='dialog'] {
        width: 900px;
      }
    }
  }
`

export const ShippingAddressContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.layout.size4};
    margin: ${tokens.typography.size9} 0;
    align-items: stretch;
  }
`
export const ShippingAddressItemContainer = styled.div`
  && {
    width: calc(50% - 16px);
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }

    > div {
      border-radius: ${tokens.layout.cornerRadius4};
    }
  }
`
