import React, { useCallback, useEffect, useMemo } from 'react'
import IconCheckmark from '@veneer/core/dist/esm/scripts/icons/icon_checkmark'
import Tooltip from '@veneer/core/dist/esm/scripts/tooltip'
import {
  SubscriptionStateEnum,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import {
  Container,
  CardPill,
  IconContainer,
  SubscribeBtn,
  Divider,
  Perk
} from './InkPlanCard.styles'
import Icon from './Icon'
import { InkOffer } from '../../types/instantink'
import { toCurrency } from '../../utils/currency'
import { StyledTitle } from '../Page/Page.styles'
import { useAnalytics } from '@monetization/hpaip-ui-shared-components'

interface Props extends InkOffer {
  enable: boolean
  current: boolean
  recommended: boolean
  disabled: boolean
  calculatedPrice: string
  tooltip?: string
  onSubscribe: (sku: string) => void
  printerData: any
  printerState?: string
  t?: any
}

export const InkPlanCard = ({
  sku,
  pages,
  rollover,
  overage,
  onSubscribe,
  disabled,
  current,
  recommended,
  tooltip,
  calculatedPrice,
  printerData,
  printerState,
  t
}: Props) => {
  const { publishEvent, events } = useAnalytics()
  const { activePaperSubscription } = printerData
  const flags = useFlags()
  const handleSubscribeBtn = useCallback(() => {
    if (disabled) return
    if (onSubscribe) onSubscribe(sku)
    publishEvent(
      events.ClickSelectPlan({
        controlDetail: pages.toString()
      })
    )
  }, [disabled, onSubscribe, sku])

  useEffect(() => {
    if (current || recommended) {
      const event = {
        controlDetail: pages.toString()
      }
      publishEvent(
        current
          ? events.DisplayedCurrentPlan(event)
          : events.DisplayedRecommended(event)
      )
    }
  }, [printerData, current, recommended])

  const withTooltip = useCallback(
    (content: string, children: React.ReactNode) => {
      if (!content) return children
      return (
        <Tooltip
          arrow
          content={tooltip}
          placement="top"
          data-testid="InkPlanCardBtnTooltip"
        >
          {/* for some reason the Tooltip only work with this wrapping div around the Button */}
          {/* more info: https://pages.github.azc.ext.hp.com/veneer/design-system/storybook/3.65.0/?path=%2Fstory%2Fcomponents-tooltip-test-cases--wrapper-element-in-tooltip */}
          <div style={{ display: 'inline-block' }}>{children}</div>
        </Tooltip>
      )
    },
    [tooltip]
  )

  const cardPill = useMemo(() => {
    if (current || recommended) {
      return (
        <CardPill $recommended={recommended} $current={current}>
          {current
            ? t('update-plan.inkPlanCard.labels.currentPlan', {
                defaultValue: 'Current plan'
              })
            : t('update-plan.inkPlanCard.labels.recommended', {
                defaultValue: 'Recommended'
              })}
        </CardPill>
      )
    }

    if (
      !current &&
      disabled &&
      !(
        printerState.toLowerCase() === SubscriptionStateEnum.PENDING ||
        printerState.toLowerCase() === SubscriptionStateEnum.CANCELING ||
        printerState.toLowerCase() === 'suspended'
      )
    ) {
      return (
        <CardPill
          $recommended={recommended}
          $current={current}
          $disabled={disabled}
        >
          {t('update-plan.inkPlanCard.labels.nextPlan', {
            defaultValue: 'Next plan'
          })}
        </CardPill>
      )
    }

    return null
  }, [current, recommended, disabled, printerState, t])

  const cardContent = useMemo(
    () => (
      <>
        {cardPill}
        <IconContainer>
          <Icon />
        </IconContainer>
        <StyledTitle>
          {t('update-plan.inkPlanCard.pagesPerMonth', {
            pages,
            defaultValue: '{{pages}} pages '
          })}
          <span>
            {t('update-plan.inkPlanCard.monthText', {
              defaultValue: '/ month'
            })}
          </span>
        </StyledTitle>
        {withTooltip(
          tooltip,
          <SubscribeBtn
            appearance="secondary"
            small
            onClick={handleSubscribeBtn}
            id={`${pages}-pages-btn`}
            disabled={current ? 'true' : disabled}
          >
            {calculatedPrice}
          </SubscribeBtn>
        )}
        <Divider />
        <Perk>
          <IconCheckmark />
          {t('update-plan.inkPlanCard.rolloverInfo', {
            pages: rollover || 0,
            defaultValue: 'Rollover up to {{pages}} pages'
          })}
        </Perk>
        <Perk>
          <IconCheckmark />
          {t('update-plan.inkPlanCard.additionalPagesInfo', {
            pages: overage.size,
            price: toCurrency(overage.price),
            defaultValue: 'Additional {{pages}} pages for {{price}}'
          })}
        </Perk>
        {flags?.enableManagePaperPostEnrollment && activePaperSubscription ? (
          <Perk>
            <IconCheckmark />
            {t('update-plan.inkPlanCard.includesInkPaper', {
              defaultValue: 'Includes ink and paper'
            })}
          </Perk>
        ) : (
          <></>
        )}
      </>
    ),
    [
      cardPill,
      pages,
      current,
      disabled,
      tooltip,
      calculatedPrice,
      handleSubscribeBtn,
      t,
      rollover,
      overage,
      flags?.enableManagePaperPostEnrollment,
      activePaperSubscription
    ]
  )

  return (
    <Container
      $current={current}
      $recommended={recommended}
      $disabled={
        disabled &&
        !(
          printerState.toLowerCase() === SubscriptionStateEnum.PENDING ||
          printerState.toLowerCase() === SubscriptionStateEnum.CANCELING
        )
      }
      data-testid="InkPlanCard"
      content={cardContent}
      appearance="dropShadow"
    />
  )
}
