import Button from '@veneer/core/dist/esm/scripts/button'
import ButtonGroup from '@veneer/core/dist/esm/scripts/button_group'
import Checkbox from '@veneer/core/dist/esm/scripts/checkbox'
import React, { useEffect, useState, useCallback } from 'react'
import { InkOffer } from '../../types/instantink'
import { toCurrency } from '../../utils/currency'
import { Text } from './ChangePlanDialog.styles'
import { ErrorMessage } from './ErrorMessage'
import { StyledModal, FooterCard } from './styles'
import {
  CdmEvent,
  DisplayObserver,
  useAnalytics
} from '@monetization/hpaip-ui-shared-components'

export type Props = {
  show: boolean
  showError: boolean
  currentOffer: InkOffer
  chosenOffer: InkOffer
  currentBillingCycleEndDate: string
  nextBillingCycleStartDate: string
  isDowngrade?: boolean
  onClose?: () => void
  onConfirm?: () => void
  t?: any
}

export const ChangePlanDialog = ({
  show,
  showError,
  onClose,
  onConfirm,
  isDowngrade = false,
  currentBillingCycleEndDate,
  nextBillingCycleStartDate,
  currentOffer,
  chosenOffer,
  t
}: Props) => {
  const { publishEvent, events } = useAnalytics()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [loading, setLoading] = useState(false)
  const getDialogTitle = useCallback(() => {
    if (showError) {
      return t(
        'update-plan.modals.changePlanDialog.error.title',
        'Update HP All-In Plan error'
      )
    }

    if (isDowngrade) {
      return t(
        'update-plan.modals.changePlanDialog.downgrade.title',
        'Downgrade HP All-In Print Plan'
      )
    }

    return t(
      'update-plan.modals.changePlanDialog.upgrade.title',
      'Upgrade HP All-In Print Plan'
    )
  }, [isDowngrade, showError, t])

  const handleOnClose = (CdmEvent: CdmEvent) => {
    if (loading) return
    setTermsAccepted(false)
    setLoading(false)

    publishEvent(CdmEvent)

    onClose?.()
  }

  const handleOnCloseConfirm = (CdmEvent: CdmEvent) => {
    setLoading(true)

    publishEvent(CdmEvent)

    onConfirm?.()
  }

  useEffect(() => {
    if (!show) {
      setTermsAccepted(false)
      setLoading(false)
    }
  }, [show])

  if (!currentOffer || !chosenOffer || !nextBillingCycleStartDate) return null

  return (
    <StyledModal
      align="start"
      closeButton
      id="change-plan-dialog"
      show={show}
      onClose={() =>
        handleOnClose(
          showError
            ? events.ClickChangePlanErrorDismiss()
            : events.ClickCloseButton({
                controlDetail: chosenOffer.pages.toString()
              })
        )
      }
      footer={
        showError ? null : (
          <FooterCard>
            <ButtonGroup>
              <Button
                appearance="secondary"
                onClick={() =>
                  handleOnClose(
                    events.ClickCancelButton({
                      controlDetail: chosenOffer.pages.toString()
                    })
                  )
                }
                id="cancel-btn"
              >
                {t(
                  'update-plan.modals.changePlanDialog.upgrade.cancelButton',
                  'Cancel'
                )}
              </Button>
              <Button
                disabled={!termsAccepted}
                loading={loading}
                data-loading={loading}
                onClick={() =>
                  handleOnCloseConfirm(
                    events.ClickChangePlan({
                      controlDetail: currentOffer.pages.toString()
                    })
                  )
                }
                id="accept-terms-btn"
              >
                {isDowngrade
                  ? t(
                      'update-plan.modals.changePlanDialog.downgrade.confirmButton',
                      'Downgrade plan'
                    )
                  : t(
                      'update-plan.modals.changePlanDialog.upgrade.confirmButton',
                      'Upgrade plan'
                    )}
              </Button>
            </ButtonGroup>
          </FooterCard>
        )
      }
      title={getDialogTitle()}
    >
      {showError ? (
        <ErrorMessage t={t} />
      ) : (
        <>
          <DisplayObserver eventProps={events.ModalWindowDisplayed()}>
            {isDowngrade && (
              <>
                <Text>
                  {t(
                    'update-plan.modals.changePlanDialog.downgrade.descriptionLine1',
                    {
                      price: toCurrency(
                        Number(
                          (
                            currentOffer.price + currentOffer?.leasePrice
                          ).toFixed(2)
                        )
                      ),
                      date: currentBillingCycleEndDate,
                      defaultValue:
                        'This plan downgrade is not effective immediately. You will see one more charge of {{price}} on {{date}} before the change takes effect in the next billing cycle.'
                    }
                  )}
                </Text>

                <Text>
                  {t(
                    'update-plan.modals.changePlanDialog.downgrade.descriptionLine2',
                    {
                      page: chosenOffer.rollover,
                      date: nextBillingCycleStartDate,
                      defaultValue:
                        'The rollover maximum for this plan is less than your existing plan. You will only have {{page}} rollover pages when this downgrade takes effect on {{date}}.'
                    }
                  )}
                </Text>
              </>
            )}
            {!isDowngrade && (
              <Text>
                {t('update-plan.modals.changePlanDialog.upgrade.description', {
                  price: toCurrency(
                    Number(
                      (currentOffer.price + currentOffer?.leasePrice).toFixed(2)
                    )
                  ),
                  date: currentBillingCycleEndDate,
                  defaultValue:
                    'This upgrade won’t take effect immediately. You will see one more charge of {{price}} on {{date}} before the change takes effect in the next billing cycle.'
                })}
              </Text>
            )}
            <Checkbox
              id="ChangePlanDialogTermsCheckbox"
              data-testid="ChangePlanDialogTermsCheckbox"
              label={t(
                'update-plan.modals.changePlanDialog.termsCheckbox',
                'Confirm'
              )}
              checked={termsAccepted}
              onChange={(value) =>
                setTermsAccepted(value.currentTarget.checked)
              }
            />
          </DisplayObserver>
        </>
      )}
    </StyledModal>
  )
}
