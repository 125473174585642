import { ISessionService } from '../../../services/session';
import type { ITenantHandlerService } from '../../../services/tenantHandler';
import bindAllMethods from '../../../utils/bindAllMethods';
import { getServices } from '../../../infra/commonInitializer';
import * as T from './types';

class SessionInterfaceV1 {
  private _sessionService: ISessionService;
  private _tenantHandler: ITenantHandlerService;

  constructor() {
    const services = getServices();
    this._sessionService = services.sessionService;
    this._tenantHandler = services.tenantHandlerService;
    bindAllMethods(this);
  }

  public getInterface(): T.SessionInterfaceV1Type {
    return {
      logout: this._sessionService.logout,
      refreshToken: this._refreshToken,
      isLoggedIn: this._sessionService.isLoggedIn,
      getIdToken: this._sessionService.getIdToken,
      getProviderList: this._sessionService.getProviderList,
      generateAuthenticationUrl: this._sessionService.generateAuthenticationUrl,
      signIn: this._sessionService.signIn
    };
  }

  private _refreshToken(): Promise<void> {
    const tenantsIdMap = this._tenantHandler.getTenantIdsMap();
    return this._sessionService.refresh({ tenantsIdMap });
  }
}

export default SessionInterfaceV1;
