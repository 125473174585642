import React, { useContext, useState, useEffect } from 'react'
// import '../../css/SmartSecurityStyles.css';
import { List, ListItem } from '@veneer/core'
// import { List } from '@veneer/core'
import { ProgressIndicator } from '@veneer/core'
import PrinterStatus from './PrinterStatus/index'
import MonitorSecuritySettings from './MonitorSecuritySettings/index'
import ProtectionStatus from './ProtectionStatus/index'
// import SafetyStatus from './SafetyStatus/index'
import PasswordProtectionStatus from './PasswordProtectionStatus/index'
import ConnectivityStatus from './connectivity/index'
import FailToLoad from './FailToLoad'
import AssessmentContext from '../contexts/AssessmentContext'
import AuthContext from '../contexts/AuthContext'
import { withAssessment } from '../hocs/withAssessment'
import UpdateInProgressCard from './UpdateInProgressCard'
import AssessmentHooksContext from '../contexts/AssessmentHooksContext'
import NoPrinterPlanDevice from './NoPrinterPlanDevice/index'
import TenantDevice from './TenantDevice/index'
import { withDropShadow } from '../hocs/withDropShadow'

const PrinterInfo = ({ printer }) => {
  const { i18n, isMobileSize, isHPX } = useContext(AuthContext)
  const {
    requestAssessment,
    hasLoadingError,
    errorMessage,
    showButton,
    loading,
    hasCallRemediation
  } = useContext(AssessmentContext)
  const {
    useRemediationHook,
    useRequestsHook,
    useSecurityAssessmentStatusHook
  } = useContext(AssessmentHooksContext)

  const { remediationState } = useRemediationHook
  const { isActive, isAssessmentInProgress } = useSecurityAssessmentStatusHook
  const { state } = useRequestsHook
  const showAssessment = printer.showAssementDetails(isActive)

  // const emptySides = {
  //   leftArea: <></>, // To stop console warning
  //   rightArea: <></> // To stop console warning}
  // }

  const loadingCard = withDropShadow(
    ListItem,
    isMobileSize
  )({
    props: {
      // ...emptySides,
      centerArea: (
        <div style={{ margin: 'auto' }}>
          <ProgressIndicator value={72} data-testid="ss-progress-indicator" />
        </div>
      )
    }
  })

  const [details, setDetails] = useState(loadingCard)
  console.log(details)

  const errorCard = withDropShadow(
    ListItem,
    isMobileSize
  )({
    props: {
      // ...emptySides,
      centerArea: (
        <FailToLoad
          message={errorMessage}
          btnLabel={i18n.t('errors.tryAgain')}
          btnAction={requestAssessment}
          showButton={showButton}
        />
      )
    }
  })

  const tenantCard = <TenantDevice showAssessment={showAssessment} />
  const regularCard = showAssessment ? (
    <>
      {!isHPX ? (
        <>
          <MonitorSecuritySettings />
          <ProtectionStatus />
          {/* <SafetyStatus /> */}
          <PasswordProtectionStatus />
        </>
      ) : (
        <>
          <PasswordProtectionStatus />
          <ConnectivityStatus />
          <ProtectionStatus />
          <MonitorSecuritySettings />
        </>
      )}
    </>
  ) : (
    <NoPrinterPlanDevice isBasicPrinter={printer.isBasicPrinter} />
  )

  const updateInProgressCard = <UpdateInProgressCard />

  useEffect(() => {
    const { isMonitoringInProgress } = state
    const isRemediationInfoRequired = remediationState === ''
    const printerInfoDetails = () => {
      if (hasLoadingError) return errorCard
      if (isMonitoringInProgress || isAssessmentInProgress)
        return updateInProgressCard
      if (
        loading ||
        (useSecurityAssessmentStatusHook.isRemediationInProgress &&
          isRemediationInfoRequired &&
          !hasCallRemediation)
      )
        return loadingCard
      if (printer.isTenantUser) {
        return tenantCard
      }

      return regularCard
    }
    setDetails(printerInfoDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMobileSize,
    loading,
    hasLoadingError,
    remediationState,
    isAssessmentInProgress,
    state,
    hasCallRemediation,
    useSecurityAssessmentStatusHook.isRemediationInProgress,
    errorMessage
  ])

  const content = () => {
    return (
      <List
        gutter={isHPX ? '' : isMobileSize ? '24px' : ''}
        scrollEndOffset={50}
        style={{
          boxShadow: 'rgba(33, 33, 33, 0.1) 0px 4px 12px'
          // borderRadius: '12px'
        }}
      >
        <PrinterStatus></PrinterStatus> {details}
      </List>
    )
  }

  return content()
}

export default withAssessment(PrinterInfo)
