export enum NotificationType {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  WARNING = 'warning',
  INFORMATIVE = 'informative',
  HELP = 'help',
  CUSTOM = 'custom'
}
export enum NotificationID {
  PRESHIPPED_AFTERCANCEL = 'PreshippedAftercancelNotification',
  OVERVIEW_PREARRIVAL = 'OverviewPrearrivalNotification',
  UPDATEPLAN_PREARRIVAL = 'UpdatePlanPrearrivalNotification',
  TRACKSHIPMENTS_PREARRIVAL = 'TrackshipmentsPrearrivalNotification',
  SUBSCRIPTION_PREARRIVAL = 'SubscriptionPrearrivalNotification',
  OVERVIEW_OFFLINE = 'OverviewOfflineNotification',
  SHIPMENTTRACKING_OFFLINE = 'ShipmentTrackingOfflineNotification',
  CANCELING_NEW = 'CancelingNewNotification',
  CANCELING_INITIATED = 'CancelingInitiatedNotification',
  CANCELING_PROCESSING = 'CancelingProcessingNotification',
  CANCELING_PROCESSING_AFTER_REMORSE = 'CancelingProcessingAfterRemorseNotification',
  CANCELING_ITEMS_RECEIVED_IN_REMORSE = 'CancelingItemsReceivedInRemorseNotification',
  CANCELING_ITEMS_RECEIVED_AFTER_REMORSE = 'CancelingItemsReceivedAfterRemorseNotification',
  CANCELING_LAST_DAY_TO_RETURN = 'CancelingLastDayToReturnNotification',
  CANCELING_RETURN_TODAY = 'CancelingReturnTodayNotification',
  CANCELED_COMPLETE_SUCCESSFUL_IN_REMORSE = 'CanceledCompleteSuccessfulInRemorseNotification',
  CANCELED_COMPLETE_SUCCESSFUL_AFTER_REMORSE = 'CanceledCompleteSuccessfulAfterRemorseNotification',
  CANCELED_COMPLETE_TIMEOUT_IN_REMORSE = 'CanceledCompleteTimeoutInRemorseNotification',
  CANCELED_COMPLETE_TIMEOUT_AFTER_REMORSE = 'CanceledCompleteTimeoutAfterRemorseNotification',
  CANCELED_COMPLETE_UNSUCCESSFUL_IN_REMORSE = 'CanceledCompleteUnsuccessfulInRemorseNotification',
  CANCELED_COMPLETE_UNSUCCESSFUL_AFTER_REMORSE = 'CanceledCompleteUnsuccessfulAfterRemorseNotification',
  CANCELED_TIMEOUT_IN_REMORSE = 'CanceledTimeoutInRemorseNotification',
  CANCELED_TIMEOUT_AFTER_REMORSE = 'CanceledTimeoutAfterRemorseNotification',
  CANCELED_CANCELLED = 'CanceledCancelledNotification',
  ABOUT_TO_EXPIRED = 'AboutToExpireNotification',
  EXPIRED = 'ExpiredNotification'
}
