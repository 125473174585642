import React from 'react'
import CreditCard from '../CreditCard'
import PayPal from '../PayPal'
import { PaymentMethodType } from '@/types/PaymentMethodType'

interface BillingAddressProps {
  expirationStatus: string
  billingInfo: any
  instantInkBillingAddress: any
}
const BillingAddress = (props: BillingAddressProps) => {
  const { expirationStatus, billingInfo, instantInkBillingAddress } = props

  const fullName = instantInkBillingAddress?.fullName
  return (
    <div>
      {billingInfo?.paymentType === PaymentMethodType.pay_pal ? (
        <PayPal userName={fullName} billingInfo={billingInfo} />
      ) : (
        <CreditCard
          userName={fullName}
          billingInfo={billingInfo}
          expirationStatus={expirationStatus}
        />
      )}
    </div>
  )
}

export default BillingAddress
