import useDataFetcher from '../useDataFetcher'
import useSubscriptionClient from '../../hooks/useSubscriptionClient'

const useSubscriptionInfo = () => {
  const subscriptionClient = useSubscriptionClient()
  return useDataFetcher(
    'subscriptionInfo',
    subscriptionClient?.getSubscriptionData
      ? subscriptionClient.getSubscriptionData.bind(subscriptionClient)
      : null
  )
}

export default useSubscriptionInfo
