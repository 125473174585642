import type * as T from './types';

export default async function getMockedSessionInterfaceNorthboundAPI(
  dependencies?: T.GetMockedSessionInterfaceNorthboundDependenciesType
): Promise<T.GetMockedSessionInterfaceNorthboundAPIReturnType> {
  if (!dependencies?.enable) return undefined;

  const isLoggedInValue = !!dependencies?.isLoggedIn;

  const result: T.GetMockedSessionInterfaceNorthboundAPIReturnType = {};

  result.v1 = {
    generateAuthenticationUrl: async () => '',
    getProviderList: async () => [],
    getIdToken: () => '',
    isLoggedIn: () => isLoggedInValue,
    logout: async () => undefined,
    refreshToken: async () => undefined,
    signIn: async () => undefined
  };

  return result;
}
