import { NotificationID, NotificationType } from '@/utils/common-utils'
import defaultLanguageJson from '@/assets/locale/en_US.json'

const getNotification = (
  title,
  description,
  notificationType,
  status,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleLink?: any
) => ({
  title: title,
  description,
  showCloseButton: false,
  notificationType: notificationType,
  notificationID: status,
  callbackFunction: handleLink
})

export const getAllNotification = (
  getText,
  daysRemainingToReturn,
  printerStopDateFormatted,
  billingDateFormatted,
  handleRescindCancel,
  latestReturnEventDate
) => {
  const getDaysRemainingToReturnText = (daysRemainingToReturn: string) =>
    `To avoid fees, please return the printer within ${daysRemainingToReturn} days`
  const getDaysReamingToReturnDescription = (
    printerStopDateFormatted: string
  ) =>
    `You have canceled your HP All-In Plan. To avoid cancellation fee charges, please return the HP All-In Plan printer by ${printerStopDateFormatted}, following the instructions we’ve sent you by email. (If you have already returned this printer, please check your tracking information for more details.)<br/>For assistance, visit <a target='_blank' rel='noopener noreferrer' href='https://www.hp.com/all-in-plan/printer-support'>24/7 Pro live support.</a>`
  const getPrinterStopDateText = (billingDateFormatted: string) =>
    `Printing services will stop on ${billingDateFormatted}. Please submit the printer to FedEx for return to HP. For assistance, visit <a target='_blank' href='https://www.hp.com/all-in-plan/printer-support?target=_blank'>24/7 Pro live support.</a><br/>Would you like to <span>resume your HP All-In Plan?</span>`

  const getPrinterLastDatetoReturnText = (latestReturnEventDate: string) =>
    `The printer was not returned by ${latestReturnEventDate}. A cancellation fee has been billed to your account.`

  return {
    deactivating: {
      new: getNotification(
        getText('deactivating.new.title', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.deactivating.new.title
        }),
        getText('deactivating.new.description', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.deactivating.new
              .description
        }),
        NotificationType.CUSTOM,
        NotificationID.CANCELING_NEW
      ),
      initiated: getNotification(
        getText('deactivating.initiated.title', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.deactivating.initiated
              .title
        }),
        getText('deactivating.initiated.description', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.deactivating.initiated
              .description
        }),
        NotificationType.CUSTOM,
        NotificationID.CANCELING_INITIATED
      ),
      processing: {
        inRemorsePeriod: {
          returnDaysRemaining: getNotification(
            getText(
              'deactivating.processing.inRemorsePeriod.returnDaysRemaining.title',
              {
                daysRemainingToReturn,
                defaultValue: getDaysRemainingToReturnText(
                  daysRemainingToReturn
                )
              }
            ),
            getText(
              'deactivating.processing.inRemorsePeriod.returnDaysRemaining.description',
              {
                printerStopDate: printerStopDateFormatted,
                defaultValue: getDaysReamingToReturnDescription(
                  printerStopDateFormatted
                )
              }
            ),
            NotificationType.NEGATIVE,
            NotificationID.CANCELING_PROCESSING
          ),
          returnToday: getNotification(
            getText(
              'deactivating.processing.inRemorsePeriod.returnToday.title',
              {
                defaultValue:
                  defaultLanguageJson.postCancelNotification.deactivating
                    .processing.inRemorsePeriod.returnToday.title
              }
            ),
            getText(
              'deactivating.processing.inRemorsePeriod.returnToday.description',
              {
                defaultValue:
                  defaultLanguageJson.postCancelNotification.deactivating
                    .processing.inRemorsePeriod.returnToday.description
              }
            ),
            NotificationType.NEGATIVE,
            NotificationID.CANCELING_RETURN_TODAY
          )
        },
        afterRemorsePeriod: getNotification(
          getText('deactivating.processing.afterRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.deactivating.processing
                .afterRemorsePeriod.title
          }),
          getText('deactivating.processing.afterRemorsePeriod.description', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.deactivating.processing
                .afterRemorsePeriod.description
          }),
          NotificationType.NEGATIVE,
          NotificationID.CANCELING_PROCESSING_AFTER_REMORSE
        )
      },
      itemReceived: {
        inRemorsePeriod: getNotification(
          getText('deactivating.itemReceived.inRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.deactivating
                .itemReceived.inRemorsePeriod.title
          }),
          getText('deactivating.itemReceived.inRemorsePeriod.description', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.deactivating
                .itemReceived.inRemorsePeriod.description
          }),
          NotificationType.CUSTOM,
          NotificationID.CANCELING_ITEMS_RECEIVED_IN_REMORSE
        ),
        afterRemorsePeriod: getNotification(
          getText('deactivating.itemReceived.afterRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.deactivating
                .itemReceived.afterRemorsePeriod.title
          }),
          '',
          NotificationType.CUSTOM,
          NotificationID.CANCELING_ITEMS_RECEIVED_AFTER_REMORSE
        )
      },
      printerStopDate: getNotification(
        getText('deactivating.printerStopDate.title', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.deactivating
              .printerStopDate.title
        }),
        getText('deactivating.printerStopDate.description', {
          billingDate: billingDateFormatted,
          printerStopDate: printerStopDateFormatted,
          defaultValue: getPrinterStopDateText(billingDateFormatted)
        }),
        NotificationType.CUSTOM,
        NotificationID.CANCELING_LAST_DAY_TO_RETURN,
        handleRescindCancel
      )
    },
    inactive: {
      completeSuccessful: {
        inRemorsePeriod: getNotification(
          getText('inactive.completeSuccessful.inRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive
                .completeSuccessful.inRemorsePeriod.title
          }),
          '',
          NotificationType.CUSTOM,
          NotificationID.CANCELED_COMPLETE_SUCCESSFUL_IN_REMORSE
        ),
        afterRemorsePeriod: getNotification(
          getText('inactive.completeSuccessful.afterRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive
                .completeSuccessful.afterRemorsePeriod.title
          }),
          '',
          NotificationType.CUSTOM,
          NotificationID.CANCELED_COMPLETE_SUCCESSFUL_AFTER_REMORSE
        )
      },

      completeUnsuccessful: {
        inRemorsePeriod: getNotification(
          getText('inactive.completeUnsuccessful.inRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive
                .completeUnsuccessful.inRemorsePeriod.title
          }),
          getText('inactive.completeUnsuccessful.inRemorsePeriod.description', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive
                .completeUnsuccessful.inRemorsePeriod.description
          }),
          NotificationType.NEGATIVE,
          NotificationID.CANCELED_COMPLETE_UNSUCCESSFUL_IN_REMORSE
        ),
        afterRemorsePeriod: getNotification(
          getText('inactive.completeUnsuccessful.afterRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive
                .completeUnsuccessful.afterRemorsePeriod.title
          }),
          getText(
            'inactive.completeUnsuccessful.afterRemorsePeriod.description',
            {
              defaultValue:
                defaultLanguageJson.postCancelNotification.inactive
                  .completeUnsuccessful.afterRemorsePeriod.description
            }
          ),
          NotificationType.CUSTOM,
          NotificationID.CANCELED_COMPLETE_UNSUCCESSFUL_AFTER_REMORSE
        )
      },
      timeout: {
        inRemorsePeriod: getNotification(
          getText('inactive.timeout.inRemorsePeriod.title', {
            printerStopDate: latestReturnEventDate,
            defaultValue: getPrinterLastDatetoReturnText(latestReturnEventDate)
          }),
          getText('inactive.timeout.inRemorsePeriod.description', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive.timeout
                .inRemorsePeriod.description
          }),
          NotificationType.NEGATIVE,
          NotificationID.CANCELED_TIMEOUT_IN_REMORSE
        ),
        afterRemorsePeriod: getNotification(
          getText('inactive.timeout.afterRemorsePeriod.title', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive.timeout
                .afterRemorsePeriod.title
          }),
          getText('inactive.timeout.afterRemorsePeriod.description', {
            defaultValue:
              defaultLanguageJson.postCancelNotification.inactive.timeout
                .afterRemorsePeriod.description
          }),
          NotificationType.CUSTOM,
          NotificationID.CANCELED_TIMEOUT_AFTER_REMORSE
        )
      },
      cancelled: getNotification(
        getText('inactive.cancelled.title', {
          defaultValue:
            defaultLanguageJson.postCancelNotification.inactive.cancelled.title
        }),
        '',
        NotificationType.CUSTOM,
        NotificationID.CANCELED_CANCELLED
      )
    }
  }
}
