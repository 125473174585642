import { ApolloProvider } from '@apollo/client'
import React, { useMemo } from 'react'
import { HistoryContext } from '../../contexts'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'
import InitialComponent from '../InitialComponent'
import useApolloClient from '../../hooks/useApolloClient'
import { getEnvironmentVariables, getStack } from '../../helpers'

const History = ({
  t,
  navigation,
  localization,
  authProvider,
  stack,
  analytics
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const graphqlClient = useApolloClient(
    authProvider,
    envVariables?.variables?.MAGENTO_URL
  )
  const contextValue = useMemo(
    () => ({
      t,
      authProvider,
      stack,
      analytics,
      localization,
      navigation
    }),
    [t, authProvider, stack, analytics, localization, navigation]
  )
  console.log(
    'initi1234',
    t,
    navigation,
    localization,
    authProvider,
    stack,
    analytics
  )

  return (
    <ApolloProvider client={graphqlClient}>
      <HistoryContext.Provider value={contextValue}>
        <InitialComponent />
      </HistoryContext.Provider>
    </ApolloProvider>
  )
}

export default History
