import {
  SubscriptionOptionsResponse,
  SubscriptionsClient
} from '@monetization/hpaip-notification-rules-react'
import { useState } from 'react'
import { getEnvironmentVariables, getStack } from 'src/helpers'
import sendOpenTelemetryEvent from 'src/utils/sendOpenTelemetryEvent'

type QueryResult<T> = {
  data: T
  loading: boolean
  called: boolean
  error: Error
}

type QueryExecFunction = (
  subscriptionId?: string,
  entityId?: string
) => Promise<void>

export type SubscriptionOptionsResultTuple = [
  QueryExecFunction,
  QueryResult<SubscriptionOptionsResponse>
]

export const useSubscriptionOptions = ({
  authProvider,
  stack
}): SubscriptionOptionsResultTuple => {
  const [loading, setLoading] = useState<boolean>(false)
  const [called, setCalled] = useState<boolean>(false)
  const [error, setError] = useState<Error>(null)
  const [data, setData] = useState<SubscriptionOptionsResponse>(null)
  const envVariables = getEnvironmentVariables(getStack(stack))
  const BL_ID = envVariables?.variables?.BL_ID

  /*
   * Make a request to MBLC subscription API to return available product options for Instant ink,
   * this information is used to filter intant ink products from Magento to provide Ink offers tailored for the
   * current user.
   *
   * @param {string} subscriptionId – the subscription identifier
   * @param {string} entityId – option parameter, this should be the entity id for instant ink (found in the subscription data)
   * to return only options for instant ink
   * @return {Promise<AxiosResponse<SubscriptionOptionsResponse>>}
   */
  const getOptions = async (
    subscriptionId?: string,
    entityId?: string
  ): Promise<void> => {
    setCalled(true)
    setLoading(true)
    try {
      const webClient = new SubscriptionsClient(authProvider, stack)

      const response = await webClient.getSubscriptionOptions(
        subscriptionId,
        BL_ID,
        entityId
      )

      setData(response.data)
    } catch (err) {
      setError(err)
      sendOpenTelemetryEvent(JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  return [getOptions, { data, loading, error, called }]
}
