import React from 'react'
import { ManagePaymentText } from './styles'

const ManagePaymentButton = ({ onClick, isDisabled, getText }: any) => {
  return (
    <ManagePaymentText
      onClick={!isDisabled ? onClick : () => null}
      isDisabled={isDisabled}
    >
      {getText('editPayment', {
        defaultValue: 'Manage your payment method'
      })}
    </ManagePaymentText>
  )
}

export default ManagePaymentButton
