import { useCallback } from 'react';
import {
  DeviceApiClient,
  DeviceApiV2Client,
  AccountMgtSvcClient,
  AccountMgtSvcClientV3,
  SecurityMgrClient
} from '@jarvis/web-stratus-client';
import {
  Entitlement,
  getDeviceHasPrintAsAService,
  getDeviceHasPrintOnTheGo,
  getDeviceHasSmartSecurity
} from 'src/utils/entitlements';
import { getFeatureFlag, getFeatureFlags } from 'src/utils/featureFlags';

export function useDeviceApiAsDeviceCache({ authProvider, stack }) {
  const accountId = window.Shell?.v1?.tenantHandlerInterface?.getTenantId();
  const userId = authProvider?.getDecodedUserStratusJWTPayload()?.wpid;

  const getSolutions = useCallback(
    async (accountId, deviceId, useGrantsInsteadOfEntitlements) => {
      let programInfo;
      if (accountId) {
        let SvcClient = AccountMgtSvcClient;
        if (useGrantsInsteadOfEntitlements) {
          SvcClient = AccountMgtSvcClientV3;
        }
        const clientAMS = new SvcClient(stack, authProvider);
        const responseDPI = await clientAMS.getDeviceProgramInfos(
          accountId,
          deviceId
        );
        programInfo = responseDPI?.data?.programInfo;
      }
      const solutions = [];
      const solutionsToCheck = [
        {
          serviceId: Entitlement.PrintOnTheGo,
          callback: getDeviceHasPrintOnTheGo
        },
        {
          serviceId: Entitlement.SmartSecurity,
          callback: getDeviceHasSmartSecurity
        },
        {
          serviceId: Entitlement.PrinterAsAService,
          callback: getDeviceHasPrintAsAService
        }
      ];
      for (let i = 0; i < solutionsToCheck.length; i++) {
        const solution = solutionsToCheck[i];
        const hasSolution = await solution.callback({
          programInfo,
          tenantId: accountId,
          deviceId,
          useGrantsInsteadOfEntitlements
        });
        if (hasSolution) {
          solutions.push(solution.serviceId);
        }
      }
      return solutions;
    },
    [authProvider, stack]
  );

  const deviceToDeviceCache = useCallback(
    async (deviceV2, useGrantsInsteadOfEntitlements) => {
      const isLegacy = ['gen1', 'gen2'].includes(deviceV2.platformIdentifier);
      const clientD = new DeviceApiClient(stack, authProvider);
      const clientSM = new SecurityMgrClient(stack, authProvider);
      const deviceId = deviceV2.deviceIdentity?.deviceId;
      const modelNo = deviceV2.deviceIdentity?.modelNumber;
      const modelP = new Promise((resolve) => {
        clientD
          .getModelDetails(modelNo)
          .then((responseMD) => resolve(responseMD.data))
          .catch((error) => {
            console.error(
              `=== Error ${error.message} Model ${modelNo} not found for ${deviceId}`
            );
            resolve(null);
          });
      });
      const statusP = new Promise((resolve) => {
        // Legacy devices have no status, save this API call
        if (isLegacy) {
          resolve(null);
        } else {
          clientD
            .getDeviceStatus(deviceId)
            .then((responseDS) =>
              resolve(responseDS.data.state.reported.cdmData)
            )
            .catch((error) => {
              console.error(
                `=== Error ${error.message} Status not found for ${deviceId}`
              );
              resolve(null);
            });
        }
      });
      const suppliesP = new Promise((resolve) => {
        // Legacy devices have no supplies, save this API call
        if (isLegacy) {
          resolve(null);
        } else {
          clientD
            .getDeviceSuppliesStatus(deviceId)
            .then((responseSS) =>
              resolve(responseSS.data.state.reported.cdmData)
            )
            .catch((error) => {
              console.error(
                `=== Error ${error.message} Supplies not found for ${deviceId}`
              );
              resolve(null);
            });
        }
      });
      const benefitsP = new Promise((resolve) => {
        getSolutions(accountId, deviceId, useGrantsInsteadOfEntitlements)
          .then((solutions) => resolve(solutions))
          .catch((error) => {
            console.error(
              `=== Error ${error.message} Benefits not found for ${deviceId}/${accountId}`
            );
            resolve([]);
          });
      });
      const securityP = new Promise((resolve) => {
        clientSM
          .getSecurityAssessmentStatus(deviceId)
          .then((responseSA) => resolve(responseSA.data))
          .catch((error) => {
            console.error(
              `=== Error ${error.message} Security info not found for ${deviceId}`
            );
            resolve(null);
          });
      });
      const [model, status, supplies, benefits, security] = await Promise.all([
        modelP,
        statusP,
        suppliesP,
        benefitsP,
        securityP
      ]);
      // Legacy devices have only connectivity status
      let otherStatus = null;
      if (!isLegacy) {
        otherStatus = {
          acceptingJobs: status?.printerIsAcceptingJobs === 'YES',
          printerStateSeverity: status?.printerStateSeverity,
          printerState: status?.printerState,
          printerStateReasons: status?.printerStateReasons
        };
      }
      const statusDC = {
        status: {
          connectionState:
            status?.connectivityState?.connectionState ||
            deviceV2.connectivityStatus?.connectionState,
          connectionStateLastUpdatedAt:
            status?.connectivityState?.lastSeenTime ||
            deviceV2.connectivityStatus?.lastSeenTime,
          ...otherStatus
        }
      };
      // Legacy devices have no supplies
      let suppliesDC = null;
      if (!isLegacy) {
        suppliesDC = {
          supplies: {
            consumables:
              model?.supplies?.map((supplyM) => {
                const supply = supplies?.supplyStates?.[supplyM?.colorCode];
                return {
                  supplyType: model?.supplyType, // DC not always provide this information consistently
                  colorCode: supplyM?.colorCode,
                  slotOrder: supplyM?.slot,
                  percentLifeDisplay: supply?.pct10LevelRemaining,
                  isRefilled: String(supply?.isRefill) === 'true',
                  isVaReman: String(supply?.isVaReman) === 'true',
                  isGenuineHP:
                    supply?.deviceAssessment?.toLowerCase() !== 'nothp',
                  levelState: supply?.levelState, // ok, low, veryLow, veryVeryLow, depleted, fulfillment, unknown
                  state: supply?.supplyState, // ok, inform, warning, error
                  consumablePlatform: supply?.consumablePlatform, // iicGen1, iicGen2, pwax, iph, cissIph, hpLaser, canonLaser, cissIic
                  stateReasons: supply?.stateReasons || [] // for now only dynamicIntegrityViolationError is considered
                };
              }) || []
          }
        };
      }
      const deviceDC = {
        deviceId: deviceV2.deviceIdentity?.deviceId,
        identity: {
          deviceUuid: deviceV2.deviceIdentity?.deviceUuid,
          serialNumber: deviceV2.deviceIdentity?.serialNumber,
          platformIdentifier: deviceV2.platformIdentifier,
          bizModel: deviceV2.bizModel,
          makeAndModel: {
            number: deviceV2.deviceIdentity?.modelNumber,
            name: model?.modelName || deviceV2.deviceIdentity?.modelName,
            series: model?.seriesName
          },
          supplyDelivery: model?.supplyDelivery || 'cartridge'
        },
        ...statusDC,
        solutions: benefits,
        solutionMetadata:
          security?.active &&
          benefits.find((benefit) => benefit === Entitlement.SmartSecurity)
            ? {
                g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo: {
                  state: security?.securityAssessmentState,
                  result: security?.assessmentResult
                }
              }
            : null,
        images:
          deviceV2.deviceImages?.map?.((image) => ({
            url: image
          })) || [],
        ownership: {
          accountId
        },
        ...suppliesDC
      };
      return deviceDC;
    },
    [stack, authProvider, accountId, getSolutions]
  );

  const devicesToDeviceCache = useCallback(
    async (devicesV2, useGrantsInsteadOfEntitlements) => {
      const promises = devicesV2.map((deviceV2) =>
        deviceToDeviceCache(deviceV2, useGrantsInsteadOfEntitlements)
      );
      const devices = await Promise.all(promises);
      return devices;
    },
    [deviceToDeviceCache]
  );

  const getDevice = useCallback(
    async (deviceId) => {
      const useGrantsInsteadOfEntitlements = await getFeatureFlag({
        key: 'useGrantsInsteadOfEntitlements'
      });
      const clientDv1 = new DeviceApiClient(stack, authProvider);
      const responseDv1 = await clientDv1.getDevice(deviceId);
      const deviceV1 = responseDv1?.data?.deviceDescription;
      const device = await deviceToDeviceCache(
        deviceV1,
        useGrantsInsteadOfEntitlements
      );
      return {
        data: device
      };
    },
    [authProvider, stack, deviceToDeviceCache]
  );

  const getDevices = useCallback(async () => {
    const {
      forceUserOnboardingBeforeListDevices,
      useGrantsInsteadOfEntitlements
    } = await getFeatureFlags([
      { key: 'forceUserOnboardingBeforeListDevices' },
      { key: 'useGrantsInsteadOfEntitlements' }
    ]);
    if (!window.JWeb.isNative && forceUserOnboardingBeforeListDevices) {
      try {
        await window.Shell?.v1?.userInterface?.onboarding?.onboardUser();
      } catch (error) {
        console.error('OnboardUser failed', error);
      }
    }
    const clientDv2 = new DeviceApiV2Client(stack, authProvider);
    let responseDv2;
    try {
      responseDv2 = await clientDv2.listDevices(accountId, userId);
    } catch (error) {
      if (error?.response?.status === 404) {
        responseDv2 = { data: { devices: [] } };
      } else {
        throw error;
      }
    }
    const devicesV2 = responseDv2?.data?.devices || [];
    const devices = await devicesToDeviceCache(
      devicesV2,
      useGrantsInsteadOfEntitlements
    );
    return {
      data: devices
    };
  }, [stack, authProvider, accountId, userId, devicesToDeviceCache]);

  return {
    getDevice,
    getDevices
  };
}
