import React from 'react'
// import '../../css/SmartSecurityStyles.css';
import PropTypes from 'prop-types'
import AuthContext from '../contexts/AuthContext'

const CardItemContent = ({
  isSubcontent,
  title,
  content,
  customCss,
  titleStyle,
  children
}) => {
  const strongStyle = {} as const
  const { isHPX } = React.useContext(AuthContext)
  return !isHPX ? (
    <>
      <div>
        {isSubcontent ? (
          <h5
            className="list-item__middle-section--primary subcontent"
            style={titleStyle}
            data-testid="ss-printer-subtitle"
          >
            <p style={strongStyle}>{title}</p>
          </h5>
        ) : (
          <h4
            className="list-item__middle-section--primary"
            style={titleStyle}
            data-testid="ss-printer-title"
          >
            <p style={{ ...strongStyle }}>{title}</p>
          </h4>
        )}

        <p
          className="list-item__middle-section--secondary line-break"
          style={customCss || {}}
          data-testid="ss-printer-content"
        >
          {content}
        </p>
      </div>
      <div>{children}</div>
    </>
  ) : (
    <>
      <div>
        {isSubcontent ? (
          <h5
            className="list-item__middle-section--primary subcontent"
            style={titleStyle}
            data-testid="ss-printer-subtitle"
          >
            <p
              style={{
                ...strongStyle,
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '20px'
              }}
            >
              {title}
            </p>
          </h5>
        ) : (
          <h4
            className="list-item__middle-section--primary"
            style={titleStyle}
            data-testid="ss-printer-title"
          >
            <p
              style={{
                ...strongStyle,
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '20px'
              }}
            >
              {title}
            </p>
          </h4>
        )}

        <p
          className="list-item__middle-section--secondary line-break"
          style={
            customCss || {
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px'
            }
          }
          data-testid="ss-printer-content"
        >
          {content}
        </p>
      </div>
      <div>{children}</div>
    </>
  )
}

export default CardItemContent

CardItemContent.propTypes = {
  isSubcontent: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.element,
  customCss: PropTypes.object,
  titleStyle: PropTypes.object,
  children: PropTypes.array
}

CardItemContent.defaultProps = {
  isSubcontent: false,
  title: '',
  content: <></>,
  customCss: undefined,
  titleStyle: undefined,
  children: []
}
