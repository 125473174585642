import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const EstimatedCard = styled.div``

export const EstimatedPriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${layout.size2};
`

export const EstimateBlockText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  color: ${color.gray12};
`

export const DateRangeText = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  color: ${color.gray9};
  margin-bottom: ${layout.size4};
`

export const Divider = styled.div`
  margin: ${layout.size5} 0;
  height: 1px;
  background-color: ${color.gray3};
`

export const TotalText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  color: ${color.gray12};
  font-weight: 700;
`

export const TaxText = styled.p`
  font-size: ${typography.size1};
  line-height: ${typography.lineHeight1};
  color: ${color.gray12};
  font-style: italic;
  margin-top: -12px;
  margin-bottom: ${layout.size6};
`

export const InvoiceBlock = styled.div<{
  onClick?: () => void
  children?: React.ReactNode
}>`
  display: flex;
  align-items: center;
  gap: ${layout.size2};
  cursor: pointer;
  width: fit-content;

  svg {
    color: ${color.hpBlue7};
  }
`

export const InvoiceText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  color: ${color.hpBlue7};
`
