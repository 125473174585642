import { sprintf } from 'sprintf-js'
import {
  DeviceConfigurationResult,
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
} from '../../static/consts'

const _numberOfExpandedItems = (result) => {
  switch (result) {
    case DeviceConfigurationResult.compliant:
    case DeviceConfigurationResult.nonCompliant:
      return ConstraintsDataTypes.length
    case DeviceConfigurationResult.complianceOff:
      return ConstraintsDataTypes.length - 1
    case DeviceConfigurationResult.unknown:
    default:
      return 0
  }
}

const protectionStatusStrings = (i18n, deviceConfiguration) => {
  return {
    titleGood: i18n.t(`configurations.${deviceConfiguration}.titleGood`),
    titleHPXGood: i18n.t(`configurations.${deviceConfiguration}.titleHPXGood`),
    descriptionGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionGood`
    ),
    descriptionHPXGood: i18n.t(
      `configurations.${deviceConfiguration}.descriptionHPXGood`
    ),
    titleBad: i18n.t(`configurations.${deviceConfiguration}.titleBad`),
    titleHPXBad: i18n.t(`configurations.${deviceConfiguration}.titleHPXBad`),
    descriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionBad`
    ),
    descriptionHPXBad: i18n.t(
      `configurations.${deviceConfiguration}.descriptionHPXBad`
    ),
    titleUnknown: i18n.t(`configurations.${deviceConfiguration}.titleUnknown`),
    descriptionUnknown: i18n.t(
      `configurations.${deviceConfiguration}.descriptionUnknown`
    ),
    toast: i18n.t(`configurations.${deviceConfiguration}.toast`),
    errorToastText: i18n.t(
      `configurations.${deviceConfiguration}.errorToastText`
    ),
    errorToastSub: i18n.t(
      `configurations.${deviceConfiguration}.errorToastSub`
    ),
    fwTitleBad: i18n.t(
      `configurations.${deviceConfiguration}.firmware.titleBad`
    ),
    fwDescriptionBad: i18n.t(
      `configurations.${deviceConfiguration}.firmware.descriptionBad`
    )
  }
}

export const stringsForState = (strings, result, isHPX) => {
  const formattedStrings = { ...strings }
  const formattedResult = result && result.toLowerCase()
  const numberOfItems = _numberOfExpandedItems(formattedResult)

  switch (formattedResult) {
    case DeviceConfigurationResult.compliant:
    case DeviceConfigurationResult.complianceOff:
      formattedStrings.title = !isHPX ? strings.titleGood : strings.titleHPXGood
      formattedStrings.description = !isHPX
        ? sprintf(strings.descriptionGood, numberOfItems)
        : sprintf(strings.descriptionHPXGood, numberOfItems)
      break
    case DeviceConfigurationResult.nonCompliant:
      formattedStrings.title = !isHPX ? strings.titleBad : strings.titleHPXBad
      formattedStrings.description = !isHPX
        ? sprintf(strings.descriptionBad, numberOfItems)
        : sprintf(strings.descriptionHPXGood, numberOfItems)
      break
    case DeviceConfigurationResult.unknown:
    default:
      formattedStrings.title = strings.titleUnknown
      formattedStrings.description = strings.descriptionUnknown
      break
  }

  return formattedStrings
}

export const getSecurityCheckStringsForProtectionStatus = (
  checkName,
  strings,
  isCompliant,
  isHPX
) => {
  let securityCheckStrings = strings.securityChecks[checkName]

  if (checkName === ConstraintsDataTypesKeys.network) {
    if (isCompliant) {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: !isHPX
          ? securityCheckStrings.titleGood
          : securityCheckStrings.titleHPXGood,
        description: !isHPX
          ? securityCheckStrings.descriptionGood
          : securityCheckStrings.descriptionHPXGood
      }
    } else {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: !isHPX
          ? securityCheckStrings.titleBad
          : securityCheckStrings.titleHPXBad,
        description: securityCheckStrings.descriptionBad
      }
    }
  }

  return securityCheckStrings
}
export const getSecurityCheckStringsForFlexProtectionStatus = (
  checkName,
  strings,
  isCompliant
) => {
  let securityCheckStrings = strings.securityChecks[checkName]

  if (checkName === ConstraintsDataTypesKeys.network) {
    if (isCompliant) {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleGood,
        description: securityCheckStrings.descriptionGood
      }
    } else {
      securityCheckStrings = {
        ...securityCheckStrings,
        title: securityCheckStrings.titleBad,
        description: securityCheckStrings.descriptionBad
      }
    }
  }
  if (checkName === ConstraintsDataTypesKeys.firmware) {
    securityCheckStrings = {
      ...securityCheckStrings,
      title: securityCheckStrings.title,
      description: securityCheckStrings.descriptionFlex
    }
  }
  return securityCheckStrings
}

export default protectionStatusStrings
