import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AdvancedScanCards,
  AdvancedScanCarousel
} from '../../components/AdvancedScan';
import {
  MobileProductivityCard,
  MobileProductivityCarousel
} from '../../components/MobileProductivity';
import { Help } from '../../components/Help';
import { ShortcutsCard, ShortcutsCarousel } from '../../components/Shortcuts';
import { AppStores } from '../../components/AppStores';
import { useFeatureFlag } from '../../utils/featureFlags';
import {
  CardsContainer,
  Container,
  ContentWrapper,
  HelpContainer,
  HelpContainerMobile,
  MobileCarouselContainer,
  StyledBanner
} from './styles';
import subscriptionMock from '../../mock/mock-subscription-statusFalse.json';
import { getJWebIsAvailable } from '../../utils/jweb';

const useMock = false;

const HpSmartAdvance = ({ subscriptionInfo }) => {
  const [showAppStores, setShowAppStores] = useState(false);
  const smartAdvAppStores = useFeatureFlag({
    key: 'smartAdvAppStores',
    defaultValue: false
  });
  const smartAdvShortcuts = useFeatureFlag({
    key: 'smartAdvShortcuts',
    defaultValue: false
  });
  const releaseVersionHpSmartAdvance = useFeatureFlag({
    key: 'releaseVersionHpSmartAdvance',
    defaultValue: 'published'
  });
  const subscriptionData = useMock ? subscriptionMock : subscriptionInfo || {};

  const showMobileProductivity =
    subscriptionData?.solutions?.printAnywhere ||
    subscriptionData?.solutions?.smartDashboard;

  useEffect(() => {
    (async function getShowAppStores() {
      const jwebIsAvailable = await getJWebIsAvailable();
      setShowAppStores(!jwebIsAvailable && smartAdvAppStores);
    })();
  }, [smartAdvAppStores]);

  return (
    <React.Fragment>
      <StyledBanner showAppStores={showAppStores} />
      <Container data-testid="hp-smart-advance-container">
        <CardsContainer>
          <AdvancedScanCards
            releaseFeatureFlag={releaseVersionHpSmartAdvance}
          />
          {smartAdvShortcuts && <ShortcutsCard />}
          {showMobileProductivity && (
            <MobileProductivityCard solutions={subscriptionData?.solutions} />
          )}
          {showAppStores && <AppStores />}
          <HelpContainer>
            <Help />
          </HelpContainer>
        </CardsContainer>
        <MobileCarouselContainer>
          <AdvancedScanCarousel
            releaseFeatureFlag={releaseVersionHpSmartAdvance}
          />
          {smartAdvShortcuts && <ShortcutsCarousel />}
          {showMobileProductivity && (
            <MobileProductivityCarousel
              solutions={subscriptionData?.solutions}
            />
          )}
          {showAppStores && (
            <ContentWrapper>
              <AppStores />
            </ContentWrapper>
          )}
        </MobileCarouselContainer>
        <HelpContainerMobile>
          <Help />
        </HelpContainerMobile>
      </Container>
    </React.Fragment>
  );
};

HpSmartAdvance.defaultProps = {
  subscriptionInfo: {}
};

HpSmartAdvance.propTypes = {
  subscriptionInfo: PropTypes.objectOf(PropTypes.shape)
};

export default memo(HpSmartAdvance);
