import { NotificationBar } from '@monetization/hpaip-ui-shared-components'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

import { Text } from '../DisclaimerText/styles'
import { Title } from '../InkPlanCard/InkPlanCard.styles'

const { size2: layoutSize2, size4, size5, size8 } = tokens.layout
const { size2, size3, family0, lineHeight3 } = tokens.typography
const { gray12, black, gray9 } = tokens.color

export const Container = styled.section`
  padding: 0px ${size4} ${size5} ${size4};

  @media (min-width: 500px) {
    padding: 0 ${size5};
  }

  @media (min-width: 641px) {
    padding: 0 ${size8};
  }
  @media (min-width: 1280px) {
    padding: 0 40px;
  }
`

export const CurrentPriceDescription = styled.p`
  font-size: ${size2};
  font-family: ${family0};
  margin-bottom: ${layoutSize2};
  color: ${gray9};
  line-height: ${lineHeight3};
`

export const Loader = styled.div`
  width: 100%;
  min-height: 613px;
  display: grid;
  place-items: center;
`

export const StyledNotificationBar = styled(NotificationBar)`
  white-space: break-spaces;
  margin-bottom: 1px;

  p + p {
    margin-top: ${size4};
  }
`

export const StyledPage = styled.div`
  @media only screen {
    max-width: 1480px;
  }
`
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    padding: ${size8} ${size4} ${layoutSize2} ${size4};
    @media (min-width: 500px) {
      padding-right: ${size5};
    }
    @media (min-width: 641px) {
      padding-right: ${size8};
    }
    @media (min-width: 1280px) {
      padding-top: 40px;
      padding-right: 40px;
      padding-left: 0px;
    }
  }
  h2 {
    color: ${gray12};
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
  }

  header {
    padding: ${size8} ${size4} ${layoutSize2} ${size4};
    min-height: 0px;
    text-align: left;
    margin: 0;
  }
  @media (min-width: 500px) {
    header {
      padding-left: ${size5};
      padding-right: ${size5};
    }
  }

  @media (min-width: 641px) {
    header {
      padding-left: ${size8};
      padding-right: ${size8};
    }
  }
  @media (min-width: 1280px) {
    header {
      padding-left: 40px;
      padding-top: 40px;
      padding-right: 0px;
    }
  }
`

export const StyledText = styled(Text)`
  color: ${black};
`
export const StyledTitle = styled(Title)`
  color: ${gray9};
  font-size: ${size3};
  line-height: ${lineHeight3};
  span {
    font-size: ${size2};
    line-height: ${lineHeight3};
  }
`
export const DropDownStyle = styled.p`
  float: right;
`

export const NotificationsContainer = styled.div`
  margin: ${size5};
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 641px) {
    margin: ${size5} ${size8};
  }
  @media (min-width: 1280px) {
    margin: ${size5} 40px;
  }
`
