import React from 'react'
// import '../../css/SmartSecurityStyles.css';

interface WarningMarkerTypes {
  isSecured: boolean
  customCss?: any
}
const WarningMarker = ({ isSecured, customCss }: WarningMarkerTypes) => {
  return isSecured ? null : (
    // <div className="status-bar" style={customCss || {}} />
    <div
      className="status-bar"
      style={
        customCss || {
          float: 'left',
          position: 'absolute',
          WebkitBoxAlign: 'center',
          alignItems: 'center',
          width: '4px',
          backgroundColor: '#e8701a',
          zIndex: 1,
          left: '0px',
          top: '0px',
          bottom: '0px'
        }
      }
    />
  )
}

export default WarningMarker
