import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../../../utils/device/device'

export const StyledErrorMessage = styled.div`
  color: #585858;
  font-size: 10px;
  line-height: ${tokens.typography.lineHeight2};
  display: flex;
  align-items: center;

  > div {
    margin-top: 10px;
  }

  svg {
    margin-right: 5px;
  }
`

export const StyledItem = styled.div`
  .fulfillment-status {
    display: flex;
    justify-content: space-between;
    .order-status {
      display: grid;
      font-size: 12px;
      line-height: 16px;
    }
    ${smartQueries.mobileXS(`
      flex-wrap: wrap;
      flex-direction: column;
      .order-status{
        display: contents;
      }
      .stepper-container {
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
      }
    `)}
  }

  .product-info {
    align-items: center;
    display: flex;
    line-height: ${tokens.typography.lineHeight1};
    margin-top: 2px;
    .product {
      display: flex;
    }
    ${smartQueries.mobileXS(`
      .product {
        margin-left: auto;
        margin-right: auto;
        display: flex;

        .product-image {
           height: 90px !important;
           width: 120px !important;
           margin-right: ${tokens.layout.size4}!important;
        }
        .product-desc {
           margin-top: ${tokens.layout.size3}!important;
        }
    `)}
  }

  .product-image {
    margin-right: ${tokens.layout.size4};
    width: 80px;

    img {
      max-width: 100%;
    }
  }

  .product-desc {
    color: ${tokens.color.gray12};
    font-family: ${tokens.typography.family0};

    > strong {
      letter-spacing: 0.02em;
      display: block;
      font-weight: 400;
      font-size: ${tokens.typography.size2};
      line-height: ${tokens.typography.lineHeight2};
    }

    > span {
      > strong {
        font-family: ${tokens.typography.family0};
        color: #737373;
        font-weight: normal;
      }
      display: block;
      font-size: ${tokens.typography.size0};
      color: ${tokens.color.gray9};
      margin-top: ${tokens.layout.size1};
    }
  }
  .product-name {
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight2};
  }

  .order-status {
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.lineHeight1};
    letter-spacing: 0.5px;
    a {
      color: #0278ab;
      text-decoration: none;
    }
    > span {
      > strong {
        color: #be1313;
      }
      color: ${tokens.color.gray9};
      display: block;
      margin-bottom: ${tokens.layout.size1};
      text-transform: capitalize;
    }
  }
`
