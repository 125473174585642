import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useRef } from 'react'
import { AuthProviderProps } from '../../types/shell'

const useApolloClient = (props: { authProvider?: AuthProviderProps }) => {
  const authProvider = props?.authProvider
    ? props.authProvider
    : window?.Shell?.v1?.authProvider
  const httpLink = createHttpLink({
    uri: process.env.MAGENTO_URL
  })
  const authLink: ApolloLink | undefined = setContext(
    async (_, { headers }) => {
      const token = await authProvider.getAccessToken()
      if (!token) return undefined

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    }
  )

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  const apolloClientRef = useRef<ApolloClient<NormalizedCacheObject>>(client)

  return apolloClientRef.current
}

export default useApolloClient
