import { PaymentMethodType } from '../types/PaymentMethodType'
import { instantInkState, IISubsEntityState } from '../types/instantInkStates'

export const determineBillingVisibility = (
  billingInfo: any,
  instantInkSubscriptions: any[]
) => {
  const showIICreditCard =
    billingInfo?.paymentType === PaymentMethodType.credit_card &&
    instantInkSubscriptions?.length > 0

  const showPayPal =
    billingInfo?.paymentType === PaymentMethodType.pay_pal &&
    instantInkSubscriptions?.length > 0

  const showDirectDebit =
    billingInfo?.paymentType === PaymentMethodType.direct_debit &&
    instantInkSubscriptions?.length > 0

  const showBilling = showIICreditCard || showPayPal
  const showIIBilling = showIICreditCard || showPayPal || showDirectDebit

  const isAddPayment =
    !showIIBilling &&
    instantInkSubscriptions?.find(
      ({ state }) =>
        state === IISubsEntityState.ACTIVE || instantInkState.includes(state)
    )

  return {
    showIICreditCard,
    showPayPal,
    showDirectDebit,
    showBilling,
    showIIBilling,
    isAddPayment
  }
}
