import {
  AnalyticsProvider,
  parseAuxParams,
  ShellAnalytics
} from '@monetization/hpaip-ui-shared-components'
import React, { useMemo } from 'react'
import analyticsEvents from '../../utils/analyticsEvents'

interface Props {
  analytics: ShellAnalytics
  printerData: any
}

const Analytics: React.FC<Props> = ({ children, analytics, printerData }) => {
  const auxParams = useMemo(() => {
    if (!printerData?.printer || !printerData?.root) return

    const params = parseAuxParams({
      subscriptionId: printerData?.root?.subscriptionId,
      subscriptionStartDate: printerData?.root?.createdAt
    })

    return params
  }, [printerData?.printer, printerData?.root])

  return useMemo(
    () => (
      <AnalyticsProvider
        analytics={analytics}
        defaults={{
          screenPath: '/ReactPaasDashboardShipmentTracking/',
          activity: 'HpOnePrintMgmt-v01',
          version: '1.3.0',
          ...auxParams
        }}
        events={analyticsEvents}
      >
        {children}
      </AnalyticsProvider>
    ),
    [analytics, auxParams, children]
  )
}

export default Analytics
