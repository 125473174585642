import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import InfoDatas from '../../types/InfoDatas'
import { forceLoginHandling } from '../../utils/forceLoginHandling'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'
import { CriticalScopes, TaxpayerDetails } from '../../types/TaxpayerDetails'
import InstantInkShippingAddress from '../../types/InstantInkShippingAddress'
import { SettingsInfo } from '../../types/PaymentMethodsInfo'

export default class StoreFrontClient {
  httpClient: AxiosInstance
  xCorrelationId: string
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider, xCorrelationId: string) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.mfeAuthProvider = authProvider
    this.xCorrelationId = xCorrelationId

    this.baseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getBillingData(): Promise<InfoDatas['billingInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/billing`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching billing data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getBillingAddressInstantInk(): Promise<InstantInkShippingAddress> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/billing/address`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching billing address from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getInstantInkSubscriptionData(): Promise<
    InfoDatas['instantInkSubscriptionInfo']
  > {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/subscriptions`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching II subscription data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getTaxIdData(): Promise<TaxpayerDetails> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/billing/taxpayer_details`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from taxpayer_details api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getCriticalScopes(): Promise<CriticalScopes> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/critical_scopes`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from critical_scopes api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getSettingsData(): Promise<SettingsInfo> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/settings`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        sendOpenTelemetryEvent(
          `Error while fetching data from Settings api : ` +
            JSON.stringify(error)
        )
        throw error
      })
  }
  async getInstnatInkShippingAddress(): Promise<InstantInkShippingAddress> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/shipping/addresses`, {
        headers
      })
      .then(({ data }) => this.formatAddressData(data[0], ''))
      .catch((error) => {
        sendOpenTelemetryEvent(
          `Error while fetching data from Instant Ink address api : ` +
            JSON.stringify(error)
        )
        throw error
      })
  }

  defaultHeaders() {
    if (this.xCorrelationId) {
      return {
        headers: {
          'X-Correlation-ID': this.xCorrelationId
        }
      }
    }
    return {}
  }

  private formatAddressData(
    data: InstantInkShippingAddress,
    cloudIdentifier: string
  ) {
    const shippingAddress = {
      ...data,
      resourceId: data.id,
      tenantId: cloudIdentifier,
      address: data.street1,
      address2: data.street2,
      postalCode: data.zipCode,
      phoneNumber: data.phoneNumber1,
      bisPrimary: data.isDefaultAddress,
      receivetextMessage: data.optedIn,
      fullName: `${data.firstName} ${data.lastName}`
    }
    return shippingAddress
  }
}
