import React from 'react'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PasswordForm from './PasswordForm'
import { ThemeProvider } from '@veneer/theme'
import { List } from '@veneer/core'

const Web = ({
  strings,
  setAdminPassword,
  isCompliant,
  isInProgress,
  loadingCard,
  isPwdUnknownValue,
  isHPX
}) => {
  const regularContent = !isHPX ? (
    <li className="ss-password-section">
      <CardItem
        customCss={{ padding: '20px' }}
        leftItem={
          <div className="list-item__left-section">
            <StatefulIcon value={isCompliant} isHPX={isHPX} />
          </div>
        }
        contentItem={
          <CardItemContent
            titleStyle={{ fontSize: '24px' }}
            title={strings.title}
            content={<>{strings.description}</>}
          />
        }
      />

      {!isCompliant && !isPwdUnknownValue ? (
        <PasswordForm useSetAdminPassword={setAdminPassword} />
      ) : (
        ''
      )}
    </li>
  ) : (
    <li
      className="ss-password-section"
      style={{ borderBottom: isHPX ? '1px solid #d9d9d9' : '' }}
    >
      <ThemeProvider shape="sharp">
        <List>
          <CardItem
            customCss={{ paddingLeft: isHPX ? '24px' : '' }}
            leftItem={
              <div className="list-item__left-section">
                <StatefulIcon value={isCompliant} isHPX={isHPX} />
              </div>
            }
            contentItem={
              <CardItemContent
                title={strings.title}
                content={<>{strings.description}</>}
              />
            }
          />
        </List>
      </ThemeProvider>
      <List
        style={{
          marginLeft: '12px',
          borderTop: !isCompliant ? '1px solid #d9d9d9' : '',
          marginRight: '12px',
          width: 'auto'
        }}
      ></List>
      {!isCompliant && !isPwdUnknownValue ? (
        <PasswordForm useSetAdminPassword={setAdminPassword} />
      ) : (
        ''
      )}
    </li>
  )

  return isInProgress ? loadingCard : regularContent
}

export default Web
