import React from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys,
  AppColors,
  Sizes,
  Colors
} from '../../static/consts'
// import '../../../css/SmartSecurityStyles.css';
import { withDropShadow } from '../../hocs/withDropShadow'

const Mobile = ({
  strings,
  getSecurityCheckStringsForProtectionStatus,
  active,
  onAccordionClick,
  isUnknown,
  isCompliant,
  isComplianceOff,
  isHPX
}) => {
  const warningCssBase = {
    border: `1px solid ${AppColors.warning}`,
    borderRadius: '12px'
  }

  const accordionExpandedContent = () => {
    if (active && !isUnknown && !isHPX) {
      return (
        <List>
          {ConstraintsDataTypes.map((securityCheckName) => {
            const { title, description } =
              getSecurityCheckStringsForProtectionStatus(
                securityCheckName,
                strings,
                isCompliant
              )

            const isNetworkSecurityCheck =
              securityCheckName === ConstraintsDataTypesKeys.network

            if (isComplianceOff && isNetworkSecurityCheck) return <></>

            return (
              <CardItem
                leftItem={
                  isNetworkSecurityCheck &&
                  !isCompliant && (
                    <StatefulIcon
                      value={false}
                      filled
                      size={Sizes.MOBILE_WARNING_SIZE}
                      isHPX={isHPX}
                    />
                  )
                }
                rightItem={
                  isNetworkSecurityCheck && !isCompliant ? (
                    <></>
                  ) : (
                    <Tooltip
                      arrow
                      content={description}
                      placement="bottom-end"
                      data-testid="ss-tooltip"
                    >
                      <div style={{ padding: 0 }}>
                        <IconInfo
                          size={Sizes.MOBILE_WARNING_SIZE}
                          color={Colors.MOBILE_INFO_BLUE}
                          data-testid="ss-icon-info"
                        />
                      </div>
                    </Tooltip>
                  )
                }
                key={title}
                contentItem={
                  <CardItemContent
                    title={title}
                    isSubcontent
                    content={
                      <>
                        {isNetworkSecurityCheck && !isCompliant
                          ? description
                          : ''}
                      </>
                    }
                  />
                }
              />
            )
          })}
        </List>
      )
    }
    return (
      <List
        style={{
          width: 'auto'
        }}
      >
        {ConstraintsDataTypes.map((securityCheckName, index) => {
          if (index === 3) return null
          const { title, description } =
            getSecurityCheckStringsForProtectionStatus(
              securityCheckName,
              strings,
              isCompliant
            )
          return (
            <>
              <List
                style={{
                  borderTop: '1px solid #d9d9d9',
                  marginLeft: '24px',
                  marginRight: '24px',
                  width: 'auto'
                }}
              ></List>
              <List>
                <CardItem
                  customCss={{
                    borderRadius: '0px'
                  }}
                  key={title}
                  contentItem={
                    <CardItemContent
                      title={title}
                      isSubcontent
                      customCss={{
                        textAlign: 'left',
                        paddingLeft: '0px'
                      }}
                      content={<>{description}</>}
                    />
                  }
                />
              </List>
            </>
          )
        })}
      </List>
    )
  }

  const unknownCard = (
    <CardItem
      leftItem={<StatefulIcon value={false} isHPX={isHPX} />}
      contentItem={
        <CardItemContent
          title={strings.title}
          content={<>{strings.description}</>}
        />
      }
      hasShadow
    />
  )

  const accordion = (
    <>
      {!isHPX ? (
        <Accordion
          onExpand={onAccordionClick}
          onCollapse={onAccordionClick}
          data-testid="ss-accordion"
          items={[
            {
              header: {
                startArea: (
                  <StatefulIcon
                    value={isCompliant || isComplianceOff}
                    isHPX={isHPX}
                  />
                ),
                centralArea: (
                  <CardItemContent
                    titleStyle={{ marginTop: '12px' }}
                    customCss={{ marginBottom: '12px' }}
                    title={strings.title}
                    content={<>{strings.description}</>}
                  />
                )
              },
              expanded: active,
              content: <>{accordionExpandedContent()}</>,
              id: 'accordionProtectionStatusMobileItemID'
            }
          ]}
          id="accordionProtectionStatusMobileID"
        />
      ) : (
        <>
          <CardItem
            data-testid="ss-accordion"
            customCss={{
              borderTop: '1px solid #d9d9d9',
              borderRadius: '0px'
            }}
            leftItem={<StatefulIcon value={true} isHPX={isHPX}></StatefulIcon>}
            contentItem={
              <CardItemContent
                titleStyle={{ marginTop: '12px' }}
                customCss={{ marginBottom: '12px' }}
                title={strings.title}
                content={<>{strings.description}</>}
              />
            }
          ></CardItem>
          {accordionExpandedContent()}
        </>
      )}
    </>
  )

  const content = () => {
    return withDropShadow(
      'div',
      true
    )({
      props: {
        className: 'ss-accordion-mobile',
        style: !isHPX
          ? isCompliant || isComplianceOff
            ? {}
            : warningCssBase
          : isCompliant || isComplianceOff
          ? {}
          : { borderBottom: '1px solid #d9d9d9' }
      },
      children: accordion
    })
  }

  return isUnknown ? unknownCard : content()
}

export default Mobile
