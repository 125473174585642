import { useCallback, useEffect, useState } from 'react'
import IInkBillingInfoClient from '@/services/IIBillingInfoClient'
import getAuthProvider from '@/helpers/getAuthProvider'
import InfoDatas from '@/types/InfoDatas'

const useBillingData = () => {
  const authProvider = getAuthProvider()
  const [data, setData] = useState<InfoDatas['billingInfo'] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const fetchBillingData = useCallback(async () => {
    setLoading(true)
    setError(null)

    const client = new IInkBillingInfoClient(authProvider)

    try {
      const data = await client.getBillingData()
      setData(data)
    } catch (err) {
      setError('Failed to fetch billing data.')
    } finally {
      setLoading(false)
    }
  }, [authProvider])
  useEffect(() => {
    fetchBillingData()
  }, [fetchBillingData])

  return { data, loading, error, forceRefresh: fetchBillingData }
}

export default useBillingData
