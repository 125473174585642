import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Printer from 'src/components/Printer';
import NoPrintersImage from 'src/assets/images/generic-printer.svg';
import {
  NoPrintersIcon,
  NoPrintersSection,
  PrinterAccount,
  PrinterBox,
  PrintersContainer
} from './styles';
import TransferOwnershipModal from 'src/components/TransferOwnershipModal';
import RemovePrinter from 'src/components/RemovePrinter';
import {
  removePrinterModalTypes,
  statusDeleteDeviceErrorMessage
} from 'src/components/RemovePrinter/constants';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import useAccountInfoApiCall from 'src/hooks/useAccountInfoApiCall';

const PrintersCollection = ({
  printersInfo,
  accountSolutions,
  userInfo,
  refreshData
}) => {
  const { t } = useI18n();
  const { stack, authProvider } = useRootContext();

  const accountInfo = useAccountInfoApiCall({ authProvider, stack });

  const [openTransferDeviceModal, setOpenTransferDeviceModal] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState(undefined);
  const [isLastHPPlusPrinter, setIsLastHPPlusPrinter] = useState(false);
  const [openRemoveDeviceModal, setOpenRemoveDeviceModal] = useState(false);
  const [removePrinterModalType, setRemovePrinterModalType] = useState(
    removePrinterModalTypes.PRINTER_MODAL_OPEN
  );
  const [removePrinterModalSubType, setRemovePrinterModalSubType] = useState(
    statusDeleteDeviceErrorMessage.NOT_MAPPED
  );

  const handleOpenTransferDeviceModal = useCallback((device, lastHPPlus) => {
    setIsLastHPPlusPrinter(lastHPPlus);
    setSelectedDeviceData(device);
    setOpenTransferDeviceModal(true);
  }, []);
  const handleOpenRemoveDeviceModal = useCallback(
    (
      device,
      category,
      subCategory = statusDeleteDeviceErrorMessage.NOT_MAPPED
    ) => {
      setSelectedDeviceData(device);
      setOpenRemoveDeviceModal(true);
      setRemovePrinterModalType(category);
      setRemovePrinterModalSubType(subCategory);
    },
    []
  );
  const handleCloseModals = useCallback(() => {
    setOpenTransferDeviceModal(false);
    setOpenRemoveDeviceModal(false);
    setSelectedDeviceData(undefined);
  }, []);

  if (printersInfo.data?.length > 0) {
    return (
      <>
        <PrintersContainer>
          {printersInfo.data.map((printer) => (
            <PrinterBox
              key={printer.deviceId}
              appearance="dropShadow"
              content={
                <Printer
                  device={printer}
                  userInfo={userInfo.data}
                  handleOpenTransferDeviceModal={handleOpenTransferDeviceModal}
                  handleOpenRemoveDeviceModal={handleOpenRemoveDeviceModal}
                  accountInfo={accountInfo.data}
                  accountSolutions={accountSolutions.data}
                />
              }
            />
          ))}
        </PrintersContainer>
        <TransferOwnershipModal
          handleCloseModal={handleCloseModals}
          openModal={openTransferDeviceModal}
          device={selectedDeviceData}
          userInfo={userInfo.data}
          accountInfo={accountInfo.data}
          isLastHPPlusPrinter={isLastHPPlusPrinter}
        />
        <RemovePrinter
          openModal={openRemoveDeviceModal}
          handleCloseModal={handleCloseModals}
          device={selectedDeviceData}
          refreshData={refreshData}
          handleOpenRemoveDeviceModal={handleOpenRemoveDeviceModal}
          removePrinterModalType={removePrinterModalType}
          removePrinterModalSubType={removePrinterModalSubType}
        />
      </>
    );
  }

  if (printersInfo.isFetching) {
    return null;
  }

  return (
    <PrintersContainer data-testid="consumer-printers-printersCard-empty">
      <PrinterBox
        appearance="dropShadow"
        content={
          <NoPrintersSection>
            <NoPrintersIcon
              src={NoPrintersImage}
              alt={t('myPrinters.noPrintersInList.message')}
            />
            <PrinterAccount
              className="body"
              data-testid="consumer-printers-printersCard-noPrintersText"
            >
              {t('myPrinters.noPrintersInList.message')}
            </PrinterAccount>
          </NoPrintersSection>
        }
      />
    </PrintersContainer>
  );
};

PrintersCollection.propTypes = {
  printersInfo: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string.isRequired
      })
    ),
    isFetching: PropTypes.bool.isRequired
  }).isRequired,
  programInfos: PropTypes.shape({
    data: PropTypes.shape({
      hasPrintOnTheGo: PropTypes.bool
    })
  }).isRequired,
  userInfo: PropTypes.shape({
    data: PropTypes.shape({
      userTenantDetail: PropTypes.shape({
        roleCategory: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  refreshData: PropTypes.func.isRequired
};

export default PrintersCollection;
