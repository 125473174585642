import get from 'lodash/get'
import set from 'lodash/set'
import merge from 'lodash/merge'
import type AppState from '../types/AppState'

const assignState = (
  state: AppState,
  path: string,
  value: unknown,
  mustMerge = false
) => {
  let newValue = value
  const oldValue = get(state, path)

  if (mustMerge && typeof value === 'object' && typeof newValue === 'object') {
    newValue = merge(value, oldValue)
  }

  return { ...set(state, path, newValue) } as AppState
}

export default assignState
