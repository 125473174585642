import React from 'react'
import { ListItem } from '@veneer/core'
import PropTypes from 'prop-types'
// import { withDropShadow } from '../hocs/withDropShadow'

const CardItem = ({
  leftItem,
  contentItem,
  rightItem,
  customCss
  // hasShadow,
  // customRef
}) => {
  const leftArea = () => {
    return leftItem ? (
      <div className="list-item__left-section" style={{ top: '0px' }}>
        {leftItem}
      </div>
    ) : (
      <></>
    )
  }
  const content = () => {
    return <div style={{ width: '100%' }}>{contentItem || ''}</div>
  }
  const rightArea = () => {
    return rightItem ? (
      <div style={{ padding: '8px' }}>{rightItem || ''}</div>
    ) : (
      <></>
    )
  }

  // return withDropShadow(
  //   ListItem,
  //   hasShadow
  // )({
  //   props: {
  //     css: { padding: '20px', ...customCss },
  //     leadingArea: leftArea(),
  //     centerArea: content(),
  //     trailingArea: rightArea(),
  //     ...(customRef ? { ref: customRef } : {})
  //   }
  // })
  return (
    <ListItem
      style={{ ...customCss }}
      leadingArea={leftArea()}
      centerArea={content()}
      trailingArea={rightArea()}
    ></ListItem>
  )
}

export default CardItem

CardItem.propTypes = {
  leftItem: PropTypes.object,
  contentItem: PropTypes.object,
  rightItem: PropTypes.object,
  customCss: PropTypes.object,
  hasShadow: PropTypes.bool,
  customRef: PropTypes.object
}

CardItem.defaultProps = {
  leftItem: undefined,
  contentItem: undefined,
  rightItem: undefined,
  customCss: undefined,
  customRef: undefined
}
