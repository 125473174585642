import {
  useFlags,
  BillingCyclePeriodsClient,
  useLazyFetchRequest,
  BillingCyclePeriodsResponse
} from '@monetization/hpaip-notification-rules-react'
import React, { useRef, useMemo, useEffect } from 'react'
import { FaqCard, PlanDetailsCard } from '../Cards'
import { BillingCard } from '../Cards/BillingCard'
import {
  Container,
  Header,
  Title,
  SubTitle,
  GridRightContainer,
  GridLeftContainer,
  GridMainContainer,
  NotificationSection,
  DisprencyText
} from './styles'
import { useHistoryContext } from '../../hooks/useHistoryContext'
import ErrorSection from '../ErrorSection'
import PreviousBillingCard from '../Cards/PreviousBillingCard/PreviousBillingCard'
import { useGetCustomer } from 'src/hooks/useGetCustomer/useGetCustomer'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
interface HistoryComponentProps {
  printerData: any
  tenantId?: string
  notificationList?: any
}
const HistoryPage = ({
  printerData,
  tenantId,
  notificationList
}: HistoryComponentProps) => {
  const historyContext = useHistoryContext()
  const { t, authProvider, stack } = historyContext
  const customerInfo = useGetCustomer(tenantId)
  const customerId = customerInfo?.customerId
  const flags = useFlags()
  const { showUsageData } = flags
  const faqRef = useRef<HTMLDivElement>(null)

  const billingCyclePeriodsClient = useMemo(() => {
    return new BillingCyclePeriodsClient(authProvider, stack)
  }, [authProvider, stack])

  const [fetchBillingCycles, billingCycles] = useLazyFetchRequest<
    BillingCyclePeriodsResponse[]
  >(() => {
    return billingCyclePeriodsClient.getBillingCyclesByEntityId(
      flags.bizlogicId,
      printerData?.root?.subscriptionId,
      printerData?.instantInk?.entityId
    )
  })

  useEffect(() => {
    if (
      printerData?.instantInk?.entityId &&
      printerData?.root?.subscriptionId &&
      !billingCycles?.called
    ) {
      fetchBillingCycles()
    }
  }, [
    printerData?.subscriptionId,
    printerData?.instantInk,

    fetchBillingCycles,
    billingCycles,
    printerData?.root?.subscriptionId
  ])
  const showBillingCard = !!(
    billingCycles.data?.length && billingCycles.data[0]?.items?.length
  )
  const hasError = printerData?.error
  const isPreArrival = printerData?.printer?.state === 'pending'
  const isCancelled = printerData?.printer?.state === 'canceled'

  return (
    <>
      <Header>
        <Title>{t('history.header.title', 'Print and Payment History')}</Title>
        <SubTitle>
          {t(
            'history.header.subtitle',
            'Billing will occur at the end of each billing cycle.'
          )}
        </SubTitle>
      </Header>

      <NotificationSection>
        <MultipleNotification
          multiNotificationArray={notificationList}
        ></MultipleNotification>
      </NotificationSection>

      <Container hasError={hasError}>
        <GridMainContainer>
          {hasError ? (
            <ErrorSection
              title={t('error-section.title', 'Something went wrong')}
              description={t(
                'error-section.description',
                'Your print history did not load.'
              )}
              btnText={t('error-section.button', 'Please refresh page')}
            />
          ) : (
            <>
              <>
                {showBillingCard && showUsageData && (
                  <BillingCard
                    printerData={printerData}
                    billingCycles={billingCycles}
                    isPreArrival={isPreArrival}
                  />
                )}
              </>
            </>
          )}
        </GridMainContainer>
        <GridRightContainer>
          {!hasError && (
            <PlanDetailsCard
              subscriptionId={printerData?.root?.friendlySubscriptionId}
              printerData={printerData}
              isPreArrival={isPreArrival}
              isCancelled={isCancelled}
            />
          )}
          {customerId && !isPreArrival && (
            <PreviousBillingCard customerId={customerId} />
          )}
        </GridRightContainer>
        <GridLeftContainer>
          <FaqCard faqRef={faqRef} />
          <DisprencyText>
            {t(
              'history.discrepancy-text',
              'There may be discrepancies between this page and your invoice due to printer-cloud connectivity issues.'
            )}
          </DisprencyText>
        </GridLeftContainer>
      </Container>
    </>
  )
}

export default HistoryPage
