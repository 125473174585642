import React from 'react';
import {
  DevicesListItem,
  DeviceListItemInfo,
  DevicesListItemImage,
  CardLabel,
  CardTitle
} from './Style';
import PrinterPlaceholderImage from 'src/assets/images/printer-placeholder.png';

const PrinterDetails = ({ props, t }) => {
  const serialNumber = props?.printerSubscription?.entityDetails?.serialNumber;
  return (
    <>
      <DevicesListItem key={`device-list-${props?.productSKU}`}>
        <DevicesListItemImage
          data-testid="PrinterDetailsImg"
          src={props?.printerDetails?.deviceImage}
          alt={props?.printerDetails?.modelName}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = PrinterPlaceholderImage;
          }}
        />

        <DeviceListItemInfo data-testid="PrinterDetails">
          <CardTitle>{props?.printerDetails?.modelName}</CardTitle>
          {props?.productSKU && serialNumber && (
            <CardLabel>
              {t('PrinterDetails.Serial', 'Serial:')} {serialNumber}
            </CardLabel>
          )}
          <CardLabel>
            {t('PrinterDetails.Plan', 'Plan:')} {props?.inkPlan?.pages}{' '}
            {t('PrinterDetails.pages', 'pages/month')}
          </CardLabel>
        </DeviceListItemInfo>
      </DevicesListItem>
    </>
  );
};

export default PrinterDetails;
