import React from 'react'
import useGetText from '@/hooks/useGetText'
import {
  StyledButtonContainer,
  StyledAddButtonContainer,
  StyledIconChevronRight,
  StyledContent
} from './styles'
import Images from '@/assets/images'

interface ManageAddressProps {
  isDisabled: boolean
  containResourceId: boolean
  handleShippingBtn: () => void
}

const ManageAddress = (props: ManageAddressProps) => {
  const { isDisabled, containResourceId, handleShippingBtn } = props
  const getText = useGetText('shipping')
  return (
    <>
      <StyledButtonContainer>
        {!containResourceId && (
          <StyledContent
            data-testid="emptyAddressContent"
            isDisabled={isDisabled}
          >
            <img src={Images.shipping} alt="title icon" />
            <p>{getText('noShipping')}</p>
          </StyledContent>
        )}
        <StyledAddButtonContainer isDisabled={isDisabled}>
          <a role="button" onClick={handleShippingBtn}>
            {containResourceId ? (
              getText('editShippingAddress')
            ) : (
              <>
                {getText('addShippingAddress')}
                <StyledIconChevronRight size={20} />
              </>
            )}
          </a>
        </StyledAddButtonContainer>
      </StyledButtonContainer>
    </>
  )
}

export default ManageAddress
