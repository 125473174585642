import { useState, useMemo } from 'react'
import { SubscriptionsClient } from '@monetization/hpaip-notification-rules-react'
import { getEnvironmentVariables, getStack } from 'src/helpers'

function useUpdateSubscription(subscriptionId, optionId, commonProps) {
  const { authProvider, stack } = commonProps
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const envVariables = getEnvironmentVariables(getStack(stack))
  const BL_ID = envVariables?.variables?.BL_ID

  const subscriptionsClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack),
    [authProvider, stack]
  )

  const updateSubscription = async () => {
    setLoading(true)
    setError(null)
    try {
      await subscriptionsClient.update(subscriptionId, BL_ID, {
        optionId: optionId
      })
      setLoading(false)
      return true
    } catch (e) {
      setError(e.message)
      setLoading(false)
      return false
    }
  }

  return { updateSubscription, loading, error }
}

export default useUpdateSubscription
