import React from 'react'
import { ShipmentCard } from '../../types/shipmentCard'
import { PrintCardAccordionSubtitle } from './PrintCardAccordionSubtitle'
import {
  AccordionSubtitle,
  QuantityLabel,
  QuantityValue,
  StyledAnchor,
  TrackingNumberContainer
} from './styles'
import CommonProps from '../../types/commonProps'
import { useAnalytics } from '@monetization/hpaip-ui-shared-components'

type PrintCardAccordionHeaderProps = {
  shipmentCard: ShipmentCard
  isCustomError?: boolean
  eta?: string
  isCissPrinter?: boolean
  commonProps: CommonProps
}

export const PrintCardAccordionHeader: React.FunctionComponent<
  PrintCardAccordionHeaderProps
> = ({ shipmentCard, isCustomError, eta, isCissPrinter, commonProps }) => {
  const [lastShipmentInfo] = shipmentCard.history
  const { t } = commonProps
  const { publishEvent, events } = useAnalytics()

  const handleClick = () => {
    publishEvent(events.ClickTrackingNumber())
  }

  return (
    <>
      <TrackingNumberContainer>
        {!!lastShipmentInfo.quantity && (
          <>
            <QuantityLabel>
              {t('shipment.print-cards.quantity', 'qty')}:
            </QuantityLabel>
            <QuantityValue>{lastShipmentInfo.quantity}</QuantityValue>
          </>
        )}

        <StyledAnchor
          href={lastShipmentInfo.url}
          target="_blank"
          onClick={handleClick}
        >
          {lastShipmentInfo.id}
        </StyledAnchor>
      </TrackingNumberContainer>
      <AccordionSubtitle>
        <PrintCardAccordionSubtitle
          shipmentCard={shipmentCard}
          isCustomError={isCustomError}
          eta={eta}
          isCissPrinter={isCissPrinter}
          commonProps={commonProps}
        />
      </AccordionSubtitle>
    </>
  )
}
