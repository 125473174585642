import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Title = styled.p`
  color: ${tokens.color.gray12};
  font-size: ${tokens.typography.size9};
  line-height: ${tokens.typography.lineHeight9};

  @media (max-width: ${tokens.layout.mdMin}) {
    font-size: ${tokens.typography.size5};
    line-height: ${tokens.typography.lineHeight5};
  }
`

export const Subtitle = styled.p`
  color: #737373;
  margin-top: 14px;
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};

  @media (max-width: ${tokens.layout.mdMin}) {
    margin-top: ${tokens.layout.size2};
  }
`

export const AddressContainer = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 46px;
  align-items: flex-start;
  @media (max-width: ${tokens.layout.mdMin}) {
    flex-direction: column;
    align-items: stretch;
  }
`
export const ButtonContainer = styled.div.attrs<{ 'data-testid'?: string }>(
  ({ 'data-testid': testId }) => ({
    'data-testid': testId || 'button-container'
  })
)<{ disabled: boolean }>`
  margin-top: 88px;

  && > button {
    color: ${({ disabled }) => (disabled ? '#A3A3A3' : tokens.color.white)};
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight2};
    padding: 14px ${tokens.layout.size6};
    border: none;
    border-radius: ${tokens.layout.cornerRadius3};
    background-color: ${({ disabled }) => (disabled ? '#E8E8E8' : '#0076AD')};
    cursor: pointer;
    width: max-content;
  }

  @media (max-width: ${tokens.layout.mdMin}) {
    margin-top: ${tokens.layout.size8};
  }
`
