import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  stringsForState,
  getSecurityCheckStringsForFlexProtectionStatus
} from '../../strings/DeviceConfigurations/protectionStatusStrings'
import { DeviceConfigurationResult, getEnvConfig } from '../../static/consts'
import AssessmentHooksContext from '../../contexts/AssessmentHooksContext'
import Web from './Web'
import Mobile from './Mobile'
import AuthContext from '../../contexts/AuthContext'

const NoPrinterPlanDevice = ({ isBasicPrinter }) => {
  const { i18n, isMobileSize, stack, isHPX } = useContext(AuthContext)
  const { useAssessmentStringsHook } = useContext(AssessmentHooksContext)

  const { monitorSecuritySettingsStrings, configWifiNetworkStrings } =
    useAssessmentStringsHook

  const wifiEncryptionStrings = stringsForState(
    configWifiNetworkStrings,
    DeviceConfigurationResult.compliant
  )

  const { printPlanHyperLink } = getEnvConfig(stack)

  const componentProps = {
    printPlanHyperLink,
    i18n,
    isBasicPrinter,
    monitorSecuritySettingsStrings,
    configWifiNetworkStrings,
    wifiEncryptionStrings,
    getSecurityCheckStringsForFlexProtectionStatus,
    isHPX
  }

  return isMobileSize ? (
    <Mobile {...componentProps} />
  ) : (
    <Web {...componentProps} />
  )
}

export default NoPrinterPlanDevice
NoPrinterPlanDevice.propTypes = {
  isBasicPrinter: PropTypes.bool.isRequired
}
