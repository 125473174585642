import { AnalyticScreenMode } from '../utils/analytics'

export default function getSubscriptionLineItemIcon(type: string): string {
  switch (type) {
    case 'instant_ink':
      return AnalyticScreenMode.InstantInk
    case 'hp_one':
      return AnalyticScreenMode.HpOne
    case 'iot-pc':
      return AnalyticScreenMode.HpOne
    case 'chromebook':
      return AnalyticScreenMode.HpOne
    case 'iot-printer':
      return AnalyticScreenMode.InstantInk
    default:
      return ''
  }
}
