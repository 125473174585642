import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import InfoDatas from '../../types/InfoDatas'
import { forceLoginHandling } from '../../utils/forceLoginHandling'
import sendOpenTelemetryEvent from '../../helpers/sendOpenTelemetryEvent'
export default class AccountClient {
  httpClient: AxiosInstance
  xCorrelationId: string
  apiName = 'accounts'
  apiVersion = '2'
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider, xCorrelationId: string) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.mfeAuthProvider = authProvider
    this.xCorrelationId = xCorrelationId

    this.baseURL = `${process.env.UCDE_ACCOUNT_URL}/v${this.apiVersion}/ecosystem/accountmgtsvc/${this.apiName}`
  }

  async getAccountData(): Promise<InfoDatas['account']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return this.httpClient
      .get(this.baseURL, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching account data: ' + JSON.stringify(err)
        )
        throw err
      })
  }

  defaultHeaders() {
    if (this.xCorrelationId) {
      return {
        headers: {
          'X-Correlation-ID': this.xCorrelationId
        }
      }
    }
    return {}
  }
}
