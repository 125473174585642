import { STATUS } from 'src/utils/statusDictionary';

export const Entitlement = {
  AdvanceScan: 'ws-hp.com/scan',
  AdvanceShortcuts: 'ws-hp.com/shortcuts',
  SmartDashboard: 'ws-hp.com/smartdashboard',
  SmartSecurity: 'ws-hp.com/smartsecurity',
  PrintOnTheGo: 'ws-hp.com/potg',
  SmartAdvance: 'ws-hp.com/smart-advance',
  Warranty: 'ws-hp.com/warranty',
  PositivePrinting: 'ws-hp.com/positiveprinting',
  PrinterAsAService: 'ws-hp.com/paas',
  PrintPlans: 'ws-hp.com/printplans',
  InstantInk: 'ws-hp.com/instantink',
  InkDelivery: 'ws-hp.com/ink-delivery'
};

export const EntitlementState = {
  Enabled: 'ENABLED',
  Published: 'Published',
  Disabled: 'DISABLED',
  Pending: 'PENDING'
};

export const EntitlementLevel = {
  Device: 'DEVICE',
  Account: 'ACCOUNT'
};

export const EntitlementCategory = {
  Solution: 'SOLUTION',
  Manage: 'MANAGE',
  Benefit: 'BENEFIT',
  Paid: 'PAID'
};

const checkGrantsByState = async ({ grants, state }) => {
  switch (state) {
    case EntitlementState.Enabled: {
      const result = await window.Shell.v1.grants.checkGrants(grants);
      return result;
    }
    case EntitlementState.Pending: {
      const result = await window.Shell.v1.grants.checkPendingGrants(grants);
      return result;
    }
    case EntitlementState.Disabled: {
      const result = await window.Shell.v1.grantsHistory.checkGrantsHistory(
        grants
      );
      return result;
    }
    default:
      return false;
  }
};

/**
 * Check for grants (grants, pending or history, decided by state)
 * @param {string} tenantId - Account ID
 * @param {string} level - EntitlementLevel
 * @param {string} deviceId - Device ID
 * @param {Array} serviceIds - array of Entitlement service ids
 * @param {Array} states - array of EntitlementState
 * @returns {boolean}
 */
const checkGrants = async ({
  tenantId,
  level,
  deviceId,
  serviceIds,
  states
}) => {
  for (let grant of serviceIds) {
    for (let state of states) {
      const hasGrant = await checkGrantsByState({
        grants: [
          {
            tenantId,
            level,
            deviceId,
            grant
          }
        ],
        state
      });
      if (hasGrant) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Checks for any entitlement with any state
 * @param {Object} programInfo - programInfo object from api (user or device)
 * @param {Array} serviceIds - array of Entitlement service ids
 * @param {Array} states - array of EntitlementState
 * @param {Array} subServiceIds - array of Entitlement service ids nested in softwareProductInfoList
 * @param {Array} subStates - array of EntitlementState
 * @param {string} level - EntitlementLevel
 * @param {string} category - EntitlementCategory
 * @returns {boolean}
 */
const checkEntitlements = ({
  programInfo,
  serviceIds,
  states,
  subServiceIds,
  subStates,
  level,
  category
}) => {
  // user
  let map =
    programInfo?.programInfoMap?.YETI || programInfo?.programInfoMap?.UCDE;
  // device
  if (programInfo?.entitlementList) {
    map = programInfo;
  }
  const check = map?.entitlementList?.find(
    (entitlement) =>
      (serviceIds.includes(entitlement.serviceId) &&
        (!states || states.includes(entitlement.state)) &&
        (!level || entitlement.level === level) &&
        (!category || !entitlement.type || entitlement.type === category) &&
        (!subServiceIds ||
          !entitlement.softwareProductInfoList ||
          entitlement.softwareProductInfoList.find((subEntitlement) =>
            subServiceIds.includes(subEntitlement.serviceId)
          ))) ||
      (subServiceIds &&
        subServiceIds.includes(entitlement.serviceId) &&
        (!subStates || subStates.includes(entitlement.state)))
  );
  return !!check;
};

/**
 * Checks of any offering with any state
 * @param {Object} programInfo - programInfo object from api (user or device)
 * @param {Array} serviceIds - array of Entitlement service ids
 * @param {Array} states - array of EntitlementState
 * @param {Array} subServiceIds - array of Entitlement service ids nested in softwareProductInfoList
 * @param {string} level - EntitlementLevel
 * @param {string} category - EntitlementCategory
 * @returns {boolean}
 */
const checkOfferings = ({
  programInfo,
  serviceIds,
  states,
  subServiceIds,
  level,
  category
}) => {
  // user
  let map =
    programInfo?.programInfoMap?.YETI || programInfo?.programInfoMap?.UCDE;
  // device
  if (programInfo?.subscriptionOfferingList) {
    map = programInfo;
  }
  const check = map?.subscriptionOfferingList?.find(
    (entitlement) =>
      (serviceIds.includes(entitlement.serviceId) &&
        (!states || states.includes(entitlement.state)) &&
        (!level || level === entitlement.level) &&
        (!category || !entitlement?.type || entitlement.type === category) &&
        (!subServiceIds ||
          !entitlement.softwareProductInfoList ||
          entitlement.softwareProductInfoList.find((subEntitlement) =>
            subServiceIds.includes(subEntitlement.serviceId)
          ))) ||
      (subServiceIds &&
        subServiceIds.includes(entitlement.serviceId) &&
        (!states || states.includes(entitlement.state)))
  );
  return !!check;
};

/**
 * Gets the subscription status of the device
 * Device is subscribed if it has Ink Delivery OR Instant Ink OR Print Plans
 * Device is eligible if it has offering for Instant Ink OR Print Plans
 * Device is non-eligible if it has none of the above
 * @param {Object} programInfo - programInfo object from api (device)
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @returns {string} Subscription status
 */
export const getDeviceSubscriptionStatus = async ({
  programInfo,
  tenantId,
  deviceId,
  useGrantsInsteadOfEntitlements
}) => {
  if (useGrantsInsteadOfEntitlements) {
    const hasGrant = await checkGrants({
      tenantId,
      level: EntitlementLevel.Device,
      deviceId,
      serviceIds: [
        Entitlement.InkDelivery,
        Entitlement.InstantInk,
        Entitlement.PrintPlans
      ],
      states: [EntitlementState.Enabled, EntitlementState.Pending]
    });
    if (hasGrant) {
      return STATUS.SUBSCRIPTION.SUBSCRIBED;
    }
  } else {
    const hasEntitlement = checkEntitlements({
      programInfo,
      serviceIds: [
        Entitlement.InkDelivery,
        Entitlement.InstantInk,
        Entitlement.PrintPlans
      ],
      states: [EntitlementState.Enabled, EntitlementState.Pending]
    });
    if (hasEntitlement) {
      return STATUS.SUBSCRIPTION.SUBSCRIBED;
    }
  }
  const hasOffering = checkOfferings({
    programInfo,
    serviceIds: [Entitlement.InstantInk, Entitlement.PrintPlans]
  });
  if (hasOffering) {
    return STATUS.SUBSCRIPTION.ELIGIBLE;
  }
  return STATUS.SUBSCRIPTION.NON_ELIGIBLE;
};

/**
 * Gets susbcription update plan of the device
 * Device is Print As A Service if has the Enabled grant for it
 * Device is Instant Ink if has the Enabled or Pending grant or entitlement for Instant Ink or Print Plans
 * Otherwise, it is Unknown
 * @param {Object} programInfo - programInfo object from api (device)
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @returns {string} Subscription update plan
 */
export const getDeviceSubscriptionUpdatePlan = async ({
  programInfo,
  tenantId,
  deviceId,
  useGrantsInsteadOfEntitlements
}) => {
  const hasGrant = await checkGrants({
    tenantId,
    level: EntitlementLevel.Device,
    deviceId,
    serviceIds: [Entitlement.PrinterAsAService],
    states: [EntitlementState.Enabled]
  });
  if (hasGrant) {
    return STATUS.UPDATE_PLAN.PRINT_AS_A_SERVICE;
  }
  if (useGrantsInsteadOfEntitlements) {
    const hasGrant = await checkGrants({
      tenantId,
      level: EntitlementLevel.Device,
      deviceId,
      serviceIds: [Entitlement.InstantInk, Entitlement.PrintPlans],
      states: [EntitlementState.Enabled, EntitlementState.Pending]
    });
    if (hasGrant) {
      return STATUS.UPDATE_PLAN.INSTANT_INK;
    }
  } else {
    const hasEntitlement = checkEntitlements({
      programInfo,
      serviceIds: [Entitlement.InstantInk, Entitlement.PrintPlans],
      states: [EntitlementState.Enabled, EntitlementState.Pending]
    });
    if (hasEntitlement) {
      return STATUS.UPDATE_PLAN.INSTANT_INK;
    }
  }
  return STATUS.UPDATE_PLAN.UNKNOWN;
};

/**
 * Check if device or account has services (grants or entitlement)
 * @param {Object} programInfo - programInfo object from api (device or account)
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @param {string} level - EntitlementLevel (Device or Account)
 * @param {string} category - EntitlementCategory (Solution, Manage, Benefit, Paid)
 * @param {Array} serviceIds - array of Entitlement service ids
 * @param {Array} states - array of EntitlementState
 * @param {Array} subServiceIds - array of Entitlement service ids
 * @param {Array} subStates - array of EntitlementState
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @returns {boolean}
 */
const hasServices = async ({
  programInfo,
  tenantId,
  deviceId,
  level,
  category,
  serviceIds,
  states,
  subServiceIds = null,
  subStates = null,
  useGrantsInsteadOfEntitlements
}) => {
  if (useGrantsInsteadOfEntitlements) {
    const grantIds = subServiceIds || serviceIds;
    const grantStates = subStates || states;
    const hasGrant = await checkGrants({
      tenantId,
      level,
      deviceId,
      serviceIds: grantIds,
      states: grantStates
    });
    if (hasGrant) {
      return true;
    }
  } else {
    const hasEntitlement = checkEntitlements({
      programInfo,
      serviceIds,
      states,
      subServiceIds,
      subStates,
      level,
      category
    });
    if (hasEntitlement) {
      return true;
    }
  }
  const hasOffering = checkOfferings({
    programInfo,
    serviceIds,
    states,
    subServiceIds,
    level,
    category
  });
  return hasOffering;
};

/**
 * Check if device has Print As A Service
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @returns {boolean}
 */
export const getDeviceHasPrintAsAService = async ({ tenantId, deviceId }) => {
  return await checkGrants({
    tenantId,
    level: EntitlementLevel.Device,
    deviceId,
    serviceIds: [Entitlement.PrinterAsAService],
    states: [EntitlementState.Enabled]
  });
};

/**
 * Check if device has Print On The Go
 * @param {Object} programInfo - programInfo object from api (device)
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: Enabled (Enabled or Published)
 * @returns {boolean}
 */
export const getDeviceHasPrintOnTheGo = async ({
  programInfo,
  tenantId,
  deviceId,
  useGrantsInsteadOfEntitlements,
  states = [EntitlementState.Enabled, EntitlementState.Published]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    level: EntitlementLevel.Device,
    deviceId,
    serviceIds: [Entitlement.PrintOnTheGo],
    states,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if device has Smart Security
 * @param {Object} programInfo - programInfo object from api (device)
 * @param {string} tenantId - Account ID
 * @param {string} deviceId - Device ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: ServiceActive (Enabled, Pending, Disabled)
 * @returns {boolean}
 */
export const getDeviceHasSmartSecurity = async ({
  programInfo,
  tenantId,
  deviceId,
  useGrantsInsteadOfEntitlements,
  states = [
    EntitlementState.Enabled,
    EntitlementState.Pending,
    EntitlementState.Disabled
  ]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    level: EntitlementLevel.Device,
    deviceId,
    serviceIds: [Entitlement.SmartSecurity],
    states,
    useGrantsInsteadOfEntitlements
  });
};

export const getHasServiceWithState = async ({
  programInfo,
  tenantId,
  level,
  category,
  deviceId,
  serviceIds,
  states,
  subServiceIds,
  subStates,
  useGrantsInsteadOfEntitlements
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    level,
    category,
    deviceId,
    serviceIds,
    states,
    subServiceIds,
    subStates,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Print On The Go
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: Enabled (Enabled or Published)
 * @returns {boolean}
 */
export const getAccountHasPrintOnTheGo = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements,
  states = [EntitlementState.Enabled, EntitlementState.Published]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    // do not use level Account!
    serviceIds: [Entitlement.PrintOnTheGo],
    states,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Smart Advance
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: Active (Enabled or Pending)
 * @returns {boolean}
 */
export const getAccountHasSmartAdvance = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements,
  states = [EntitlementState.Enabled, EntitlementState.Pending]
}) => {
  return await hasServices({
    programInfo,
    tenantId: tenantId,
    serviceIds: [Entitlement.SmartAdvance],
    states,
    category: EntitlementCategory.Benefit,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Smart Advance solutions
 * Special rules (entitlements):
 * - Smart Advance is Enabled or Pending AND has nested Advance Scan or Advance Shortcuts
 * - Smart Advance is Enabled or Pending AND Advance Scan or Advance Shortcuts is Enabled or Published
 * Special rules (grants):
 * - Smart Advance is Enabled or Pending AND Advance Scan or Advance Shortcuts is Enabled or Published
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @returns {boolean}
 */
export const getAccountHasSmartAdvanceSolutions = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements
}) => {
  const hasSmartAdvance = await getAccountHasSmartAdvance({
    programInfo,
    tenantId,
    useGrantsInsteadOfEntitlements
  });
  if (hasSmartAdvance) {
    const hasSmartAdvanceSolutions = await hasServices({
      programInfo,
      tenantId,
      serviceIds: [Entitlement.SmartAdvance],
      states: [EntitlementState.Enabled, EntitlementState.Pending],
      subServiceIds: [Entitlement.AdvanceScan, Entitlement.AdvanceShortcuts],
      subStates: [EntitlementState.Enabled, EntitlementState.Published],
      category: EntitlementCategory.Benefit,
      useGrantsInsteadOfEntitlements
    });
    if (hasSmartAdvanceSolutions) {
      return true;
    }
    return await hasServices({
      programInfo,
      tenantId,
      serviceIds: [Entitlement.AdvanceScan, Entitlement.AdvanceShortcuts],
      states: [EntitlementState.Enabled, EntitlementState.Published],
      useGrantsInsteadOfEntitlements
    });
  }
  return false;
};

/**
 * Check if account has Smart Security
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: Benefit (Enabled or Disabled)
 * @returns {boolean}
 */
export const getAccountHasSmartSecurity = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements,
  states = [EntitlementState.Enabled, EntitlementState.Disabled]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    serviceIds: [Entitlement.SmartSecurity],
    states,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Smart Dashboard
 * Special rules (entitlements):
 * - Smart Dashboard is enabled if Smart Dashboard is Enabled or Published
 * - Smart Dashboard is active if Smart Advance is Enabled or Pending AND has nested Smart Dashboard
 * Special rules (grants):
 * - Smart Dashboard is Enabled, Pending or Published
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @returns {boolean}
 */
export const getAccountHasSmartDashboard = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements
}) => {
  const hasSmartDashboard = await hasServices({
    programInfo,
    tenantId,
    serviceIds: [Entitlement.SmartDashboard],
    states: [EntitlementState.Enabled, EntitlementState.Published],
    useGrantsInsteadOfEntitlements
  });
  if (hasSmartDashboard) {
    return true;
  }
  return await hasServices({
    programInfo,
    tenantId,
    serviceIds: [Entitlement.SmartAdvance],
    subServiceIds: [Entitlement.SmartDashboard],
    states: [EntitlementState.Enabled, EntitlementState.Pending],
    category: EntitlementCategory.Benefit,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Positive Printer
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: Enabled (Enabled or Published)
 * @returns {boolean}
 */
export const getAccountHasPositivePrinting = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements,
  states = [EntitlementState.Enabled, EntitlementState.Published]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    serviceIds: [Entitlement.PositivePrinting],
    states,
    category: EntitlementCategory.Benefit,
    useGrantsInsteadOfEntitlements
  });
};

/**
 * Check if account has Instant Ink
 * @param {Object} programInfo - programInfo object from api (user)
 * @param {string} tenantId - Account ID
 * @param {boolean} useGrantsInsteadOfEntitlements - use grants instead of entitlements
 * @param {Array} states - array of EntitlementState
 * default: ServiceActive (Enabled, Pending or Disabled)
 * other options: Offering (Published), Acceptable (Enabled, Pending, Disabled, Published)
 * @returns {boolean}
 */
export const getAccountHasInstantInk = async ({
  programInfo,
  tenantId,
  useGrantsInsteadOfEntitlements,
  states = [
    EntitlementState.Enabled,
    EntitlementState.Pending,
    EntitlementState.Disabled
  ]
}) => {
  return await hasServices({
    programInfo,
    tenantId,
    serviceIds: [Entitlement.PrintPlans, Entitlement.InstantInk],
    states,
    useGrantsInsteadOfEntitlements
  });
};
