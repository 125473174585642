import React, { useState } from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import PropTypes from 'prop-types'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import PasswordForm from './PasswordForm'
import { AppColors } from '../../static/consts'
import { withDropShadow } from '../../hocs/withDropShadow'
import { List } from '@veneer/core'

const Mobile = ({
  strings,
  setAdminPassword,
  isCompliant,
  isInProgress,
  loadingCard,
  isPwdUnknownValue,
  isHPX
}) => {
  const [active, setActive] = useState(false)

  const onAccordionClick = () => {
    setActive(!active)
  }

  const warningBorder = {
    border: `1px solid ${AppColors.warning}`,
    borderRadius: '12px'
  }

  const accordionExpandedContent = () => {
    return (
      <div className="ss-passwordset-mobile">
        <PasswordForm useSetAdminPassword={setAdminPassword} isMobileSize />
      </div>
    )
  }

  const passwordCard = () =>
    !isHPX ? (
      <CardItem
        customCss={{ padding: '12px' }}
        leftItem={
          <div className="list-item__left-section">
            <StatefulIcon value={isCompliant} isHPX={isHPX} />
          </div>
        }
        contentItem={
          <CardItemContent
            title={strings.title}
            content={<>{strings.description}</>}
          />
        }
      />
    ) : (
      <CardItem
        customCss={{ padding: '12px', borderRadius: '0px' }}
        leftItem={
          <div className="list-item__left-section">
            <StatefulIcon value={isCompliant} isHPX={isHPX} />
          </div>
        }
        contentItem={
          <CardItemContent
            title={strings.title}
            content={<>{strings.description}</>}
          />
        }
      />
    )

  const accordionCard = () => {
    return (
      <div className="ss-accordion-mobile">
        {!isHPX ? (
          <Accordion
            onExpand={onAccordionClick}
            onCollapse={onAccordionClick}
            data-testid="ss-accordion"
            items={[
              {
                header: {
                  startArea: (
                    <div className="list-item__left-section">
                      <StatefulIcon value={isCompliant} isHPX={isHPX} />
                    </div>
                  ),
                  centralArea: (
                    <CardItemContent
                      titleStyle={{ marginTop: '12px' }}
                      customCss={{ marginBottom: '12px' }}
                      title={strings.title}
                      content={<>{strings.description}</>}
                    />
                  )
                },
                expanded: active,
                content: <>{accordionExpandedContent()}</>,
                id: 'password-protection-status'
              }
            ]}
            id="password-protection-status"
          />
        ) : (
          <>
            <CardItem
              data-testid="ss-accordion"
              customCss={{
                padding: '12px'
              }}
              leftItem={
                <div className="list-item__left-section">
                  <StatefulIcon value={isCompliant} isHPX={isHPX} />
                </div>
              }
              contentItem={
                <CardItemContent
                  titleStyle={{ marginTop: '12px' }}
                  customCss={{ marginBottom: '12px' }}
                  title={strings.title}
                  content={<>{strings.description}</>}
                />
              }
            />
            <List
              style={{
                marginLeft: '12px',
                borderTop: '1px solid #d9d9d9',
                marginRight: '12px',
                width: 'auto'
              }}
            ></List>
            {accordionExpandedContent()}
          </>
        )}
      </div>
    )
  }

  const viewForState = () => {
    if (isInProgress) {
      return loadingCard
    }
    if (!isCompliant && !isPwdUnknownValue) return accordionCard()
    return passwordCard()
  }

  const style = !isHPX
    ? isInProgress || isCompliant || isPwdUnknownValue
      ? {}
      : warningBorder
    : isInProgress || isCompliant || isPwdUnknownValue
    ? {}
    : {}
  return withDropShadow(
    'div',
    true
  )({ children: viewForState(), props: { style } })
}

export default Mobile

Mobile.propTypes = {
  strings: PropTypes.object.isRequired,
  setAdminPassword: PropTypes.object.isRequired,
  isCompliant: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  loadingCard: PropTypes.object.isRequired,
  isPwdUnknownValue: PropTypes.bool.isRequired
}
