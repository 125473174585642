import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const FaqContainer = styled.section<{
  ref?: React.RefObject<HTMLDivElement>
  children?: React.ReactNode
}>`
  grid-row: 2;
  grid-column: 1;
  border-radius: ${layout.cornerRadius4};
  background: ${color.white};
  box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
  padding: ${layout.size8};
`

export const CardTitle = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size4};
  line-height: ${typography.lineHeight4};
  margin-bottom: ${layout.size8};
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${color.gray3};
  margin-bottom: ${layout.size4};
`

export const AccordionHeaderTitle = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
`

export const AccordionContent = styled.p<{
  id?: string
  children?: React.ReactNode
}>`
  color: #737373;
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
`

export const LinkText = styled.a`
  color: ${color.hpBlue7};
  cursor: pointer;
`
