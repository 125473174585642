import React from 'react'
import getHpOnePaymentCardInfo from '@/helpers/getHpOnePaymentCardInfo'
import ExpirationText from '../ExpirationText'
import { CCImageContainer, CCInfoText } from './styles'
import { PaymentMethodDetails } from '@/types'
interface BillingAddressProps {
  creditCardDetails: PaymentMethodDetails
  expirationStatus: string
}

const BillingAddress = (props: BillingAddressProps) => {
  const { creditCardDetails, expirationStatus } = props

  const creditCardTypeInfo = getHpOnePaymentCardInfo(
    creditCardDetails?.card_type
  )
  return (
    <>
      <CCImageContainer>
        <img
          src={creditCardTypeInfo?.cardImage}
          alt={creditCardTypeInfo?.cardName}
        />
      </CCImageContainer>
      <CCInfoText>{creditCardDetails?.name_on_card}</CCInfoText>
      <CCInfoText>
        {creditCardDetails?.last_four &&
          `XXXX-XXXX-XXXX-${creditCardDetails?.last_four}`}
      </CCInfoText>
      <CCInfoText>
        <ExpirationText
          creditCardDetails={creditCardDetails}
          expirationStatus={expirationStatus}
        />
      </CCInfoText>
    </>
  )
}

export default BillingAddress
