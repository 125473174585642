import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledAddressItemContainer = styled.div<{
  error?: false
}>`
  min-height: 260px;
  width: calc(50% - 16px);
  font-family: ${tokens.typography.family0};
  font-size: ${tokens.typography.size2};
  font-weight: 400;
  box-sizing: border-box;

  && {
    padding: ${tokens.layout.size4};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.error ? 'center' : 'space-between')};
    border-radius: ${tokens.layout.cornerRadius4};
    background-color: ${tokens.color.white};
    box-shadow: 0 1px 4px 1px rgba(33, 33, 33, 0.2);
  }

  && {
    @media only screen and (max-width: ${tokens.layout.smMax}) {
      width: 100%;
    }
  }
`

export const ShippingAddressItemContainer = styled.div`
  && {
    width: calc(50% - 16px);
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }

    > div {
      border-radius: ${tokens.layout.cornerRadius4};
    }
  }
`
