import React, { useCallback, useEffect, useState } from 'react';
import PrinterCollection from 'src/components/PrintersCollection';
import {
  Container,
  RefreshSection,
  SubHeader,
  SubSection,
  Title
} from './styles';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import {
  publishButtonClickedEvent,
  publishScreenDisplayedEvent
} from 'src/utils/analytics';
import useDevicesApiCall from 'src/hooks/useDevicesApiCall';
import useUserTenantApiCall from 'src/hooks/useUserTenantApiCall';
import useAccountSolutionsApiCall from 'src/hooks/useAccountSolutionsApiCall';
import RefreshButton from 'src/shared/RefreshButton';

const PrintersPage = () => {
  const { t } = useI18n();
  const { analytics, authProvider, stack } = useRootContext();

  const printersInfo = useDevicesApiCall({ authProvider, stack });
  const userInfo = useUserTenantApiCall({ authProvider, stack });
  const accountSolutions = useAccountSolutionsApiCall({
    authProvider,
    stack,
    accountId: userInfo?.data?.userTenantDetail?.tenantResourceId,
    userId: userInfo?.data?.user?.resourceId
  });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (printersInfo.data) {
      publishScreenDisplayedEvent(
        analytics,
        `totalPrinterCount=${printersInfo.data.length}`
      );
    }
  }, [analytics, printersInfo.data]);

  const refreshData = useCallback(() => {
    publishButtonClickedEvent(analytics, 'ReloadPrinterList');
    printersInfo.forceFetch();
  }, [printersInfo, analytics]);

  useEffect(() => {
    setIsFetching(
      printersInfo.isFetching ||
        userInfo.isFetching ||
        accountSolutions.isFetching
    );
  }, [
    printersInfo.isFetching,
    userInfo.isFetching,
    accountSolutions.isFetching
  ]);

  return (
    <Container data-testid="printers-mfe-container">
      <Title
        className="title-small"
        data-testid="printers-title-pp"
      >
        {t('myPrinters.headers.mainHeaderV2')}
      </Title>
      <SubSection>
        <SubHeader>
          <p
            className="caption"
            data-testid="printers-description-1-pp"
          >
            {t('myPrinters.headers.subHeader')}
          </p>
          <p
            className="caption"
            data-testid="printers-description-2-pp"
          >
            {t('myPrinters.headers.message')}
          </p>
          <RefreshSection>
            <RefreshButton
              onClick={refreshData}
              isRefreshing={isFetching}
            />
          </RefreshSection>
        </SubHeader>
      </SubSection>
      <PrinterCollection
        printersInfo={printersInfo}
        accountSolutions={accountSolutions}
        userInfo={userInfo}
        refreshData={refreshData}
      />
    </Container>
  );
};

export default PrintersPage;
