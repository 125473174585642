import { useState } from 'react'
import notificationConfig from '../../configs/notificationConfig'
import GetAllPostCancelNotification from '../GetAllPostCancelNotification/GetAllPostCancelNotification'
import GetAllDeliquencyNotification from '../GetAllDeliquencyNotification/GetAllDeliquencyNotification'
import GetAllPrearrivalNotification from '../GetAllPrearrivalNotification/GetAllPrearrivalNotification'
import GetAllInstantInkPaymentNotification from '../GetAllInstantInkPaymentNotification/GetAllInstantInkPaymentNotification'
import GetAllOfflineNotification from '../GetAllOfflineNotification/GetAllOfflineNotification'
import { useGetActivePrinterBySubscription } from '../../hooks/useGetActivePrinterBySubscription'
import { NavigationType } from '@/types/shell'
import getAllNotificationsObjects from '../../helpers/getAllNotificationsObjects'
import GetAllPaperNotification from '../GetAllPaperNotification/GetAllPaperNotification'
export const useCommonNotification = (
  mfe: string,
  subscriptionId: string,
  launchdarklyFlags?: any,
  navigateToBillingHandler?: () => void,
  navigation?: NavigationType,
  navigateToIIBillingHandler?: () => void
) => {
  const [configData, setConfigData] = useState(notificationConfig)
  console.log(setConfigData, subscriptionId, launchdarklyFlags)
  const activePrinterData = useGetActivePrinterBySubscription(
    subscriptionId,
    launchdarklyFlags
  )

  const { printer, root, pendingData } = activePrinterData
  const getConfigByKey = (key) => {
    return configData[key]
  }
  const mfeConfig = getConfigByKey(mfe)
  const allNotificationsObject = getAllNotificationsObjects()
  if (activePrinterData) {
    allNotificationsObject.printerData = activePrinterData
  }

  if (mfeConfig) {
    if (mfeConfig.postcancellation) {
      const postCancelNotification = GetAllPostCancelNotification(
        subscriptionId,
        root?.tenantId,
        printer,
        navigation,
        allNotificationsObject,
        pendingData
      )
      if (postCancelNotification) {
        allNotificationsObject.notificationsList.push(postCancelNotification)
      }
    }

    if (mfeConfig.deliquency) {
      const deliquencyInfo = GetAllDeliquencyNotification(
        mfe,
        activePrinterData,
        navigateToBillingHandler,
        navigation,
        allNotificationsObject
      )
      if (deliquencyInfo?.notificationData) {
        allNotificationsObject.notificationsList.push(
          deliquencyInfo?.notificationData
        )
      }
      if (deliquencyInfo?.deliquencyStatus) {
        allNotificationsObject.deliquencyStatus =
          deliquencyInfo.deliquencyStatus
      }
    }
    if (mfeConfig.preaarival) {
      const preArrivalNotification = GetAllPrearrivalNotification(
        mfe,
        printer,
        launchdarklyFlags.enableBuyerRemorseCancellation,
        navigation,
        subscriptionId,
        pendingData
      )
      if (preArrivalNotification) {
        allNotificationsObject.notificationsList.push(preArrivalNotification)
      }
    }
    if (mfeConfig.offline) {
      const offlineNotification = GetAllOfflineNotification(
        mfe,
        printer,
        allNotificationsObject
      )
      if (offlineNotification) {
        allNotificationsObject.notificationsList.push(offlineNotification)
      }
    }
    if (mfeConfig.instantInk) {
      const instantInkNotification = GetAllInstantInkPaymentNotification(
        navigateToIIBillingHandler,
        allNotificationsObject
      )
      if (instantInkNotification) {
        allNotificationsObject.notificationsList.push(instantInkNotification)
      }
    }
    if (mfeConfig.paperCancel) {
      const paperNotification = GetAllPaperNotification(
        subscriptionId,
        launchdarklyFlags.enablePaperOnPaaS,
        navigation,
        allNotificationsObject,
        pendingData
      )
      if (paperNotification) {
        allNotificationsObject.notificationsList.push(paperNotification)
      }
    }
  }

  return allNotificationsObject
}
