import { useCallback } from 'react';
import {
  AccountMgtSvcClient,
  AccountMgtSvcClientV3
} from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import { getAccountHasPrintOnTheGo } from 'src/utils/entitlements';
import { getFeatureFlag } from 'src/utils/featureFlags';

export default function useAccountSolutionsApiCall({
  authProvider,
  stack,
  accountId,
  userId
}) {
  const apiCall = useCallback(async () => {
    if (accountId && userId) {
      const useGrantsInsteadOfEntitlements = await getFeatureFlag({
        key: 'useGrantsInsteadOfEntitlements'
      });
      let SvcClient = AccountMgtSvcClient;
      if (useGrantsInsteadOfEntitlements) {
        SvcClient = AccountMgtSvcClientV3;
      }
      const client = new SvcClient(stack, authProvider);
      const response = await client.getProgramInfos(accountId, userId);
      const programInfo = response?.data;
      const hasPrintOnTheGo = await getAccountHasPrintOnTheGo({
        programInfo,
        tenantId: accountId,
        useGrantsInsteadOfEntitlements
      });
      return {
        hasPrintOnTheGo
      };
    }
    return undefined;
  }, [accountId, authProvider, stack, userId]);

  return useApiCall({ apiCall });
}
