import React from 'react'
import { MfePropsType } from '@/types/mfeProps'
import ShippingBillingRoot from '../ShippingBillingRoot/ShippingBillingRoot'

const ShippingBillingParent = (props: MfePropsType) => {
  const [localNotificationList, setLocalNotificationList] = React.useState([])

  const modifiedProps = {
    ...props,
    localNotificationList,
    setLocalNotificationList
  }
  return (
    <>
      <ShippingBillingRoot {...modifiedProps} />
    </>
  )
}

export default ShippingBillingParent
