import React, { FC } from 'react'
import IconDocumentCopy from '@veneer/core/dist/esm/scripts/icons/icon_document_copy'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
import { toCurrency } from 'src/utils/currency'

import {
  Divider,
  EstimatedPriceBlock,
  EstimatedCard,
  EstimateBlockText,
  DateRangeText,
  TotalText,
  TaxText,
  InvoiceBlock,
  InvoiceText
} from './styles'

interface EstimatedChargesCardProps {
  data: any
}

const EstimateBlock = ({ type, value }) => {
  return (
    <EstimatedPriceBlock>
      <EstimateBlockText>{type}</EstimateBlockText>
      <EstimateBlockText>{toCurrency(value || 0)}</EstimateBlockText>
    </EstimatedPriceBlock>
  )
}

const EstimatedChargesCard: FC<EstimatedChargesCardProps> = ({ data }) => {
  const { t, navigation } = useHistoryContext()

  const handleAllInvoice = () => {
    navigation.push('/account-details/statements')
  }

  const formatDateRange = () => {
    const startDate = data?.customerStartDate || data?.invoiceStartDate
    const endDate = data?.customerEndDate || data?.invoiceEndDate
    return `${startDate} - ${endDate}`
  }

  const planInformation = 'history.cards.plan-details.plan-information'

  return (
    <EstimatedCard>
      <EstimateBlock
        type={t(`${planInformation}.plan-charges`, 'Plan price')}
        value={data?.planFee}
      />
      <DateRangeText>{formatDateRange()}</DateRangeText>
      <EstimateBlock
        type={t(`${planInformation}.remaining-credit`, 'Remaining credit')}
        value={0}
      />
      <EstimateBlock
        type={t(`${planInformation}.additional-pages`, 'Additional pages')}
        value={data?.additionalFee}
      />
      <EstimateBlock
        type={t(`${planInformation}overdue-charges`, 'Overdue charges')}
        value={data?.overdueFee}
      />
      <EstimateBlock
        type={t(`${planInformation}cancellation-fee`, 'Cancellation fee')}
        value={data?.cancellationFee}
      />
      <Divider />
      <EstimatedPriceBlock>
        <TotalText>
          {t(`${planInformation}current-total`, 'Current total:')}
        </TotalText>
        <TotalText>{toCurrency(data?.total)}</TotalText>
      </EstimatedPriceBlock>
      <TaxText>{t(`${planInformation}.tax`, 'Taxes included')}</TaxText>
      <InvoiceBlock onClick={handleAllInvoice}>
        <IconDocumentCopy size={24} />
        <InvoiceText>
          {t(`${planInformation}.all-invoice`, 'All invoices')}
        </InvoiceText>
      </InvoiceBlock>
    </EstimatedCard>
  )
}

export default EstimatedChargesCard
