import React, { useContext } from 'react'
// import Accordion from '@veneer/core/dist/scripts/accordion'
import List from '@veneer/core/dist/scripts/list'
// import { IconInfo } from '@veneer/core/dist/scripts/icons'
// import Tooltip from '@veneer/core/dist/scripts/tooltip'
import StatefulIcon from '../StatefulIcon'
import CardItem from '../CardItem'
import CardItemContent from '../CardItemContent'
import {
  ConstraintsDataTypes,
  ConstraintsDataTypesKeys
  // AppColors
  // Sizes,
  // Colors
} from '../../static/consts'
// import '../../../css/SmartSecurityStyles.css';
import { withDropShadow } from '../../hocs/withDropShadow'
// import { ThemeProvider } from '@veneer/core'
import AuthContext from '../../contexts/AuthContext'

const Mobile = ({
  strings,
  getSecurityCheckStringsForProtectionStatus,
  isUnknown,
  isCompliant,
  isComplianceOff
}) => {
  const { isHPX } = useContext(AuthContext)

  const accordionExpandedContent = () => {
    return (
      <List>
        {ConstraintsDataTypes.filter((_, index) => index === 3).map(
          (securityCheckName) => {
            const { title, description } =
              getSecurityCheckStringsForProtectionStatus(
                securityCheckName,
                strings,
                isCompliant,
                isHPX
              )

            const isNetworkSecurityCheck =
              securityCheckName === ConstraintsDataTypesKeys.network

            if (isComplianceOff && isNetworkSecurityCheck) return <></>

            return (
              <CardItemContent
                key={title}
                title={title}
                content={<>{description}</>}
              />
            )
          }
        )}
      </List>
    )
  }

  const unknownCard = (
    <CardItem
      customCss={{ borderTop: '1px solid #d9d9d9', borderRadius: '0px' }}
      leftItem={<StatefulIcon value={false} isHPX={true} />}
      contentItem={
        <CardItemContent
          title={strings.title}
          content={<>{strings.description}</>}
        />
      }
      hasShadow
    />
  )

  const accordion = (
    <CardItem
      data-testid="ss-card"
      customCss={{
        borderTop: '1px solid #d9d9d9',
        borderRadius: '0px'
      }}
      leftItem={
        <StatefulIcon
          value={isComplianceOff || isCompliant}
          isHPX={true}
        ></StatefulIcon>
      }
      contentItem={<>{accordionExpandedContent()}</>}
    ></CardItem>
  )

  const content = () => {
    return !isComplianceOff ? (
      withDropShadow(
        'div',
        true
      )({
        props: {
          className: 'ss-accordion-mobile',
          style: isCompliant || isComplianceOff ? {} : {}
        },
        children: accordion
      })
    ) : (
      <></>
    )
  }

  return isUnknown ? unknownCard : content()
}

export default Mobile
