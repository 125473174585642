import styled from 'styled-components'
import tokens from '@veneer/tokens'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'

const { color, layout, typography } = tokens

export const Container = styled.div`
  border-radius: ${layout.cornerRadius4};
  background: ${color.white};
  box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
  padding: ${layout.size8};
  font-family: ${typography.family0};
  color: ${color.gray12};
  grid-row: 1;
  grid-column: 2;
  height: fit-content;
`

export const Header = styled.p`
  font-size: ${typography.size4};
  line-height: ${typography.lineHeight4};
  margin-bottom: ${layout.size2};
`

export const SubHeader = styled.p<{ marginBottom?: string }>`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const Divider = styled.div`
  margin: ${layout.size5} 0;
  height: 1px;
  background-color: ${color.gray3};
`

export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${layout.size2};
`

export const Cardlabel = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
`
export const CardlabelColor = styled.p`
  color: #ff0000;
`

export const IconBlock = styled.div`
  margin-top: ${layout.size8};
  color: ${color.hpBlue7};
`

export const IconColor = styled.span`
  svg {
    color: ${color.hpBlue7};
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${layout.size2};

  svg {
    color: ${color.hpBlue7};
  }
`

export const LinkText = styled.div<{
  onClick?: () => void
  children?: React.ReactNode
}>`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  cursor: pointer;
  color: ${color.hpBlue7};
`

export const StyledIconInfo = styled(IconInfo)`
  margin: 0px 0px -4px 5px;
`

export const StyledLoader = styled.div`
  transform: translate(10px, 10px);
  position: relative;
  color: ${color.hpBlue7};
`
export const PriceDetails = styled.div``
