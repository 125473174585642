import * as React from 'react'
import { CenterContainer, Text } from './LoadingHandler.styles'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { DefaultExportProps } from '../../../types'

export interface ILoadingHandlerProps extends Partial<DefaultExportProps> {
  loading: boolean
  error?: boolean | null
  customError?: JSX.Element
  errorTitle?: string
  errorDescription?: string
  loadingText?: string
  $position?: 'absolute' | null
  children?: React.ReactNode
}

const LoadingHandler: React.FunctionComponent<ILoadingHandlerProps> = ({
  loading,
  error,
  errorTitle,
  errorDescription,
  customError,
  children,
  className,
  loadingText,
  $position
}) => {
  if (error) {
    return (
      customError ?? (
        <CenterContainer
          className={`error-container ${className}`}
          $position={$position}
        >
          <Text $size={3}>{errorTitle}</Text>
          <Text $size={3}>{errorDescription}</Text>
        </CenterContainer>
      )
    )
  }

  if (loading) {
    return (
      <CenterContainer
        className={`loading-container ${className}`}
        $position={$position}
      >
        <ProgressIndicator
          appearance="circular"
          behavior="indeterminate"
          color="hpBlue6"
        />
        {loadingText ? <Text $size={3}>{loadingText}</Text> : null}
      </CenterContainer>
    )
  }

  return <>{children}</>
}

export default LoadingHandler
