import React, { useEffect, useState } from 'react'
import useGetText from '@/hooks/useGetText'
import {
  EditShippingClicked,
  publishEvent,
  EditShippingCancelButtonClicked,
  EditShippingSaveButtonClicked
} from '@/utils/analytics'
import { ShippingForm as ShippingInstantInkForm } from '@jarvis/react-shipping'
import getLocalization from '@/helpers/getLocalization'
import getAuthProvider from '@/helpers/getAuthProvider'
import useShellContext from '@/hooks/useShellContext'
import SecuritySessionExpiredModal from '../../../../shared/SecuritySessionExpiredModal'
import ManageAddress from '../ManageAddress'
import { getNotification } from '@/helpers/notifications'
import { StyledModal, ModalContentContainer } from './styles'
import { instantInkStates } from '@/utils/instantInkStates'

interface FooterProps {
  commonProps: any
  isDisabled: boolean
  containResourceId: boolean
  fetchIntantInkAddress: () => void
  instantInkAddress?: any
}
const Footer = (props: FooterProps) => {
  const [isOpen, setOpenModal] = useState(false)
  const [customErr, setCustomErr] = useState(false)
  const {
    commonProps,
    isDisabled,
    containResourceId,
    fetchIntantInkAddress,
    instantInkAddress
  } = props
  const { commonNotificationData, iInk } = commonProps
  const subscriptionId = commonNotificationData?.printerData?.subscriptionId
  const accountData = commonProps?.accountData
  const { language } = getLocalization()
  const authProvider = getAuthProvider()
  const shell = useShellContext()
  const country = accountData?.regionId || process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE + '_' + country
  const instantInkSubData =
    iInk?.iInkSubscriptionInfo?.instantInkSubscriptions?.filter(
      (subitem) => subitem.state !== instantInkStates.OBSOLETE
    )
  const cloudId = instantInkSubData[0]?.printerCloudIdentifier

  const getText = useGetText()

  const {
    criticalScopeError,
    criticalScopeLoading,
    forceCriticalScope,
    sessionModal,
    handleSessionModal,
    setLocalNotificationList,
    localNotificationList
  } = commonProps

  useEffect(() => {
    if (!containResourceId && instantInkAddress != null) {
      const notification = getNotification(
        getText,
        'noInstantInkShippingAddress'
      )

      const filteredNotificationList = localNotificationList?.filter(
        (item) => item?.notificationID === 'instantInkNoShippingAddress'
      )

      setLocalNotificationList([...filteredNotificationList, notification])
    }
  }, [containResourceId, getText, instantInkAddress])

  const handleShippingBtn = async () => {
    forceCriticalScope()
    setOpenModal(true)
    publishEvent(EditShippingClicked, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: 'InstantInk'
    })
  }

  const handleCloseModal = () => {
    publishEvent(EditShippingCancelButtonClicked, {
      entityType: 'InstantInk'
    })
    setOpenModal(false)
  }
  const instankInkURLProvider = async () =>
    `${process.env.INSTANTINK_BASE_URL_PROVIDER}/instantink/v1/commerce`

  const onSave = async (err: string) => {
    if (err === 'expired_critical_scope') {
      setOpenModal(true)
      setCustomErr(true)
      return
    }
    publishEvent(EditShippingSaveButtonClicked, {
      entityType: 'InstantInk'
    })
    const notification = err
      ? getNotification(getText, 'instantInkShippingError')
      : getNotification(getText, 'instantInkShippingSuccess')
    commonProps.setLocalNotificationList([])
    commonProps.refreshNotification(notification)
    setOpenModal(false)
    fetchIntantInkAddress()

    if (!err) {
      sessionStorage.setItem('IsIIShippingSaved', 'true')
    }
  }

  return (
    <>
      {customErr || (criticalScopeError && !criticalScopeLoading) ? (
        <SecuritySessionExpiredModal
          show={sessionModal}
          onClose={handleSessionModal}
        />
      ) : (
        isOpen && (
          <StyledModal
            data-testid="modal"
            closeButton
            show={isOpen}
            onClose={handleCloseModal}
            closeOnBlur={false}
          >
            <ModalContentContainer>
              <ShippingInstantInkForm
                data-testid="editInstantInkShippingFormId"
                country={country}
                language={currLanguage}
                baseURLProvider={instankInkURLProvider}
                authProvider={authProvider}
                onSave={(err: string) => onSave(err)}
                onCancel={handleCloseModal}
                stack={shell?.stack}
                cloudId={cloudId}
                saveButtonText={getText('shipping.save')}
                hideTitle={false}
                secondaryButton={null}
              />
            </ModalContentContainer>
          </StyledModal>
        )
      )}
      <ManageAddress
        isDisabled={isDisabled}
        containResourceId={containResourceId}
        handleShippingBtn={handleShippingBtn}
      />
    </>
  )
}

export default Footer
