import { SignInResponseType } from '../../../services/session/ISessionService';
import SessionInterfaceV1 from './SessionInterfaceV1';
import { SessionInterfaceV1Type } from './types';

let sessionInterfaceV1: SessionInterfaceV1;

export function getSessionInterfaceV1(): SessionInterfaceV1Type {
  return sessionInterfaceV1.getInterface();
}

export default async function initializeSessionInterfaceV1(): Promise<SessionInterfaceV1Type> {
  if (sessionInterfaceV1) return sessionInterfaceV1.getInterface();
  sessionInterfaceV1 = new SessionInterfaceV1();

  return sessionInterfaceV1.getInterface();
}

export function createNoopSessionInterfaceInterface(): SessionInterfaceV1Type {
  return {
    isLoggedIn: function (): boolean {
      console.debug('Function not implemented.');
      return false;
    },
    getProviderList: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    generateAuthenticationUrl: function (): Promise<string> {
      console.debug('Function not implemented.');
      return Promise.resolve('');
    },
    logout: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    refreshToken: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    getIdToken: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    signIn: function (): Promise<SignInResponseType> {
      console.debug('Function not implemented.');
      return Promise.resolve({ success: true });
    }
  };
}
