import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { NotificationBar } from '@monetization/hpaip-ui-shared-components'

const customLayout = {
  containerMaxWidth: '1480px'
}

const { containerMaxWidth } = customLayout

const { gray12 } = tokens.color
const { lineHeight3, size2, size7, lineHeight7 } = tokens.typography

const {
  smMax,
  size5: layoutSize5,
  size4: layoutSize4,
  size8: layoutSize8
} = tokens.layout

export const Container = styled.section`
  padding: ${layoutSize5} 40px;

  @media only screen and (max-width: ${smMax}) {
    margin-top: -${layoutSize5};
  }
`
export const NotificationSection = styled.div`
  margin: 20px 40px;
`
export const Section = styled.div`
  padding: 0 40px ${layoutSize5};
`
export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Subtitle = styled.p`
  font-family: 'Forma DJR Micro';
  color: ${gray12};
  font-size: ${size2};
  line-height: ${lineHeight3};
`

export const StyledNotificationBar = styled(NotificationBar)`
  white-space: break-spaces;
  margin-bottom: 1px;
  p + p {
    margin-top: ${layoutSize4};
  }
`

export const StyledPage = styled.div`
  @media only screen {
    max-width: ${containerMaxWidth};
  }
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    padding: 20px ${layoutSize4} 40px 0;
    @media (min-width: 500px) {
      padding-top: ${layoutSize5};
      padding-right: ${layoutSize5};
    }
    @media (min-width: 641px) {
      padding-right: ${layoutSize8};
      padding-top: ${layoutSize8};
    }
    @media (min-width: 1200px) {
      padding-top: 40px;
      padding-right: 40px;
      padding-bottom: 40px;
    }
  }
  header {
    padding: 20px 40px 8px 40px;
  }
  header h2 {
    color: ${gray12};
    font-size: ${size7};
    font-style: normal;
    font-weight: 400;
    line-height: ${lineHeight7};
  }
`
export const FaqPanel = styled.div<{ isCissPrinter: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    & > section {
      width: ${({ isCissPrinter }) => (isCissPrinter ? '100%' : '67%')};
    }
  }
`
