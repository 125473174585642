import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { family0, size1, size2, lineHeight2 } = tokens.typography

export const MultipleStyledNotification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0;
  > div {
    border-radius: 12px;

    padding: 12px 8px 12px 12px;
    white-space: break-spaces;
    align-items: flex-start;
    box-shadow: 0 1px 4px 1px rgb(33, 33, 33, 0.2);
    > div {
      align-items: flex-start;
    }
  }

  .custom-link1 {
    display: inline;
    cursor: pointer;
  }

  .custom-link2 {
    display: inline;
    cursor: pointer;
  }
`
export const Title = styled.div<{ marginInfo?: boolean }>`
  font-family: ${family0};
  font-weight: 400;
  font-size: ${size2};
  line-height: ${lineHeight2};
  align-items: flex-start;
  margin-bottom: ${(props) => (props.marginInfo ? '4px' : '0px')};
`
export const Description = styled.div<{ marginInfo?: boolean }>`
  font-family: ${family0};
  font-weight: 400;
  font-size: ${size1};
  line-height: ${lineHeight2};
`
