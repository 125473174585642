import FetchDataState from '@/types/FetchDataState'
import { ActionsType, Actions } from '@/actions/Actions'
import AppState from '@/types/AppState'
import { curry } from 'ramda'
import assignState from '@/helpers/assignState'

const getInitialState = (): AppState => ({
  assetsProvider: null,
  isLoading: true,
  isAccountLoading: true,
  billingInfo: {
    isFetching: false
  },
  shippingInfo: {
    isFetching: false
  },
  account: {
    isFetching: false
  },
  subscriptionInfo: {
    isFetching: false
  },
  instantInkSubscriptionInfo: {
    isFetching: false
  },
  paymentMethodsInfo: {
    isFetching: false
  },
  userInfo: {
    isFetching: false
  },
  instantInkDeviceAddressInfo: {
    isFetching: false
  }
})

const factorySuccessFetch = <T>(data: T): FetchDataState<T, undefined> => ({
  isFetching: false,
  data
})

const factorErrorFetch = <T>(error: T): FetchDataState<undefined, T> => ({
  isFetching: false,
  error
})

const appReducer = (state: AppState, action: Actions): AppState => {
  const assignPopuled = curry(assignState)(state)

  switch (action.type) {
    case ActionsType.SET_ASSETS_PROVIDER: {
      return { ...state, assetsProvider: action.data }
    }
    case ActionsType.SET_IS_FETCHING: {
      const { key, value } = action.data
      return assignPopuled(`${key}.isFetching`, value, false)
    }

    case ActionsType.SET_SUCCESS_FETCH: {
      const { key, data } = action.data
      return assignPopuled(key, factorySuccessFetch(data), false)
    }

    case ActionsType.SET_ERROR_FETCH: {
      const { key, error } = action.data
      return assignPopuled(key, factorErrorFetch(error), false)
    }

    default:
      return state
  }
}

export const initialState = getInitialState()

export default appReducer
